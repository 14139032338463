import '../../../../../../tailwind.css';
import { useState, useEffect } from "react"
import { CiUser,  CiFileOn } from "react-icons/ci";
import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";


    const handleStatus = (values) => {
        if(values === 'PRÉ-REQUISIÇÃO PENDENTE DE ATENDIMENTO'){
            return 'PRÉ-REQ. PENDENTE'
        }
        return values
    }


function OrderItem({ item }) {
  return (
    <div>
      <div style={{minHeight: 300}}>
        <table className="w-full border border-gray-300 text-sm shadow-md rounded-lg">
          <thead className='bg-gray-300 text-gray-700 uppercase text-[10px] sticky top-0 z-10'>
            <tr>
              <th className="border p-1">COD. DO PRODUTO</th>
              <th className="border p-1">DESCRIÇÃO DO PRODUTO</th>
              <th className="border p-1">NUMERO DA SC</th>
              <th className="border p-1">NUMERO DO PEDIDO</th>
              <th className="border p-1">NUMERO DA OS</th>
              <th className="border p-1">NUMERO DA OP</th>
              <th className="border p-1">CLASSE DE VALOR</th>
              <th className="border p-1">QTD.</th>
              <th className="border p-1">CENTRO DE CUSTO</th>
              <th className="border p-1">OBSERVAÇÃO</th>
              <th className="border p-1">STATUS</th>
              <th className="border p-1">EMISSÃO</th>
            </tr>
          </thead>
          <tbody>
            {item?.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100 even:bg-gray-50 text-[10px]">
                <td className="border p-1 text-center">{item?.cp_produto}</td>
                <td className="border p-1 text-center">{item?.cp_descri}</td>
                <td className="border p-1 text-center">{item?.sc?.value || "N/A"}</td>
                <td className="border p-1 text-center">{item?.pd?.value || 'N/A'}</td>
                <td className="border p-1 text-center">{item?.cp_os?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{item?.cp_op?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{item?.cp_clvl?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{item?.cp_quant}</td>
                <td className="border p-1 text-center">{item?.cp_cc}</td>
                <td className="border p-1 text-center" title={item.cp_obs}>{item?.cp_obs?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{handleStatus(item?.status)}</td>
                <td className="border p-1 text-center">{moment(item?.cp_emissao).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const SaOrderView = ({data, print, isPrint}) => {
  const getCurrentItems = () => {
    return data.data;
  };



  
  const exportToPDF = async () => {
    const element = document.getElementById("purchase-order");

    if (!element) {
      console.error("Erro: Elemento #purchase-order não encontrado!");
      return;
    }

    // Ajusta o zoom para capturar melhor os estilos
    const scale = 2;
    const canvas = await html2canvas(element, {
      scale,
      useCORS: true, // Permite capturar imagens externas
      backgroundColor: "#ffffff", // Mantém o fundo branco
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 210; // Largura total do A4
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pageHeight = 297; // Altura de uma página A4

    let position = 0;

    while (position < imgHeight) {
      pdf.addImage(imgData, "PNG", 0, position * -1, imgWidth, imgHeight);
      position += pageHeight;

      if (position < imgHeight) {
        pdf.addPage();
        pdf.setFontSize(12);
        pdf.text("Pedido de Compra - Continuação", 10, 15);
      }
    }

    pdf.save(`${data.numero}.pdf`);
    isPrint(false);
  };

  useEffect(() => {
    if (print) {
      exportToPDF();
    }
  }, [print]);




  

  return (
    <div style={{ width: '100%', margin: 0, borderRadius: 5, height: 'auto', position: 'relative', overflow: 'hidden', padding: 5}} id="purchase-order">
      <div className="p-3 border-b border-gray-200" style={{border: '1px solid #e4e2e2', marginTop: 5, borderRadius: 5, height: '15%'}}>
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <h2 className="text-base font-bold">N°: {data.numero}</h2>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-y-2 text-[13px]">
          <div className="flex items-center gap-1">
            <CiFileOn style={{fontSize: 20, color: 'gray'}} />
            <span>FILIAL: <span className="font-semibold text-[12px]">{data.data[0]?.cp_filial}</span></span>
          </div>
          <div className="flex items-center gap-1">
            <CiUser style={{fontSize: 20, color: 'gray'}} />
            <span>SOLICITANTE: <span className="font-semibold text-[12px]">{data.data[0]?.cp_solicit}</span></span>
          </div>
        </div>
      </div>

      <div className="divide-y divide-gray-200">
        <div className="px-3 py-1 bg-gray-100 flex justify-between items-center">
          <h3 className="text-xs font-semibold">ITENS DA SA</h3>
          <h3 className="text-xs font-semibold">{data?.data?.length} ITEMS</h3>
        </div>

        <div style={{margin: 0, padding: 0}}>
          <OrderItem item={getCurrentItems()} />
        </div>
      </div>
    </div>
  )
}
export default SaOrderView


// import '../../../../../../tailwind.css';
// import { useState } from "react"
// import { FaRegFileAlt } from "react-icons/fa";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
// import { CiUser, CiCalendar, CiDeliveryTruck, CiFileOn, CiMoneyBill } from "react-icons/ci";
// import moment from 'moment';







    

  


// function OrderItem({ item }) {
//   const handleStatus = (value) => {
//         if(value === 'PD'){
//             return 'PENDENTE';
//         }else if(value === 'GR'){
//             return 'GERADO';
//         }else if(value === 'GC'){
//             return 'GERADO COMPRA';
//         }else{
//             return "INDEFINIDO";
//         }
//   }

//   return (
//     <div>
//       <table className="w-full border border-gray-300 text-sm shadow-md rounded-lg">
//         <thead>
//           <tr className="bg-gray-300 text-gray-700 uppercase text-[10px]">
//             <th className="border p-1">COD. DO PRODUTO</th>
//             <th className="border p-1">DESCRIÇÃO DO PRODUTO</th>
//             <th className="border p-1">NUMERO DA SC</th>
//             <th className="border p-1">ITEM DA SC</th>
//             <th className="border p-1">NUMERO DO PEDIDO</th>
//             <th className="border p-1">NUMERO DA OS</th>
//             <th className="border p-1">NUMERO DA OP</th>
//             <th className="border p-1">CLASSE DE VALOR</th>
//             <th className="border p-1">QTD.</th>
//             <th className="border p-1">CENTRO DE CUSTO</th>
//             <th className="border p-1">OBSERVAÇÃO</th>
//             <th className="border p-1">STATUS</th>
//           </tr>
//         </thead>
//         <tbody>
//           {item?.map((item, index) => (
//             <tr key={index} className="hover:bg-gray-100 even:bg-gray-50 text-[10px]">
//               <td className="border p-1 text-center">{item?.cp_produto}</td>
//               <td className="border p-1 text-center">{item?.cp_descri}</td>
//               <td className="border p-1 text-center">{item?.numsc || "SEM SC"}</td>
//               <td className="border p-1 text-center">{item?.itemsc || "SEM SC"}</td>
//               <td className="border p-1 text-center">{item?.numpd || "SEM PD"}</td>
//               <td className="border p-1 text-center">{item?.cp_numos?.trim() || "SEM OS"}</td>
//               <td className="border p-1 text-center">{item?.cp_op?.trim() || "SEM OP"}</td>
//               <td className="border p-1 text-center" title={item?.classe_valor_desc}>{item?.cp_clvl?.trim() || "SEM CLVL"}</td>
//               <td className="border p-1 text-center">{item?.cp_quant}</td>
//               <td className="border p-1 text-center">{item?.cp_cc}</td>
//               <td className="border p-1 text-center" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 120}}>{item?.cp_obs?.trim() || "SEM OBS"}</td>
//               <td className="border p-1 text-center">{handleStatus(item?.status)}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   )
// }

// const SaOrderView = ({data, isPrint}) => {
//   const [currentPage, setCurrentPage] = useState(1)
//   const itemsPerPage = 5
//   const totalPages = Math.ceil(data.data.length / itemsPerPage)




//   const getCurrentItems = () => {
//     const startIndex = (currentPage - 1) * (currentPage === 1 ? 11 : itemsPerPage);
//     const endIndex = startIndex + (currentPage === 1 ? 11 : itemsPerPage);
//     if(isPrint){
//       return data.data;
//     }
//     return data.data.slice(startIndex, endIndex);
//   };



  





//   const handleStatus = (value) => {
//         if(value === 'PENDENTE'){
//             return 'APROVADA';
//         }else if(value === "BLOQUEADO"){
//             return 'EM APROVAÇÃO';
//         }else if (value === "TRANSPORTE"){
//             return 'EM TRANSPORTE';
//         }
//         else{
//             return value;
//         }
        
//     }

//   return (
//     <div style={{ width: '100%', margin: 0, borderRadius: 5, height: '100%', position: 'relative', overflow: 'hidden'}}>
//       <div className="p-3 border-b border-gray-200" style={{border: '1px solid #e4e2e2', marginTop: 5, borderRadius: 5}}>
//         <div className="flex items-center justify-between mb-2">
//           <div className="flex items-center gap-2">
//             <h2 className="text-base font-bold">N°: {data.numero}</h2>
//           </div>
//         </div>

//         <div className="grid grid-cols-2 gap-y-2 text-[13px]">
//           <div className="flex items-center gap-1">
//             <CiFileOn style={{fontSize: 20, color: 'gray'}} />
//             <span>FILIAL: <span className="font-semibold text-[12px]">{data.data[0]?.cp_filial}</span></span>
//           </div>
//           <div className="flex items-center gap-1">
//             <CiCalendar style={{fontSize: 20, color: 'gray'}}/>
//             <span>EMISSÃO: <span className="font-semibold text-[12px]">{moment(data.data[0]?.cp_emissao).format('DD/MM/YYYY')}</span></span>
//           </div>
//           <div className="flex items-center gap-1">
//             <CiUser style={{fontSize: 20, color: 'gray'}} />
//             <span>SOLICITANTE: <span className="font-semibold text-[12px]">{data.solicitante}</span></span>
//           </div>
//         </div>
//       </div>

//       <div className="divide-y divide-gray-200">
//         <div className="px-3 py-1 bg-gray-100 flex justify-between items-center">
//           <h3 className="text-xs font-semibold">ITENS DA SA</h3>
//           <span className="text-xs text-gray-500">PÁGINA {currentPage} DE {totalPages}</span>
//         </div>

//         <div style={{margin: 0, padding: 0, height: '100%'}}>
//           <OrderItem item={getCurrentItems()} />
//         </div>
//       </div>

      
//       <div style={{position: 'absolute', bottom: 0, width: '100%', justifyContent: 'space-between', display: isPrint ? 'none' : 'flex'}}>
//         <button className="px-3 py-1 text-xs border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-200"
//           onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
//           disabled={currentPage === 1}
//         >
//           <FaAngleLeft className="h-3 w-3 inline-block mr-1" />
//           ANTERIOR
//         </button>
//         <button className="px-3 py-1 text-xs border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-200"
//           onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
//           disabled={currentPage === totalPages}
//         >
//           PRÓXIMA
//           <FaAngleRight className="h-3 w-3 inline-block ml-1" />
//         </button>
//       </div>
//     </div>
//   )
// }
// export default SaOrderView
