import React, { createContext, useState, useEffect } from 'react';

export const ListScContext = createContext();

export const PedidoProvider = ({ children }) => {
    const [scList, setScList] = useState([]);
    const [pedidoList, setPedidoList] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [selectedSolicitacao, setSelectedSolicitacao] = useState([]);
    const [loadingSc, setLoadingSc] = useState(true);
    const [cardSelected, setCardSelected] = useState("solicitacao");
    const [listUser, setListUser] = useState([]);
    const [Pdpagination, setPdPagination] = useState({
      links: {
        next: null,
        previous: null,
      },
      querys: {
        filter: "",
        option: "",
        status: "",
      },
      path: "",
      current: 1,
      total_pages: 1,
      total_items: 1,
      page_size: 1,
    });
    const [pagination, setPagination] = useState({
      links: {
        next: null,
        previous: null,
      },
      querys: {
        filter: "",
        status: "",
      },
      current: 1,
      total_pages: 1,
      total_items: 1,
      page_size: 1,
    });


    const handleCheckboxChange = (value) => {
          setSelectedSolicitacao((prevItems) => {
            const isItemSelected = prevItems.find(
              (item) => parseInt(item.r_e_c_n_o_field) === parseInt(value.r_e_c_n_o_field)
            );

            if (isItemSelected) {
              return prevItems.filter(
                (item) => parseInt(item.r_e_c_n_o_field) !== parseInt(value.r_e_c_n_o_field)
              );
            } else {
              return [...prevItems, value];
            }
          });
    };

    const handleRemoveItem = (id) => {
        const vls = selectedSolicitacao.filter(item => parseInt(item.r_e_c_n_o_field) !== parseInt(id));
        setSelectedSolicitacao(vls);
    }



    const handleSelectAll = () => {
        const uniqueNumbers = [...new Set(selectedSolicitacao.map(item => item.c1_num))];
        const filteredSolicitacoes = scList.filter(item => 
            uniqueNumbers.includes(item.c1_num)
        );
        setSelectedSolicitacao(filteredSolicitacoes);

        
    }

    

    



    return (
      <ListScContext.Provider
        value={{
          scList,
          setScList,
          handleCheckboxChange,
          handleRemoveItem,
          selectedSolicitacao,
          setSelectedSolicitacao,
          handleSelectAll,
          cardSelected,
          setCardSelected,
          loadingSc,
          setLoadingSc,
          pedidoList,
          setPedidoList,
          listUser,
          setListUser,
          Pdpagination,
          setPdPagination,
          pagination,
          setPagination,
          isFilter,
          setIsFilter,
        }}
      >
        {children}
      </ListScContext.Provider>
    );
};
