import '../../../../../../tailwind.css';
import { useState, useEffect, useContext } from "react"
import moment from 'moment';
import img from '../../../../../../assets/traterr.png';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import currency from 'currency.js';
import { useSelector } from 'react-redux';
import { ListScContext } from '../../utils/scListContext';







function OrderItem({ item }) {
  return (
    <div style={{border: '1px solid #e4e2e2'}}>
      <div style={{minHeight: 300}}>
        <table className="w-full border border-gray-300 text-sm">
          <thead className="bg-gray-300 text-gray-700 uppercase text-[10px] sticky z-10" style={{top: -1}}>
            <tr>
              <th className="border p-1">PRODUTO</th>
              <th className="border p-1">SOLICITANTE</th>
              <th className="border p-1">ARMAZÉM</th>
              <th className="border p-1">SA</th>
              <th className="border p-1">SC</th>
              <th className="border p-1">CENTRO DE CUSTO</th>
              <th className="border p-1">CLASSE DE VALOR</th>
              <th className="border p-1">QTD.</th>
              <th className="border p-1">UN.</th>
              <th className="border p-1">OBSERVAÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {item?.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100 even:bg-gray-50 text-[10px]">
                <td className="border p-1 text-center">{item?.c1_descri || 'N/A'}</td>
                <td className="border p-1 text-center">{item?.c1_solicit || 'N/A'}</td>
                <td className="border p-1 text-center">{item?.c1_local || 'N/A'}</td>
                <td className="border p-1 text-center">{item?.relacoes_sc?.sa?.cp_num || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c1_num || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c1_cc || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c1_clvl || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c1_quant || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c1_um || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c1_obs || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}




const SolicitacaoOrderImport = ({data, contato, isExport, setIsExport, openImport, setOpenImport}) => {
  const user = useSelector(state => state.auth);
  const { setSelectedSolicitacao} = useContext(ListScContext);
  

  const getCurrentItems = () => {
    return data;
  };



  const exportToPDF = async () => {
    const element = document.getElementById("sc-order");

    if (!element) {
        console.error("Erro: Elemento #purchase-order não encontrado!");
        return;
    }

      try {
          // Aguarda um pequeno tempo para garantir que fontes e estilos carreguem antes da captura
          await new Promise(resolve => setTimeout(resolve, 300));

          // Força a renderização do elemento antes da captura
          element.style.transform = "scale(1)";
          element.style.opacity = "1";

          const canvas = await html2canvas(element, {
              scale: window.devicePixelRatio > 1 ? window.devicePixelRatio : 2, // Ajusta a escala automaticamente
              useCORS: true, // Permite capturar imagens externas
              backgroundColor: "#ffffff",
              logging: false, // Reduz logs para performance
              letterRendering: true // Melhora a renderização de texto
          });

          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");

          const imgWidth = 210;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const pageHeight = 297;

          let position = 0;

          while (position < imgHeight) {
              pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight, undefined, "FAST");
              position += pageHeight;

              if (position < imgHeight) {
                  pdf.addPage();
              }
          }

          pdf.save(`COTAÇÃO_SC.pdf`);
          setOpenImport(false);
          setIsExport(false);
          setSelectedSolicitacao([]);
      } catch (error) {
          console.error("Erro ao gerar o PDF:", error);
      }
  };


  useEffect(() => {
    if (isExport) {
      exportToPDF();
    }
  }, [isExport]);




  return (
    <div style={{ width: '100%', margin: 0, borderRadius: 5, height: 'auto', position: 'relative', overflow: 'hidden', padding: 15}} id="sc-order">
      <div className="p-3 border-gray-200" style={{border: '1px solid #e4e2e2', borderRadius: 0, paddingLeft: 50, paddingRight: 50}}>
        <div className="grid grid-cols-3 items-center pb-2">
          <div className="flex items-center space-x-2" style={{width: '45%', height: '45%'}}>
            <img src={img} alt="logo"/>
          </div>
          <div className="text-center">
            <h2 className="text-lg font-semibold">TRATERRA CONSTRUÇÕES LTDA</h2>
            <p className="text-sm text-gray-700" style={{fontSize: 13}}>Rodovia dos Pioneiros, SN, Paragominas-PA - 68627-370</p>
            <p className="text-sm text-gray-700" style={{fontSize: 13}}>Fone: (91) 3729-0123 / 3726-3877</p>
            <p className="text-sm text-gray-700" style={{fontSize: 13}}>CNPJ: 34.349.906/0001-7</p>
          </div>
          <div className="text-right text-sm">
            <p className="font-semibold">Cotação de Compra</p>
            <p style={{fontSize: 13}}>Emissão: {moment().format("DD/MM/YYYY")}</p>
          </div>
        </div>
        
      </div>
      <div className="grid grid-cols-3 gap-6 my-4 text-sm" style={{border: '1px solid #e4e2e2', borderRadius: 0, marginTop: 5, fontSize: 12, padding: 20, marginBottom: 0}}>
            <div className="flex justify-between border-b py-1" style={{marginBottom: 10}}>
              <span className="font-semibold">Responsável:</span>
              <code className='text-[11px]'>{user.name} {user.last_name}</code>
            </div>
            <div className="flex justify-between border-b py-1" style={{marginBottom: 10}}>
              <span className="font-semibold">Contato:</span>
              <code className='text-[11px]'>{user.email}</code>
            </div>
            <div className="flex justify-between border-b py-1" style={{marginBottom: 10}}>
              <span className="font-semibold">Qtd de Itens</span>
              <code className='text-[11px]'>{data?.length}</code>
            </div>
      </div>
      

      

      <div className="divide-y divide-gray-200" style={{marginTop:5}}>
        <div style={{margin: 0, padding: 0}}>
          <OrderItem item={getCurrentItems()} />
        </div>
      </div>

      <div className="border p-6 mt-6 bg-gray-50 text-center" style={{height: '100%', marginTop: 5}}>
        <h3 style={{fontSize: 13}} className="text-lg font-semibold text-gray-800">Solicitação de Dilação de Prazo para Pagamento</h3>
        <p style={{fontSize: 12}} className="text-sm mt-2 text-gray-700">Prezado(a) Fornecedor, a Traterra Construções LTDA, por meio deste, vem respeitosamente solicitar a dilação do prazo para pagamento das compras realizadas,</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">com valor superior a R$ 1.000,00 (mil reais). O faturamento deverá ser realizado com prazo de 60 dias (via boleto bancário).</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">Adicionalmente, solicitamos que sejam observadas as seguintes condições:</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">O orçamento deve ser emitido no CNPJ ao qual será emitida a Nota Fiscal.</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">Agradecemos a gentileza de informar o desconto aplicável.</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">O faturamento deverá ser realizado com prazo de 60 dias (via boleto bancário).</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">Estamos à disposição para quaisquer esclarecimentos e, na certeza de que nossa solicitação será atendida, agradecemos a parceria e compreensão.</p>
      
      
      
      </div>
    </div>
  )
}
export default SolicitacaoOrderImport;
