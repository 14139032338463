import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const datasetsSlice = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    selectAllDatasets: (state) => {
      return state;
    },
    updateDataset: (state, action) => {
      const { datasetName, data } = action.payload;
      if (state[datasetName]) {
        state[datasetName] = state[datasetName].map((item) =>
          item.id === data.id ? { ...item, ...data } : item
        );
      }
    },
    createDataset: (state, action) => {
      const { datasetName, data } = action.payload;
      state[datasetName] = data;
    },
    clearDataset: (state, action) => {
      const { datasetName } = action.payload;
      state[datasetName] = [];
    },
    searchDataset: (state, action) => {
      const { datasetName, filterFunction } = action.payload;
      if (!state[datasetName]) return null;

      if (filterFunction) {
        return state[datasetName].filter(filterFunction);
      }

      return state[datasetName];
    },
  },
});

export const {
  selectAllDatasets,
  updateDataset,
  createDataset,
  searchDataset,
  clearDataset,
} = datasetsSlice.actions;
export default datasetsSlice.reducer;
