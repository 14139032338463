import { IconButton, Input, InputGroup, Whisper, Popover, Button, InputPicker, Loader } from "rsuite";
import { useState, useEffect, useRef, useContext, useCallback } from "react";
import FunnelIcon from '@rsuite/icons/Funnel';
import { ListScContext } from '../../utils/scListContext';
import { useAxios } from '../../../../../../utils/api/api';
import { debounce } from 'lodash';
import { atualizarGrupos, agruparSolicitacoes } from '../../utils/groupdata';



const SolicitacaoInput = () => {
    const triggerRef = useRef();
    const api = useAxios();
    const [isOpen, setIsOpen] = useState(false) 
    const openFilter = () => triggerRef.current.open();
    const closeFilter = () => triggerRef.current.close();
    const {cardSelected, setCardSelected, setScList, loadingSc, setLoadingSc, isFilter, setIsFilter} = useContext(ListScContext);
    const [loadingInput, setLoadingInput] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [centro_custos, setCentroCustos] = useState([]);
    const [produtoList, setProdutoList] = useState([]);
    const [optionFilter, setOptionFilter] = useState('1');
    const [filterValue, setFilterValue] = useState({
        status: "PENDENTE",
        solicitante: '',
        data_inicio: '',
        data_fim: '',
        numero: '',
        centro_custo: '',
        produto: '',
    });



    const handleSearchCentro = async (value) => {
        if (value.length > 2){
            setLoadingInput(true);
            try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                params: {
                    filter: value,  
                    table: 'Ctt010'
                }
            });
            setLoadingInput(false);
            setCentroCustos(response.data);
            } catch (error) {
            console.log(error)
            }
        }
    }


    const handleSearchProduct = async (value) => {
        if(value.length > 3){
          setLoadingInput(true);
          try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
              params: {
                  filter: value,  
                  table: 'Sb1010'
              }
          });
            setLoadingInput(false);
            setProdutoList(response.data);
          } catch (error) {
            console.log(error)
          }
        }
    }



    const handleSearchDynamic = useCallback(
        debounce(async (value, optionFilter, setScList, setLoadingSc, setIsFilter) => {
            if (value.length > 0) {
            setLoadingSc(true);
            setIsFilter(true);
            try {
                const response = await api.get(
                "/api/v2/modules/gestao-compras/solicitacao-compras/solicitacoes_custom_filter",
                {
                    params: {
                    value: value,
                    tipo: optionFilter,
                    },
                }
                );
                setScList(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingSc(false);
            }
            }
        }, 500),
        []
    );

    const handleChange = (value) => {
        setInputValue(value);
        handleSearchDynamic(value, optionFilter, setScList, setLoadingSc, setIsFilter);
    };  
    


    const handleFilterSolicitacoes = async () => {
        const filterString = Object.entries(filterValue)
            .filter(([_, value]) => value)
            .map(([key, value]) => `${key}:${value}`)
            .join(',');
        handleOpenFilter();
        setLoadingSc(true);
        setFilterValue({
            status: "PENDENTE",
            solicitante: '',
            data_inicio: '',
            data_fim: '',
            numero: '',
            centro_custo: '',
            produto: '',
        });
        try {
            setIsFilter(true);
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/solicitacoes_custom_filter',{
                params: {
                    filter: filterString
                }
            });
            setLoadingSc(false);
            setScList(response.data);
        } catch (error) {
            console.log(error);
        }
    }


    const updateFilterValue = (field, value) => {
        setFilterValue(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const speakerSc = (
        <Popover className="custom-popover" style={{zIndex: 9999}}>
          <div className="container-filter">
            <div className="filter-header">
                <span style={{fontSize: 14}}>Filtro</span>
            </div>
            <div className="filter-body">

            <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        value={filterValue.produto}
                        data={produtoList.map((item) => 
                        ({
                            label: `${item?.b1_cod} - ${item?.b1_desc}`,
                            value: item?.b1_cod
                        }))}
                        placeholder="Produto"
                        onSelect={(value, item) => updateFilterValue('produto', item.value)}
                        onClean={() => updateFilterValue('produto', '')}
                        onSearch={(data) => handleSearchProduct(data.toUpperCase())}
                        renderMenu={(menu) => {
                        if(loadingInput){
                            return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                            </div>
                            )
                        } return(
                            <div style={{fontSize: 10}}>
                                {menu}
                            </div>
                        )
                        }}
                    />    
                </div>

                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        data={centro_custos.map((item) => 
                        ({
                            label: `${item?.ctt_custo} - ${item?.ctt_desc01}`,
                            value: item?.ctt_custo
                        }))}
                        placeholder="Centro de custo"
                        onSelect={(value, item) => updateFilterValue('centro_custo', item.value.trim())}
                        onClean={() => updateFilterValue('centro_custo', '')}
                        onSearch={(data) => handleSearchCentro(data.toUpperCase())}
                        renderMenu={(menu) => {
                        if(loadingInput){
                            return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                            </div>
                            )
                        } return(
                            <div style={{fontSize: 10}}>
                                {menu}
                            </div>
                        )
                        }}
                    />    
                </div>
                
                <div className="filter-item">
                    <Input placeholder="Solicitante" onChange={(value)=>updateFilterValue('solicitante', value)}/>
                </div>
                <div className="filter-body-more">
                    <div className="filter-item" style={{width: '100%'}}>
                        <Input 
                            placeholder="Data inicial"
                            onChange={(value)=>updateFilterValue('data_inicio', value)}
                            value={filterValue.data_inicio}
                            type="date"  
                        />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', padding: 10}}>
                        <span style={{marginBottom: 15}}>Até</span>
                    </div>
                    <div className="filter-item" style={{width: '100%'}}>
                        <Input 
                            placeholder="Data final"
                            onChange={(value)=>updateFilterValue('data_fim', value)}
                            value={filterValue.data_fim}
                            type="date"  
                        />
                    </div>
                </div>
                
            </div>
            <div className="filter-footer">
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: 'gray', marginRight: 10}} onClick={()=>handleOpenFilter()}>Sair</Button>
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: '#00664e'}} onClick={()=>handleFilterSolicitacoes()}>Pesquisar</Button>
            </div>
          </div>
        </Popover>
    );

    const handleOpenFilter = () => {
        if (isOpen) {
            closeFilter();
        } else {
            openFilter();
        }
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setInputValue('');
    }, [optionFilter]);

    return(
        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
            <Whisper placement="bottomStart" speaker={speakerSc} ref={triggerRef} trigger="none">
                <IconButton onClick={()=>handleOpenFilter()} style={{backgroundColor: '#f1f1f1', marginRight: 5}} icon={<FunnelIcon style={{height: '100%'}} title="Filtrar"/>}/>
            </Whisper>
            <InputGroup style={{height: 36}}>
                <InputGroup.Addon style={{padding: 0, margin: 0}}>
                <select className="input-select" defaultValue={optionFilter} onChange={(e)=>setOptionFilter(e.target.value)}>
                    <option value="8" title='NUMERO DA FILIAL'>N°. (FILIAL)</option>
                    <option value="1" title='NUMERO DA SOLICITAÇÃO DE COMPRAS'>N°. (SC)</option>
                    <option value="2" title='NUMERO DA SOLICITAÇÃO AO ARMAZEM'>N°. (SA)</option>
                    <option value="3" title='NUMERO DA ORDEM DE SERVIÇO'>N°. (OS)</option>
                    <option value="4" title='NUMERO DA ORDEM DE PRODUÇÃO'>N°. (OP)</option>
                    <option value="6" title='CENTRO DE CUSTO'>CENTRO</option>
                    <option value="5" title='NOME DO SOLICITANTE'>SOLICIT</option>
                </select>
                </InputGroup.Addon>
            <Input value={inputValue} onChange={(value)=>handleChange(value.toUpperCase())} placeholder="Pesquisar" />
            </InputGroup>
        </div>
    )
}


const statusList = [
    {label: 'Em aprovação', value: 'aprovacao'},
    {label: 'Aprovadas', value: 'aprovado'},
    {label: 'Reprovadas', value: 'rejeitado'},
    {label: 'Em transporte', value: 'transporte'},
    {label: 'Concluidas', value: 'atendido'},
]


const PedidoInput = () => {
    const triggerRef = useRef();
    const api = useAxios();
    const [isOpen, setIsOpen] = useState(false) 
    const openFilter = () => triggerRef.current.open();
    const closeFilter = () => triggerRef.current.close();
    const {setLoadingSc, setIsFilter, setPedidoList, setCardSelected, setPdPagination} = useContext(ListScContext);
    const [loadingInput, setLoadingInput] = useState(false);
    const [centro_custos, setCentroCustos] = useState([]);
    const [produtoList, setProdutoList] = useState([]);
    const [optionFilter, setOptionFilter] = useState('7');
    const [listFornec, setListFornec] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [filterValue, setFilterValue] = useState({
        status: '',
        data_inicio: '',
        data_fim: '',
        centro_custo: '',
        produto: '',
        fornecedor: '',
        numSc: '',
        comprador: '',
    });
    

    useEffect(() => {
        setInputValue('');
    }, [optionFilter]);


    const handleSearchCentro = async (value) => {
        if (value.length > 2){
            setLoadingInput(true);
            try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                params: {
                    filter: value,  
                    table: 'Ctt010'
                }
            });
            setLoadingInput(false);
            setCentroCustos(response.data);
            } catch (error) {
            console.log(error)
            }
        }
    }


    const handleSearchProduct = async (value) => {
        if(value.length > 3){
          setLoadingInput(true);
          try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
              params: {
                  filter: value,  
                  table: 'Sb1010'
              }
          });
            setLoadingInput(false);
            setProdutoList(response.data);
          } catch (error) {
            console.log(error)
          }
        }
    }

    const handleSearchFornec = async (value) => {
        if(value.length > 1){
          setLoadingInput(true);
          try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
              params: {
                  filter: value.toUpperCase(),
                  table: 'Sa2010'  
              }
              
          });
          const uniqueArray = response.data.filter((item, index, self) =>
            index === self.findIndex(t => t.a2_cod.trim() === item.a2_cod.trim())
          );
          setLoadingInput(false);
          setListFornec(uniqueArray);
          } catch (error) {
            console.log(error)
          }
        }
    }



    const handleSearchDynamic = useCallback(
        debounce(async (value, optionFilter, agruparSolicitacoes, setLoadingSc, setPedidoList, setCardSelected) => {
            if (value.length > 0) {
            setLoadingSc(true);
            try {
                const response = await api.get(
                "/api/v2/modules/gestao-compras/pedido-compra/pedido_custom_filter",
                {
                    params: {
                    filter: value,
                    option: optionFilter,
                },
                }
                );
                setCardSelected(null);
                setPdPagination({
                    links: {
                        next: response.data.links.next,
                        previous: response.data.links.previous
                    },
                    querys:{
                        filter: value,
                        option: optionFilter,
                    },
                    path: "/api/v2/modules/gestao-compras/pedido-compra/pedido_custom_filter",
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });
                const data = agruparSolicitacoes(response.data.results);
                setPedidoList(data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingSc(false);
            }
            }
        }, 500),
        []
    );

    const handleChange = (value) => {
        setInputValue(value.toUpperCase());
        setIsFilter(false);
        handleSearchDynamic(value, optionFilter, agruparSolicitacoes, setLoadingSc, setPedidoList,setCardSelected);
    };  


    const handleFilterPedidos = async () => {
        if (filterValue.status === ""){
            setCardSelected(null);
        }else{
            setIsFilter(true);
            if(filterValue.status === 'aprovacao'){
                setCardSelected('aprovacao');
            }else if(filterValue.status === 'aprovado'){
                setCardSelected('aprovada');
            }else if(filterValue.status === 'rejeitado'){
                setCardSelected('reprovadas');
            }else if(filterValue.status === 'transporte'){
                setCardSelected('transporte');
            }else if(filterValue.status === 'atendido'){
                setCardSelected('concluida');
            }
        }
        const filterString = Object.entries(filterValue)
            .filter(([_, value]) => value)
            .map(([key, value]) => `${key}:${value}`)
            .join(',');
        handleOpenFilter();
        setLoadingSc(true);
        setIsFilter(false);
        setFilterValue({
            status: '',
            data_inicio: '',
            data_fim: '',
            centro_custo: '',
            produto: '',
            fornecedor: '',
            numSc: '',
            comprador: '',
        });
        try {
            setPedidoList([]);
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/pedido_custom_filter',{
                params: {
                    filter: filterString,
                    option:"0",
                }
            });
            setLoadingSc(false);
            setPdPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys:{
                    filter: filterString,
                    option: "0",
                },
                path: "/api/v2/modules/gestao-compras/pedido-compra/pedido_custom_filter",
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
            const data = agruparSolicitacoes(response.data.results);
            setPedidoList(data);
        } catch (error) {
            console.log(error);
        }
    }


    const updateFilterValue = (field, value) => {
        setFilterValue(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const speakerSc = (
        <Popover className="custom-popover">
          <div className="container-filter">
            <div className="filter-header">
                <span style={{fontSize: 14}}>Filtro</span>
            </div>
            

            <div className="filter-body">
                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        value={filterValue.status}
                        data={statusList}
                        placeholder="Status"
                        onSelect={(value, item) => updateFilterValue('status', item.value)}
                        onClean={() => updateFilterValue('status', '')}
                        renderMenu={(menu) => {
                        return(
                            <div style={{fontSize: 10}}>
                                {menu}
                            </div>
                        )
                        }}
                    />    
                </div>
            <div className="filter-item" style={{zIndex: 9999}}>
                <Input
                    style={{width: '100%', zIndex: 9999}}
                    placeholder="Comprador"
                    value={filterValue.comprador}
                    onChange={(value)=>updateFilterValue('comprador', value.toUpperCase())}
                />    
            </div>

            <div className="filter-item" style={{zIndex: 9999}}>
                <InputPicker
                    className="custom-picker"
                    style={{width: '100%', zIndex: 9999}}
                    value={filterValue.fornecedor}
                    data={listFornec.map((item) => 
                    ({
                        label: `${item.a2_nome} - ${item.a2_cod}`,
                        value: item?.a2_cod
                    }))}
                    placeholder="Fornecedor"
                    onSelect={(value, item) => updateFilterValue('fornecedor', item.value)}
                    onClean={() => updateFilterValue('fornecedor', '')}
                    onSearch={(data) => handleSearchFornec(data.toUpperCase())}
                    renderMenu={(menu) => {
                    if(loadingInput){
                        return(
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                        </div>
                        )
                    } return(
                        <div style={{fontSize: 10}}>
                            {menu}
                        </div>
                    )
                    }}
                />    
            </div>
            <div className="filter-item" style={{zIndex: 9999}}>
                <InputPicker
                    className="custom-picker"
                    style={{width: '100%', zIndex: 9999}}
                    value={filterValue.produto}
                    data={produtoList.map((item) => 
                    ({
                        label: `${item?.b1_cod} - ${item?.b1_desc}`,
                        value: item?.b1_cod
                    }))}
                    placeholder="Produto"
                    onSelect={(value, item) => updateFilterValue('produto', item.value)}
                    onClean={() => updateFilterValue('produto', '')}
                    onSearch={(data) => handleSearchProduct(data.toUpperCase())}
                    renderMenu={(menu) => {
                    if(loadingInput){
                        return(
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                        </div>
                        )
                    } return(
                        <div style={{fontSize: 10}}>
                            {menu}
                        </div>
                    )
                    }}
                />    
            </div>

            <div className="filter-item" style={{zIndex: 9999}}>
                <InputPicker
                    className="custom-picker"
                    style={{width: '100%', zIndex: 9999}}
                    data={centro_custos.map((item) => 
                    ({
                        label: `${item?.ctt_custo} - ${item?.ctt_desc01}`,
                        value: item?.ctt_custo
                    }))}
                    placeholder="Centro de custo"
                    onSelect={(value, item) => updateFilterValue('centro_custo', item.value.trim())}
                    onClean={() => updateFilterValue('centro_custo', '')}
                    onSearch={(data) => handleSearchCentro(data.toUpperCase())}
                    renderMenu={(menu) => {
                    if(loadingInput){
                        return(
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                        </div>
                        )
                    } return(
                        <div style={{fontSize: 10}}>
                            {menu}
                        </div>
                    )
                    }}
                />    
            </div>
                
            <div className="filter-body-more">
                <div className="filter-item" style={{width: '100%'}}>
                    <Input 
                        placeholder="Data inicial"
                        onChange={(value)=>updateFilterValue('data_inicio', value)}
                        value={filterValue.data_inicio}
                        type="date"  
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center', padding: 10}}>
                    <span style={{marginBottom: 15}}>Até</span>
                </div>
                <div className="filter-item" style={{width: '100%'}}>
                    <Input 
                        placeholder="Data final"
                        onChange={(value)=>updateFilterValue('data_fim', value)}
                        value={filterValue.data_fim}
                        type="date"  
                    />
                </div>
            </div>
                
            </div>
            <div className="filter-footer">
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: 'gray', marginRight: 10}} onClick={()=>handleOpenFilter()}>Sair</Button>
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: '#00664e'}} onClick={()=>handleFilterPedidos()}>Pesquisar</Button>
            </div>
          </div>
        </Popover>
    );

    const handleOpenFilter = () => {
        if (isOpen) {
            closeFilter();
        } else {
            openFilter();
        }
        setIsOpen(!isOpen);
    };

    return(
        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
            <Whisper placement="bottomStart" speaker={speakerSc} ref={triggerRef} trigger="none">
                <IconButton onClick={()=>handleOpenFilter()} style={{backgroundColor: '#f1f1f1', marginRight: 5}} icon={<FunnelIcon style={{height: '100%'}} title="Filtrar"/>}/>
            </Whisper>
            <InputGroup style={{height: 36}}>
                <InputGroup.Addon style={{padding: 0, margin: 0}}>
                <select className="input-select"  defaultValue={optionFilter}  onChange={(e)=>setOptionFilter(e.target.value)}>
                    <option value="6" title='NUMERO DA FILIAL'>N°. (FILIAL)</option>
                    <option value="7" title='NUMERO DO PEDIDO DE COMPRA'>N°. (PD)</option>
                    <option value="1" title='NUMERO DA SOLICITAÇÃO DE COMPRAS'>N°. (SC)</option>
                    <option value="2" title='NUMERO DA SOLICITAÇÃO AO ARMAZEM'>N°. (SA)</option>
                    <option value="4" title='CENTRO DE CUSTO'>CENTRO</option>
                    <option value="5" title='CLASSE DE VALOR'>CLASSE VLR</option>
                    <option value="3" title='NOME DO SOLICITANTE'>SOLICIT</option>
                </select>
                </InputGroup.Addon>
            <Input value={inputValue} onChange={(value)=>handleChange(value.toUpperCase())} placeholder="Pesquisar" />
            </InputGroup>
        </div>
    )
}



const InputFilter = () => {
    const {cardSelected, setCardSelected, setScList} = useContext(ListScContext);








    





    return(
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', zIndex: 9999}}>
            {cardSelected === 'solicitacao' ? <SolicitacaoInput/> : <PedidoInput/>}
        </div>
    )
}
export default InputFilter;