import '../../../../../../tailwind.css';
import { useState, useEffect } from "react"
import moment from 'moment';
import img from '../../../../../../assets/traterr.png';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import currency from 'currency.js';

const listCond = [
  { e4_codigo: '001', e4_descri: 'A VISTA' },
  { e4_codigo: '003', e4_descri: '60 Dias' },
  { e4_codigo: '002', e4_descri: '1X 30 Dias' },
  { e4_codigo: '004', e4_descri: 'ENTRADA,30,60' },
  { e4_codigo: '005', e4_descri: '00,30,60,90' },
  { e4_codigo: '006', e4_descri: 'TODO DIA 10' },
  { e4_codigo: '007', e4_descri: 'PGTO ANTECIPADO' },
  { e4_codigo: '008', e4_descri: '2X' },
  { e4_codigo: '009', e4_descri: '3X' },
  { e4_codigo: '010', e4_descri: '4X' },
  { e4_codigo: '011', e4_descri: '5X' },
  { e4_codigo: '012', e4_descri: '6X' },
  { e4_codigo: '013', e4_descri: 'TODO DIA 14' },
  { e4_codigo: '014', e4_descri: 'TODO DIA 28' },
  { e4_codigo: '015', e4_descri: 'TODO DIA 15' },
  { e4_codigo: '016', e4_descri: 'TODO DIA 15' },
  { e4_codigo: '017', e4_descri: 'TODO DIA 05' },
  { e4_codigo: '018', e4_descri: '8X' },
  { e4_codigo: '019', e4_descri: '6X' },
  { e4_codigo: '020', e4_descri: 'TODO DIA 24' },
  { e4_codigo: '021', e4_descri: 'TODO DIA 20' },
  { e4_codigo: '022', e4_descri: '7X' },
  { e4_codigo: '023', e4_descri: '8X' },
  { e4_codigo: '024', e4_descri: '9X' },
  { e4_codigo: '025', e4_descri: '10X' },
  { e4_codigo: '026', e4_descri: '12X' },
  { e4_codigo: '027', e4_descri: 'TODO DIA 31' },
  { e4_codigo: '028', e4_descri: '5X PCC + IR 1º PARCELA' },
  { e4_codigo: '029', e4_descri: 'TODO DIA 09' },
  { e4_codigo: '030', e4_descri: '2X' },
  { e4_codigo: '031', e4_descri: 'TODO DIA 05' },
  { e4_codigo: '032', e4_descri: 'SETE DIAS' },
  { e4_codigo: '033', e4_descri: '14 DIAS' },
  { e4_codigo: '034', e4_descri: '16 DIAS' },
  { e4_codigo: '035', e4_descri: '17 DIAS' },
  { e4_codigo: '036', e4_descri: '18 DIAS' },
  { e4_codigo: '037', e4_descri: '5% CAUCAO' },
  { e4_codigo: '038', e4_descri: '1 X 90 DIAS' },
  { e4_codigo: '039', e4_descri: '11 DIAS' },
  { e4_codigo: '040', e4_descri: '35 DIAS' },
  { e4_codigo: '041', e4_descri: '65 DIAS' },
  { e4_codigo: '042', e4_descri: '15 DIAS' },
  { e4_codigo: '043', e4_descri: '150 DIAS' },
  { e4_codigo: '044', e4_descri: '25 DIAS' },
  { e4_codigo: '045', e4_descri: '31 DIAS' },
  { e4_codigo: '046', e4_descri: '10 DIAS' },
  { e4_codigo: '047', e4_descri: '87 DIAS' },
  { e4_codigo: '048', e4_descri: '20 DIAS' },
  { e4_codigo: '049', e4_descri: '27 DIAS' },
  { e4_codigo: '050', e4_descri: '28 DIAS' },
  { e4_codigo: '051', e4_descri: 'S/ENTRADA, 30 E 60 DIAS' },
  { e4_codigo: '052', e4_descri: '59 DIAS' },
  { e4_codigo: '053', e4_descri: 'TODO DIA 29' },
  { e4_codigo: '054', e4_descri: 'TODO DIA 30' },
  { e4_codigo: '055', e4_descri: 'TODO DIA 17' },
  { e4_codigo: '056', e4_descri: 'TODO DIA 13' },
  { e4_codigo: '057', e4_descri: '24X' },
  { e4_codigo: 'C01', e4_descri: 'CARTÃO 1X' },
  { e4_codigo: 'C02', e4_descri: 'CARTÃO 2X' },
  { e4_codigo: 'C03', e4_descri: 'CARTÃO 3X' },
  { e4_codigo: 'C04', e4_descri: 'CARTÃO 4X' },
  { e4_codigo: 'C05', e4_descri: 'CARTÃO 5X' },
  { e4_codigo: 'C06', e4_descri: 'CARTÃO 6X' },
  { e4_codigo: 'C07', e4_descri: 'CARTÃO 7X' },
  { e4_codigo: 'C08', e4_descri: 'CARTÃO 8X' },
  { e4_codigo: 'C09', e4_descri: 'CARTÃO 9X' },
  { e4_codigo: 'C10', e4_descri: 'CARTÃO 10X' },
  { e4_codigo: 'C11', e4_descri: 'CARTÃO 11X' },
  { e4_codigo: 'C12', e4_descri: 'CARTÃO 12X' },
  { e4_codigo: 'C13', e4_descri: 'DIA 20' },
  { e4_codigo: '058', e4_descri: 'TODO DIA 30' }
];


const parseCurrency = (formattedValue) => {
        if (!formattedValue) return 0;

        if (typeof formattedValue === 'number') {
            return parseFloat(formattedValue.toFixed(2)); // Garante 2 casas decimais
        }

        // Remove espaços extras
        formattedValue = formattedValue.trim();

        // Substitui todas as vírgulas por pontos (caso seja separador decimal)
        formattedValue = formattedValue.replace(',', '.');

        // Captura apenas os números e o último separador decimal válido
        const validCurrency = formattedValue.match(/[\d]+(?:\.\d{0,2})?/g);

        if (!validCurrency) return 0;

        return parseFloat(validCurrency[0]);
  };




  const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value);
  };

  const handleTotal = (data) => {
      let total = currency(0);
      data.forEach((item) => {
          total = total.add(currency(item.total));
      });
      return formatCurrency(total.value);
  };



  const handleTotalSub = (data) => {
      let total = 0;
        data.map((item) => {
          total += parseCurrency(currency(item.total).subtract(item.vlrDesconto).add(item?.valor_frete).add(item?.vlrDespesa).value);
        });
        return formatCurrency(total.toFixed(2));
  };


  const handleDesconto = (data) => {
      let total = currency(0);
      data.forEach((item) => {
          total = total.add(currency(item.c7_vldesc));
      });
      return total.format();
  };

  const handleFrete = (data) => {
      let total = currency(0);
      data.forEach((item) => {
          total = total.add(currency(item.c7_valfre));
      });
      return total.format();
  };

function OrderItem({ item }) {
  return (
    <div style={{border: '1px solid #e4e2e2'}}>
      <div style={{minHeight: 300}}>
        <table className="w-full border border-gray-300 text-sm">
          <thead className="bg-gray-300 text-gray-700 uppercase text-[10px] sticky z-10" style={{top: -1}}>
            <tr>
              <th className="border p-1">PRODUTO</th>
              <th className="border p-1">SOLICITANTE</th>
              <th className="border p-1">SA</th>
              <th className="border p-1">SC</th>
              <th className="border p-1">CENTRO DE CUSTO</th>
              <th className="border p-1">CLASSE DE VALOR</th>
              <th className="border p-1">PREV. ENTREGA</th>
              <th className="border p-1">QTD.</th>
              <th className="border p-1">UN.</th>
              <th className="border p-1">PREÇO UN.</th>
              <th className="border p-1">DESCONTO</th>
              <th className="border p-1">PREÇO TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {item?.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100 even:bg-gray-50 text-[10px]">
                <td className="border p-1 text-center">{item?.c7_descri || 'N/A'}</td>
                <td className="border p-1 text-center">{item.relacoes_pd?.sc?.c1_solicit || 'N/A'}</td>
                <td className="border p-1 text-center">{item?.relacoes_pd?.sa?.cp_num || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c7_numsc || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c7_cc || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c7_clvl || 'N/A'}</td>
                <td className="border p-1 text-center">{moment(item?.c7_datprf).format("DD/MM/YYYY")}</td>
                <td className="border p-1 text-center">{item.c7_quant || 'N/A'}</td>
                <td className="border p-1 text-center">{item.c7_um || 'N/A'}</td>
                <td className="border p-1 text-center">{formatCurrency(item.c7_preco)}</td>
                <td className="border p-1 text-center">{formatCurrency(item.desconto)}</td>
                <td className="border p-1 text-center">{formatCurrency(item.c7_total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}




const PurchaseOrderExport = ({data, vendedor, contato, isExport, setIsExport, setOpenConfirm}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const totalPages = Math.ceil(data.data.length / itemsPerPage)


  const getCurrentItems = () => {
    return data.data;
  };



  const exportToPDF = async () => {
    const element = document.getElementById("purchase-order");

    if (!element) {
        console.error("Erro: Elemento #purchase-order não encontrado!");
        return;
    }

      try {
          // Aguarda um pequeno tempo para garantir que fontes e estilos carreguem antes da captura
          await new Promise(resolve => setTimeout(resolve, 300));

          // Força a renderização do elemento antes da captura
          element.style.transform = "scale(1)";
          element.style.opacity = "1";

          const canvas = await html2canvas(element, {
              scale: window.devicePixelRatio > 1 ? window.devicePixelRatio : 2, // Ajusta a escala automaticamente
              useCORS: true, // Permite capturar imagens externas
              backgroundColor: "#ffffff",
              logging: false, // Reduz logs para performance
              letterRendering: true // Melhora a renderização de texto
          });

          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");

          const imgWidth = 210;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const pageHeight = 297;

          let position = 0;

          while (position < imgHeight) {
              pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight, undefined, "FAST");
              position += pageHeight;

              if (position < imgHeight) {
                  pdf.addPage();
              }
          }

          pdf.save(`${data.numero}.pdf`);
          setOpenConfirm(false);
          setIsExport(false);
      } catch (error) {
          console.error("Erro ao gerar o PDF:", error);
      }
  };


  useEffect(() => {
    if (isExport) {
      exportToPDF();
    }
  }, [isExport]);


  const handleCondition = () => {
    const cond = listCond.find(item => item.e4_codigo === data.data[0].c7_cond);
    
    const result = cond ? `${cond.e4_descri}` : data.data[0].c7_cond;

    return result;
  };


  return (
    <div style={{ width: '100%', margin: 0, borderRadius: 5, height: 'auto', position: 'relative', overflow: 'hidden', padding: 15}} id="purchase-order">
      <div className="p-3 border-gray-200" style={{border: '1px solid #e4e2e2', borderRadius: 0, paddingLeft: 50, paddingRight: 50}}>
        <div className="grid grid-cols-3 items-center pb-2">
          <div className="flex items-center space-x-2" style={{width: '45%', height: '45%'}}>
            <img src={img} alt="logo"/>
          </div>
          <div className="text-center">
            <h2 className="text-lg font-semibold">TRATERRA CONSTRUÇÕES LTDA</h2>
            <p className="text-sm text-gray-700" style={{fontSize: 13}}>Rodovia dos Pioneiros, SN, Paragominas-PA - 68627-370</p>
            <p className="text-sm text-gray-700" style={{fontSize: 13}}>Fone: (91) 3729-0123 / 3726-3877</p>
            <p className="text-sm text-gray-700" style={{fontSize: 13}}>CNPJ: 34.349.906/0001-7</p>
          </div>
          <div className="text-right text-sm">
            <p className="font-semibold">Pedido de Compra Nº</p>
            <p className="text-lg font-bold">{data.numero}</p>
            <p style={{fontSize: 13}}>Emissão: {moment(data.data[0]?.c7_emissao).format("DD/MM/YYYY")}</p>
          </div>
        </div>
        
      </div>
      <div className="grid grid-cols-3 gap-6 my-4 text-sm" style={{border: '1px solid #e4e2e2', borderRadius: 0, marginTop: 5, fontSize: 12, padding: 20, marginBottom: 0}}>
            <div className="flex justify-between border-b py-1">
              <span className="font-semibold">Fornecedor:</span>
              <code className='text-[11px]'>{data.fornecedor}</code>
            </div>
            <div className="flex justify-between border-b py-1">
              <span className="font-semibold">Loja:</span>
              <code className='text-[11px]'>{data.data[0].c7_loja}</code>
            </div>
            <div className="flex justify-between border-b py-1">
              <span className="font-semibold">Vendedor:</span>
              <code className='text-[11px]'>{vendedor}</code>
            </div>
            <div className="flex justify-between border-b py-1">
              <span className="font-semibold">Comprador:</span>
              <code className='text-[11px]'>{data.data[0].comprador}</code>
            </div>
            <div className="flex justify-between border-b py-1">
              <span className="font-semibold">Contato:</span>
              <code className='text-[11px]'>{contato}</code>
            </div>
            <div className="flex justify-between border-b py-1">
              <span className="font-semibold">Filial:</span>
              <code className='text-[11px]'>{data.data[0].c7_filial}</code>
            </div>
      </div>
      

      

      <div className="divide-y divide-gray-200" style={{marginTop:5}}>
        <div style={{margin: 0, padding: 0}}>
          <OrderItem item={getCurrentItems()} />
        </div>
      </div>

      
      
      <div className="flex justify-between items-center text-sm" style={{border: '1px solid #e4e2e2', borderRadius: 0, fontSize: 12, padding: 10, marginTop: 5, height: 80, marginBottom: 0}}>
        <p><span className="font-semibold">Valor do Frete: {handleFrete(data.data)}</span></p>
        <p><span className="font-semibold">Desconto Total: {handleDesconto(data.data)}</span></p>
        <p><span className="font-semibold">Condição de Pagamento: {handleCondition()}</span></p>
        <p><span className="font-semibold">Total: {handleTotal(data.data)}</span></p>
        <p><span className="font-semibold">Total A Pagar: {handleTotalSub(data.data)}</span></p>
      </div>

      <div className="border p-6 mt-6 bg-gray-50 text-center" style={{height: '100%', marginTop: 5}}>
        <h3 style={{fontSize: 13}} className="text-lg font-semibold text-gray-800">INFORMAÇÕES AO FORNECEDOR</h3>
        <p style={{fontSize: 12}} className="text-sm mt-2 text-gray-700">Material em desacordo com nosso pedido não serão aceitos.</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">Informar o Número desta PC nas notas fiscais, faturas, duplicatas e quaisquer outros documentos.</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">Material sem ordem de compra assinada não serão aceitos.</p>
        <p style={{fontSize: 12}} className="text-sm text-gray-700">Não aceitamos documentos emitidos em nome diferente da fatura.</p>
      </div>
    </div>
  )
}
export default PurchaseOrderExport
