import { Routes, Route } from 'react-router-dom';
import SolicitacaoCompras from './apps/solicitacaoCompras';
import PedidoCompras from './apps/pedidoCompras';
import CotacaoCompras from './apps/cotacao';
import GestaoCompras from '.';
import MainLayout from '../../layouts/main';
import { PedidoProvider } from './apps/pedidoCompras/utils/scListContext';
import { ScProvider } from './apps/solicitacaoCompras/utils/solicitacaoContext';



const GcRouters = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainLayout>
            <GestaoCompras />
          </MainLayout>
        }
      />
      <Route
        path="solicitacao-compras"
        element={
          <MainLayout>
            <ScProvider>
              <SolicitacaoCompras />
            </ScProvider>
          </MainLayout>
        }
      />
      <Route
        path="pedido-compras"
        element={
          <MainLayout>
            <PedidoProvider>
              <PedidoCompras />
            </PedidoProvider>
          </MainLayout>
        }
      />
      <Route
        path="cotacao-compras"
        element={
          <MainLayout>
            <CotacaoCompras />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default GcRouters;
