import './data-form.css';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@rsuite/icons/Close';
import { IconButton, Input, Loader, InputPicker, Text, Tooltip, Whisper, Popover} from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import CheckIcon from '@rsuite/icons/Check';
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { ScContext } from '../../utils/solicitacaoContext';
import {atualizarGrupos } from '../../utils/groupdata';
import { debounce } from 'lodash';
import CustomTagInput from '../customTagInput';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



  


const DataFormDrawer = ({ open, setOpen, solicitacoes, setSolicitacoes, listUser }) => {
  const user = useSelector((state) => state.auth);
  const datasets = useSelector((state) => state.datasets);
  const { cardSelected, setCardSelected } = useContext(ScContext);
  const api = useAxios();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [centroCList, setCentroCList] = useState([]);
  const [listAmz, setListAmz] = useState([]);
  const [filialList, setFilialList] = useState([]);
  const [listPrd, setListPrd] = useState([]);
  const [openList, setOpenList] = useState(true);
  const [listCls, setListCls] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loadingInput, setLoadingInput] = useState(false);
  const [valueUsuario, setValueUsuario] = useState('');
  const [isPropagate, setIsPropagate] = useState(false);
  
  const [formData, setFormData] = useState({
    dataemissao: moment().format('YYYYMMDD'),
    usuario: user.userId,
    solicitante: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
    filial: "",
    items: [],
    comprador_preferencial: null
  });
  
  const [formValue, setFormValue] = useState({
    C1_ITEM: '',
    C1_PRODUTO: '',
    C1_UM: '',
    C1_SOLICIT: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
    C1_DESCRI: '',
    C1_QUANT: 1,
    C1_CLVL: '',
    classe_valor_desc: '',
    C1_OP: '',
    C1_ITEMCTA: '',
    C1_OBS: '',
    C1_LOCAL: '',
    C1_CC: '',
    centro_custo: '',
    C1_DATPRF: '',
    CUSTOM_CLASSIFICATION: '',
    acompanhantes: [],

  });

  

  const handleAcompanhantes = (action, data) => {
    setFormValue((prevState) => {
      const updatedAcompanhantes = Array.isArray(prevState.acompanhantes) ? [...prevState.acompanhantes] : [];

      switch (action) {
        case "add":
          if (!updatedAcompanhantes.some(acomp => acomp.id === data.id)) {
            updatedAcompanhantes.push({
              id: data.id,
              first_name: data.first_name,
              last_name: data.last_name,
              is_propagate: data.is_propagate ?? false
            });
          }
          break;

        case "update":
          for (let i = 0; i < updatedAcompanhantes.length; i++) {
            if (updatedAcompanhantes[i].id === data.id) {
              updatedAcompanhantes[i] = { ...updatedAcompanhantes[i], ...data };
              break;
            }
          }
          break;

        case "remove":
          return { ...prevState, acompanhantes: updatedAcompanhantes.filter(acomp => acomp.id !== data.id) };

        default:
          console.warn("Ação inválida para handleAcompanhantes");
      }

      return { ...prevState, acompanhantes: updatedAcompanhantes };
    });
  };




  const handleSearchProduct = useCallback(
        debounce(async (value, setLoadingInput, setProductList) => {
              try {
                  const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                      params: {
                          filter: value,  
                          table: 'Sb1010'
                      }
                    });
                  setLoadingInput(false);
                  setProductList(response.data);
              } catch (error) {
                  console.log(error);
              } finally {
                  setLoadingInput(false);
              }
        }, 500),
    []
  );

  const handleProduto = (value) => {
    setOpenList(true);
    if(value.length > 1){
      setLoadingInput(true);
      handleSearchProduct(value.toUpperCase(), setLoadingInput, setProductList);
    }else{
      setLoadingInput(false);
    }
  };



  const selectProduct = (item) => {
    setOpenList(false)
    let prd = productList.filter((prd) => prd.b1_cod === item)[0];
    setFormValue({ ...formValue, C1_PRODUTO: item, C1_DESCRI: prd.b1_desc, C1_UM: prd.b1_um });
    setProductList([prd])
    
    
  };

  const handleCentroC = async (value) => {
    setLoadingInput(true);
    if(value.length > 3){
      
      try {
        const response = await api.get(`/api/v2/modules/gestao-compras/solicitacao-compras/search_cc`, {
          params: { value }
        });
        setLoadingInput(false);
        setCentroCList(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching Centro de Custo:", error);
      }
    }
  };







  useEffect(() => {
    const nnrData = datasets['Nnr010'] ?? [];
    const filialData = datasets['Filial'] ?? [];
    setListAmz(nnrData);
    setFilialList(filialData);
  },[]);


  



  const handlePrd = async (value) => {
    if(value.length > 2){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,  
              table: 'Sc2010'
          }
        });
        setLoadingInput(false);
        setListPrd(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching AMZ:", error);
      }
    }
  }

  





  const handleClass = async (value) => {
    if(value.length > 1){
      setLoading('cls');
      try {
        const response = await api.get(`/api/v2/modules/gestao-compras/solicitacao-compras/classe_valor`, {
          params: { value }
        });
        setLoading(null);
        setListCls(response.data);
      } catch (error) {
        setLoading(null);
        console.error("Error fetching AMZ:", error);
      }
    }
  }










  const handleAddItem = () => {
    setValueUsuario('');
    if (!formValue.C1_PRODUTO) {
      enqueueSnackbar("Produto é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_QUANT) {
      enqueueSnackbar("Quantidade é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_LOCAL) {
      enqueueSnackbar("Armazém é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_CC) {
      enqueueSnackbar("Centro de Custo é obrigatório", { variant: "error" });
      return;
    }
    if (!formValue.C1_DATPRF) {
      enqueueSnackbar("Data de necessidade é obrigatório", { variant: "error" });
      return;
    }



    const nextC1_ITEM = formData.items.length + 1;

    const newItem = {
      ...formValue,
      C1_ITEM: nextC1_ITEM,
    };

    setFormData({
      ...formData,
      items: [...formData.items, newItem],
    });

    setFormValue((prevState) => ({
      ...prevState,
      C1_ITEM: nextC1_ITEM + 1,
      C1_PRODUTO: '',
      C1_SOLICIT: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
      C1_UM: '',
      C1_DESCRI: '',
      C1_QUANT: 1,
      C1_CLVL: '',
      classe_valor_desc: '',
      C1_OP: '',
      C1_OBS: '',
      C1_LOCAL: '',
      C1_CC: '',
      centro_custo: '',
      C1_DATPRF: '',
      CUSTOM_CLASSIFICATION: ''
    }));
  }


  useEffect(() => {
    if (formValue.C1_DATPRF) {
      const dataLimite7Dias = moment().add(7, 'days');
      const dataLimite6Dias = moment().add(6, 'days');
      const dataNecessidade = moment(formValue.C1_DATPRF);
  
      if (dataNecessidade.isBefore(dataLimite7Dias)) {
        setFormValue((prevState) => ({ ...prevState, CUSTOM_CLASSIFICATION: 'Emergencial' }));
      } 
      
      if (dataNecessidade.isAfter(dataLimite6Dias)) {
        setFormValue((prevState) => ({ ...prevState, CUSTOM_CLASSIFICATION: 'Programada' }));
      }
    }
  }, [formValue.C1_DATPRF]);
  

  const openEdit = (item) => {
    setFormValue({
      C1_PRODUTO: item.C1_PRODUTO,
      C1_QUANT: item.C1_QUANT,
      C1_DESCRI: item.C1_DESCRI,
      C1_ITEM: item.C1_ITEM,
      C1_UM: item.C1_UM,
      C1_CLVL: item.C1_CLVL,
      classe_valor_desc: item.classe_valor_desc,
      C1_OP: item.C1_OP,
      C1_ITEMCTA: item.C1_ITEMCTA,
      C1_OBS: item.C1_OBS,
      C1_LOCAL: item.C1_LOCAL,
      C1_CC: item.C1_CC,
      centro_custo: item.centro_custo,
      C1_DATPRF: item.C1_DATPRF,
      CUSTOM_CLASSIFICATION: item.CUSTOM_CLASSIFICATION,
      acompanhantes: item.acompanhantes
    })
  }








  function removerCaracteresEspeciais(texto) {
    return texto
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')     // Remove acentos
      .replace(/ç/g, 'c')                  // Substitui ç
      .replace(/Ç/g, 'C')                  // Substitui Ç
      .replace(/[^\w\s\/-]/g, '');         // Remove tudo, exceto letras, números, espaços, / e -
  }





  const handleConclusion = async () => {
    setLoadingRequest(true);
    const { items, C1_DATPRF, ...rest } = formData;
    if (items.length === 0) {
      enqueueSnackbar("Adicione pelo menos um item", { variant: "error" });
      setLoadingRequest(false);
      return;
    }
    const data = {
      ...rest,
      items: items.map((item, index) => ({
        ...item,
        C1_ITEM: String(index + 1).padStart(4, '0'),
        C1_DATPRF: moment(C1_DATPRF).format('YYYYMMDD'),
        C1_SOLICIT: `${user.name} ${user.last_name}`,
        C1_EMISSAO: moment().format('YYYYMMDD'),
      }))
    };
    try {
      const response = await api.post(`/api/v2/modules/gestao-compras/solicitacao-compras/`, data);
      setLoadingRequest(false);
      setSolicitacoes(prevData => atualizarGrupos(prevData, response.data));

      enqueueSnackbar("Solicitação de compra criada com sucesso!", { variant: "success", autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'center' } });
      setCardSelected("sync");
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      setFormData({
        dataemissao: moment().format('YYYYMMDD'),
        usuario: user.userId,
        solicitante: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
        filial: "",
        comprador_preferencial: null,
        items: []
      });
    } catch (error) {
      console.error("Error creating request:", error);
      setLoadingRequest(false);
    }
  }






  return (
    <div className='modal-custom'>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {setOpen(false)}}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative'}}>
          <Toolbar style={{ backgroundColor: '#00664e', padding: 2}}>
            <IconButton icon={<CloseIcon />} size='sm' onClick={() => setOpen(false)} />
            <div style={{width: '100%', padding: 10}}>
              <h3 style={{fontSize: 18}}>SOLICITAÇÃO DE COMPRAS</h3>
            </div>
          </Toolbar>
        </AppBar>

        <div className='form-container'>
          <SnackbarProvider maxSnack={3}/>
          <div className='form-pushare' style={{height: '100%', padding: 15, width: '90%'}}>
            <div style={{height: '100vh', marginBottom: 100}}>
            <div className='form-row' style={{height: 'auto', padding: 15, border: '1px solid #e7e4e4', borderRadius: 5, display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'column'}}>
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%', marginBottom: 5}}>
                <div className='form-group' style={{width: '50%', position: 'relative', display: 'flex', flexDirection: 'column', marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Filial</label>
                  <InputPicker
                    id="cc"
                    value={formData.filial}
                    block
                    onSelect={(value) => {
                      setFormData({ ...formData, filial: value });
                    }}
                    onClean={() => {
                      setFormData({ ...formData, filial: null });
                    }}
                    placeholder="Filial"
                    data={filialList.map((item) => 
                      ({
                          label: `${item.codigo} - ${item.descricao}`,
                          value: item?.codigo
                      }))}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 11}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>

                <div className='form-group' style={{width: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Comprador de Preferencia</label>
                  
                    <InputPicker
                      id="cc"
                      value={formData.comprador_preferencial}
                      block
                      onSelect={(value) => setFormData({ ...formData, comprador_preferencial: value })}
                      placeholder="Selecione o comprador"
                      data={listUser
                        .filter(item => item?.is_comprador)
                        .map(item => ({                     
                          label: `${item.first_name} ${item.last_name}`,
                          value: item.id
                        }))}
                      menuStyle={{
                        maxWidth: '100%',
                        overflowX: 'auto',
                      }}
                      onClean={() => {
                        setFormData({ ...formData, comprador_preferencial: null });
                      }}
                      renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                          <div style={{fontSize: 11}}>
                            {menu}
                          </div>
                        )
                      }}
                    />
                </div>
              </div>

              <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'row',width: '100%'}}>
                <div style={{width: '100%'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Marcar Usuarios</label>
                  <CustomTagInput acompanhantes={formValue.acompanhantes} usuarios={listUser} handleAcompanhantes={handleAcompanhantes}/>
                </div>
              </div>
            </div>


            <div style={{padding: 15, border: '1px solid #e7e4e4', borderRadius: 5}}>
              <div className='form-row' style={{marginBottom: 10}}>
                <div className='form-group input-product-container' style={{width: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Produto</label>
                  <InputPicker
                    block
                    id="product"
                    virtualized
                    onClean={()=>{
                      setFormValue((prevState) => ({ 
                        ...prevState, 
                        C1_PRODUTO: '', 
                        C1_DESCRI: '', 
                        C1_UM: '' 
                      }));
                    }}
                    menuMaxHeight={300}
                    style={{width: '100%'}}
                    value={formValue.C1_PRODUTO}
                    onSearch={(e) => handleProduto(e)}
                    onSelect={(value) => selectProduct(value)}
                    menuStyle={{
                      display: openList ? 'block' : 'none',
                    }}
                    placeholder="Código ou nome"
                    data={productList.map((item) => ({
                        label: item?.b1_cod,
                        value: item?.b1_cod,
                        item
                    }))}
                    menuClassName='rs-custom-picker'
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 30}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <>
                          {productList.length > 0 && (
                            <div className='menu-custom-picker-titles'>
                              <div className='item-custom-picker-title'>COD.</div>
                              <div className='item-custom-picker-title'>DESCRIÇÃO</div>
                              <div className='item-custom-picker-title'>ESPECIFICAÇÕES</div>
                              <div className='item-custom-picker-title'>UNIDADE DE MEDIDA</div>
                            </div>
                          )}
                          {menu}
                        </>
                        
                      )
                    }}
                    searchBy={() => true}
                    renderMenuItem={(label, item) => (
                      <div className='menu-custom-picker'>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker'>
                            {label}
                          </span>
                        </div>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker' title={item.item.b1_desc} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 250, display: 'inline-block'}}>
                            {item.item.b1_desc}
                          </span>
                        </div>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker' title={item.item.b1_especif} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200, display: 'inline-block'}}>
                            {item.item.b1_especif || ''}
                          </span>
                        </div>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker' style={{marginLeft:'10%'}}>
                            {item.item.b1_um}
                          </span>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className='form-row' style={{display: 'flex', flexDirection: 'row', marginBottom: 10}}>
                
                <div className='form-group' style={{width: '100%', marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Descrição</label>
                  <Input
                    type="text"
                    id="cc"
                    readOnly
                    value={formValue.C1_DESCRI}
                    placeholder=""
                  />
                </div>

                <div className='form-group' style={{width: '10%', marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Unidade</label>
                  <Input
                    type="text"
                    id="cc"
                    readOnly
                    value={formValue.C1_UM || 'UN'}
                    placeholder=""
                  />
                </div>

              </div>
              



              <div className='form-row' style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
              

                <div className='form-group' style={{marginRight: 10, position: 'relative'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Armazém</label>
                  <InputPicker

                    id="cc"
                    value={formValue.C1_LOCAL}
                    data={listAmz.map((item) => 
                      ({
                          label: `${item.nnr_codigo} - ${item.nnr_descri}`,
                          value: item?.nnr_codigo
                      }))}
                    placeholder="Amazém"
                    onSelect={(value)=>{
                      setFormValue({ ...formValue, C1_LOCAL: value });
                    }}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                  
                </div>

                <div className='form-group' style={{width: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Centro de Custo</label>
                  <InputPicker
                    id="cc"
                    value={formValue.C1_CC}
                    block
                    onSearch={(e) => handleCentroC(e.toUpperCase())}
                    onSelect={(value) => {
                      const cls = centroCList.find((cls) => cls.ctt_custo === value);
                      setFormValue({ ...formValue, C1_CC: cls.ctt_custo, centro_custo: cls.ctt_desc01 });
                      setCentroCList([cls]);
                    }}
                    placeholder="Digite o código ou nome do centro de custo"
                    data={centroCList.map((item) => 
                      ({
                          label: `${item.ctt_custo?.trim()} - ${item.ctt_desc01.trim()}`,
                          value: item?.ctt_custo?.trim()
                      }))}
                    menuStyle={{
                      maxWidth: '100%',
                      overflowX: 'auto',
                    }}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>




              <div className='form-row-grid' style={{marginBottom: 10}}>
                <div className='form-group grid' style={{marginRight: 10, position: 'relative'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Ordem de Produção</label>
                  <InputPicker

                    className='custom-input-picker'
                    autoComplete='off'
                    value={formValue.C1_OP}
                    id="cc"
                    searchBy={() => true}
                    data={listPrd.map((item) => 
                      ({
                          label: item.c2_num?.trim(),
                          value: item.c2_num?.trim()
                      }))}
                    onSearch={(e) => handlePrd(e.toUpperCase())}
                    onSelect={(value) => {
                      const prd = listPrd.find((item) => item.c2_num === value);
                      setFormValue({ ...formValue, C1_OP: prd.c2_num});
                      setListPrd([prd]);
                    }}
                    placeholder="N° da ordem de produção"
                    menuMaxHeight={200}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>


                <div className='form-group grid' style={{marginRight: 10,  position: 'relative'}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Classe de Valor</label>
                  <InputPicker

                    id="cc"
                    className='custom-input-picker'
                    autoComplete='off'
                    value={formValue.C1_CLVL}
                    onSearch={(e) => handleClass(e.toUpperCase())}
                    placeholder="Selecione"
                    menuMaxHeight={150}
                    data={listCls.map((item) => 
                      ({  
                          label: `${item.cth_clvl?.trim()}`,
                          value: item.cth_clvl?.trim()
                      }))}
                    onSelect={(value) => {
                      const cls = listCls.find((item) => item.cth_clvl === value);
                      setFormValue({ ...formValue, C1_CLVL: cls.cth_clvl, classe_valor_desc: cls.cth_desc01 });
                      setListCls([cls]);
                    }}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                  />
                </div>


                <div className='form-group grid' style={{marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Necessidade</label>
                  <Input
                    type="date"
                    autoComplete='off'
                    style={{fontSize: 12, color: '#545454'}}
                    id="cc"
                    value={formValue.C1_DATPRF}
                    onChange={(e)=>setFormValue({ ...formValue, C1_DATPRF: e })}
                    placeholder=""
                  />
                </div>
                

                <div className='form-group grid' style={{marginRight: 10}}>
                  <label style={{marginLeft: 4, fontSize: 12}}>Classificação</label>
                  <Input
                    type="text"
                    autoComplete='off'
                    readOnly
                    id="cc"
                    value={formValue.CUSTOM_CLASSIFICATION}
                    placeholder=""
                  />
                </div>

                <div className='form-group grid'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Quantidade</label>
                  <Input
                    type="number"
                    autoComplete='off'
                    id="cc"
                    value={formValue.C1_QUANT}
                    onChange={(e)=>setFormValue({ ...formValue, C1_QUANT: parseFloat(e) })}
                    placeholder=""
                  />
                </div>
                
              </div>
              
              


              <div style={{width: '100%', marginTop: 10}}>
                <label style={{marginLeft: 4, fontSize: 12}}>Observação</label>
                <Input as="textarea" rows={3} onChange={(value)=>setFormValue({ ...formValue, C1_OBS: removerCaracteresEspeciais(value.toUpperCase()) })} value={formValue.C1_OBS} placeholder="Observação" />
              </div>
            </div>
            

            </div>
            <div className='pushare-sc-bottom' style={{width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 10, height: '8%'}}>
              <IconButton title='Adicionar item' style={{backgroundColor: 'gray', color: '#fff', marginRight: 20}} icon={<ExpandOutlineIcon  style={{backgroundColor: '#636262', color: '#fff'}}/>} onClick={()=>handleAddItem()} size='sm'>
                <span style={{fontSize: 12}}>Adicionar Item</span>
              </IconButton>
            </div>
          </div>
            
          <div className='list-pushare' style={{position: 'relative', overflowY: 'hidden', padding: 5, width: '75%'}}>
            
            <div style={{overflowX: 'auto', height: '100vh', paddingLeft: 5, paddingTop: 0, paddingRight: 5}}>
              <ul className="list-container-puchare" style={{marginBottom: '21%'}}>
                <div className='list-item-puchare-title-sc' style={{top: '0px'}}>
                  <Text weight="semibold" size="md">Produto</Text>
                  <Text weight="semibold" align='center' size="md">Qtd.</Text>
                  <Text weight="semibold" align='center' size="md">Necessidade</Text>
                  <Text weight="semibold" align='center' size="md">Classificação</Text>
                  <Text weight="semibold" align='center' size="md">Qtd.Usuarios</Text>
                  <Text weight="semibold" align='center' size="md">...</Text>
                </div>   
                {loadingRequest ? (
                  <Loader content="Salvando..."  center/> 
                ):(
                  <>
                  {formData.items.length > 0 ? (
                  <>
                    {formData.items.map((item, index) => (
                      <>
                      <li key={index} className="list-item-puchare-sc">
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
                          <span>{item.C1_PRODUTO}</span>
                          <span title={item.C1_DESCRI} style={{fontSize: 9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 190, cursor: 'pointer'}}>{item.C1_DESCRI}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                          <span style={{textAlign: 'center'}}>{item.C1_QUANT}</span>
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                          <span style={{textAlign: 'center'}}>{moment(item.C1_DATPRF, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{item.CUSTOM_CLASSIFICATION}</span>
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>2</span>
                        </div>
                        

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          <IconButton 
                          appearance='primary' 
                          style={{backgroundColor: '#bbbaba', marginRight: 10}} 
                          onClick={()=> {openEdit(item);
                          const updatedItems = formData.items.filter((_, i) => i !== index);
                          setFormData({...formData, items: updatedItems});
                          }} 
                          icon={<EditIcon style={{backgroundColor: '#bbbaba', color: '#fff'}}/>} 
                          size='md'
                          />

                          <IconButton
                            appearance='primary'
                            style={{backgroundColor: 'red', marginRight: 0}}
                            onClick={() => {
                              const updatedItems = formData.items.filter((_, i) => i !== index);
                              setFormData({...formData, items: updatedItems});
                            }}
                            icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>}
                            size='md'
                          />

                  
                        </div>
                        
                      </li>
                      
                      </>
                      
                    ))}
                  </>
                ) : (
                  <div style={{minHeight: 50, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
                    <span>Nenhum item adicionado</span>
                  </div>
                )}
                  </>
                )}
              </ul>
            </div>
              <div style={{position: 'absolute' ,bottom: 0, height: '8%', zIndex: 9999, backgroundColor: '#fff', width: '100%', left: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '1%'}}>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1%'}}>
                    <IconButton appearance='primary' style={{backgroundColor: 'red', marginRight: 10}} onClick={()=> setFormData({...formData,items: []})} icon={<CloseIcon style={{backgroundColor: '#b90202', color: '#fff'}}/>} size='sm'>
                      Cancelar
                    </IconButton>
                  
                    
                    <IconButton  style={{backgroundColor: '#00664e', color: '#fff'}} icon={<CheckIcon  style={{backgroundColor: '#015743', color: '#fff'}}/>} onClick={()=>handleConclusion()} size='sm'>
                      Concluir
                    </IconButton>
                   
                </div>
              </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DataFormDrawer;
