import './gestao-compras.css'
import { useNavigate } from 'react-router-dom';
import pd_img from '../gestaoCompras/assets/pd.png';
import sc_img from '../gestaoCompras/assets/sc.png';
import { useDispatch } from 'react-redux';
import { useAxios } from '../../utils/api/api';
import {createDataset } from '../../utils/store/datasets';
import { useEffect } from 'react';

const GestaoCompras = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useAxios();

    const getNnrData = async () => {
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                params: {  
                    table: 'Nnr010'
                }
            });
            dispatch(createDataset({datasetName: 'Nnr010', data: response.data}));
        } catch (error) {
            console.error("Error fetching products:", error);
        }
       
    }
    


    const getFilialData = async () => {
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                params: {  
                    table: 'Filial'
                }
            });
            dispatch(createDataset({datasetName: 'Filial', data: response.data}));
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    }

    useEffect(() => {
        getNnrData();
        getFilialData();
    }, []);


    return(
        <div className="folder-content">
            <div className="folder-row-2">
                <div className='table-documents main'>
                    <div className='cards-container-gestao-compras'>
                        <div className="card-gestao-compras"  onClick={()=>navigate('solicitacao-compras')}>
                            <img src={sc_img} alt="sc" style={{width: '100%', height: '100%', borderRadius: 5}}/>
                        </div>
                        <div className="card-gestao-compras" onClick={()=>{navigate('pedido-compras')}}>
                           <img src={pd_img} alt="pd" style={{width: '100%', height: '100%', borderRadius: 5}}/>
                        </div>
                        {/* <div className="card-gestao-compras" style={{border: '1px solid gray', backgroundColor: '#00654e'}}>
                           <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <CiUser size={50} color='#fff' style={{marginBottom: 5}}/>
                           <strong style={{color: '#fff'}}>COMPRADORES</strong>
                           </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GestaoCompras;
