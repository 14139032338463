import { Routes, Route } from 'react-router-dom';
import GestaoQualidade from '.';
import MainLayout from '../../layouts/main';
import GestaoNaoConformidades from './apps/non_conformit';
import FormsComponent from './apps/forms';
import FormRegistroConformidade from './apps/forms/registroConformit'
import TratamentoConformidade from './apps/forms/tratamentoConformit';
import AcompanhamentoConformidade from './apps/forms/acompanhamentoConformit';
import SuccessPage from './apps/forms/successPage';
import DetalhesConformidade from './apps/non_conformit/components/detalhesConformit';
import VisualizacaoTratamentoNC from './apps/non_conformit/components/visualizacaoTratamento';
import ListaMestraComponent from './apps/gerencimantoDocumentos/components/listaMestra';

export const GqRouters = () => {
  return (
    <Routes>
        <Route path="/" element={<MainLayout><GestaoQualidade/></MainLayout>} />
        <Route path="lista-mestra" element={<MainLayout><ListaMestraComponent/></MainLayout>} />
        <Route path="gestao-nao-conformidades" element={<MainLayout><GestaoNaoConformidades/></MainLayout>} />
    </Routes>
  );
};




export const GqRoutersForms = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <FormsComponent />
        }
      />
      <Route
        path="registro"
        element={
            <FormRegistroConformidade />
        }
      />
      <Route
        path="tratamento"
        element={
            <TratamentoConformidade />
        }
      />
      <Route
        path="acompanhamento"
        element={
            <AcompanhamentoConformidade />
        }
      />
      <Route
        path="success"
        element={
            <SuccessPage />
        }
      />
      <Route
        path="visualizacao-tratamento"
        element={
            <VisualizacaoTratamentoNC />
         
        }
      />
      <Route
        path="detalhes/:id"
        element={
          <DetalhesConformidade />
        }
      />
    </Routes>
  );
};
