import { useState, useCallback } from 'react';


const defaultFilters = {
  autor: [],
  area: [],
  processo: null,
  versao: 'todas',
  status: null,
  data_criacao: null,
};

const useFilterValues = () => {
  const [filterValue, setFilterValue] = useState({
    autor: [],
    area: [],
    processo: null,
    status: null,
    data_criacao: null,
    versao: 'todas',
  });

  const [dataValues, setDataValues] = useState({
    autores: [],
    areas: [],
  });

  const updateFilter = useCallback((key, value) => {
    setFilterValue((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const mergeFilters = useCallback((newFilters) => {
    setFilterValue((prev) => ({
      ...prev,
      ...newFilters,
    }));
  }, []);

  const resetFilters = useCallback((field) => {
    if (field) {
      setFilterValue((prev) => ({
        ...prev,
        [field]: defaultFilters[field],
      }));
    } else {
      setFilterValue(defaultFilters);
    }
  }, []);


  const setAutores = useCallback((autores) => {
    setDataValues((prev) => ({ ...prev, autores }));
  }, []);

  const setAreas = useCallback((areas) => {
    setDataValues((prev) => ({ ...prev, areas }));
  }, []);

  return {
    filterValue,
    setFilterValue,
    updateFilter,
    mergeFilters,
    resetFilters,

    dataValues,       // autores, áreas disponíveis
    setAutores,
    setAreas,
  };
};
export default useFilterValues;
