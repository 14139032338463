import styles from './documentView.module.css'
import { useNavigate } from 'react-router-dom';
import { Loader, Button, Dropdown, Popover, Whisper, Form, Drawer, Modal, Input, Tabs } from 'rsuite';
import React, {useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ModalCompartilhar from '../modalCompartilhar';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import VisualizadorComponent from '../visualizador';
import { CiFileOn } from "react-icons/ci";
import TimeLine from '../drawerTimeLine';
import { ModalConclused } from '../modalReserva';
import { ModalVersion } from '../ModalVersions';
import { ModalConcluirCheckIn } from '../modalConcluirCheckIn';
import './document.css'
import { IoMdClose } from "react-icons/io";
import { useAxios } from '../../utils/api/api';


const DocumentView = ({id, documentTitle, documentName, open, setOpen}) => {
    const api = useAxios();
    const user = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const[openAnexo, setOpenAnexo] = useState(false)
    const[openModal, setOpenModal] = useState(false)
    const[documentMaster, setDocumentMaster] = useState([]);
    const[motivoReprovacao, setMotivoReprovacao] = useState(null);
    const[documentSelected, setDocumentSelected] = useState(null);
    const[openCompartilhar, setOpenCompartilhar] = useState(false);
    const[historyDoc, setHistoryDoc] = useState([]);
    const[openTimeLine, setOpenTimeLine] = useState(false);
    const[showModal, setShowModal] = useState(false);
    const[openModalVerion, setOpenModalVersion] = useState(false);
    const[loadingBtn, setLoadingBtn] = useState(false);
    const[expand, setExpand] = useState(false);
    const[openModalConcluir, setOpenModalConcluir] = useState(false);
    const[versionActive, setVersionActive] = useState(null);    



    useEffect(() => {
        openDocument()
    },[open, id])

    const openDocument = async () => {
        if(id){
            setDocumentMaster(null)
            setDocumentSelected(null)
            setHistoryDoc(null)
            setLoading(true);
            try {
                const response = await api.get(`/api/v1/documento/${id}/`);
                setLoading(false);
                setDocumentMaster(response.data)
                setDocumentSelected(response.data)
                setHistoryDoc(response.data?.document_history)
                setVersionActive(id)

            }catch(error){
                setLoading(false);
                enqueueSnackbar(`${error}`, {
                    variant:'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                })
                setTimeout(() => {
                    setOpen(false)
                }, 2500);
            }
        }
    }





    const openDocumentVersion = async (version) => {
        if(version){
            setDocumentMaster(null)
            setDocumentSelected(null)
            setHistoryDoc(null)
            setLoading(true);
            try {
                const response = await api.get(`/api/v1/documento/${version.id}/`);
                setLoading(false);
                setDocumentMaster(response.data)
                setDocumentSelected(response.data)
                setHistoryDoc(response.data?.document_history)
                setVersionActive(version.id)

            }catch(error){
                setLoading(false);
                enqueueSnackbar(`${error}`, {
                    variant:'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                })
                setTimeout(() => {
                    setOpen(false)
                }, 2500);
            }
        }
    }




  
    const SnackLoader = () => {
        enqueueSnackbar(<Loader content="Carregando"/>,{
            
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            persist: true
        })
    }

    const ApproveDocument = async (status) =>{
        SnackLoader()
        setOpenModal(false)
        if(status === 'reprovado' && motivoReprovacao === null){
            closeSnackbar()
            enqueueSnackbar('Campo de observação é obrigatório', {
                variant:'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
            return
        }
        try {
            const response = await api.patch(`/api/v1/flow/document_aproval/${id}/`, {
                status: status,
                obs: motivoReprovacao,
                data_report: moment().format(),
            })
            setMotivoReprovacao(null)
            openDocument()
            closeSnackbar()
            enqueueSnackbar(`Documento ${status} com sucesso`, {}, {
                variant:'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
            

        }catch(error){
            console.log(error)
        }
    }


    







    const handleCheckIn = async () =>{
        setLoadingBtn(true)
        try {
            const response = await api.post(`/api/v1/documento/checkin/`, {
                documento: id,
                status: '1',
            })
            closeSnackbar()
            enqueueSnackbar('Documento reservado para Atualização', {
                variant:'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
            handleConvert(documentSelected)
            setLoadingBtn(false)
            openDocument()
        }catch(error){
            console.log(error)
            setLoadingBtn(false)
        }
        
    }




 




    const handleConvert = (files) => {
        const downloadFile = (url, filename) => {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    const linkUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = linkUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(linkUrl);
                })
                .catch(error => console.error('Download failed:', error));
        };
    
        const getFileNameFromUrl = (url, defaultName) => {
            if (!url) return defaultName;
    
            const urlParts = url.split('/');
            const lastPart = urlParts[urlParts.length - 1];
            const fileNameWithParams = lastPart.split('?')[0];
    
            const extensionMatch = fileNameWithParams.match(/\.[^/.]+$/);
            const extension = extensionMatch ? extensionMatch[0] : '';
            return `${defaultName}${extension}`;
        };
    
        const mainFileName = getFileNameFromUrl(files.link, files.codigo);
        downloadFile(files.link, mainFileName);
    
        if (files.children && files.children.length > 0) {
            files.children.forEach((file) => {
                const attachmentFileName = getFileNameFromUrl(file.link, file.anexo_name);
                downloadFile(file.link, attachmentFileName);
            });
        }
    };
    
    



    const renderMenu = ({ onClose, left, top, className }, ref) => {
        const {status, user_permission, autor, document_checkin, document_approval, document_history} = documentMaster || {};
        const {can_edit, can_shared_documents, can_download} = user_permission || {};
        const active_version = document_history?.filter((item)=>item.is_active === true)[0];
        const authorId = autor?.id;
        const handleSelect = eventKey => {
          onClose();
          console.log(eventKey);
        };
        

        const teste = () =>{
            console.log(user_permission)
        }

       

     

        return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu onSelect={handleSelect}>
                <Dropdown.Item eventKey={1} onClick={() => {onClose();setExpand(prev=>!prev); teste()}}>Propriedades</Dropdown.Item>
                
                {versionActive === active_version?.id && (
                    <Dropdown.Item eventKey={2} onClick={() => {onClose(); setOpenTimeLine(true) }}>TimeLine</Dropdown.Item>
                )}

                {document_approval && 
                    <>
                    <Dropdown.Separator />
                    <Dropdown.Item eventKey={6} onClick={() => { onClose(); ApproveDocument('aprovado'); }}>Aprovar Documento</Dropdown.Item>
                    <Dropdown.Item eventKey={6} onClick={() => { onClose(); setOpenModal(true); }}>Reprovar Documento</Dropdown.Item>
                    <Dropdown.Separator />
                    </>
                }

                
                {can_edit && status === '1' && active_version.id === versionActive && document_checkin === null && (
                    <Dropdown.Item eventKey={2} onClick={() => { onClose(); handleCheckIn(); }}>
                        Fazer Check-Out
                    </Dropdown.Item>
                )}
                

                {(status === '2' && (parseInt(authorId) === parseInt(user.userId)) && active_version.id === versionActive && (
                    <Dropdown.Item eventKey={2} onClick={() => {onClose(); handleCheckIn(); }}>
                    Reeviar Documento
                    </Dropdown.Item>
                )
                )}
                {can_download && status === '1' && active_version.id === versionActive && (
                    <Dropdown.Item eventKey={3} onClick={() => {onClose(); handleConvert(documentSelected)}}>Download</Dropdown.Item>
                )}



                <Dropdown.Item eventKey={4} onClick={() => {onClose(); setOpenAnexo(prev => !prev); }}>Listar Documentos</Dropdown.Item>

                {status === '1' && can_shared_documents && active_version.id === versionActive && (
                    <Dropdown.Item eventKey={5} onClick={() => { onClose(); setOpenCompartilhar(true)}}>Compartilhar</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Popover>
        
        );
    };




    const handleSelectVersion = (version) => {
        openDocumentVersion(version)  
        setExpand(false)      
    }

   
   
    

    return (
        <Drawer backdrop='static' size="full" placement='bottom' open={open} onClose={() => {setOpen(false); setDocumentMaster([]); setDocumentSelected(null); setVersionActive(null)}}>
        <Drawer.Body style={{margin: 0, padding: 0}}>
        <SnackbarProvider/> 
        {loading ? (
            <Loader content="Carregando" vertical size="sm" center backdrop  style={{zIndex:9999}}/>
        ):(
            <div className={styles.folder_content}>
            
            <div className={styles.document_view_container}>
                <div className={styles.column_1}>
                    <div className={styles.column_1_top_view}>
                        <div className={styles.column_1_top_01}>
                            <Button className={styles.buttom_checkout} appearance='primary' size="sm" onClick={()=>{setOpen(false); setDocumentMaster([]); setDocumentSelected(null); setVersionActive(null)}}>Sair</Button>
                            <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
                                <Button className={styles.buttom_checkout} appearance='primary' size="sm" loading={loadingBtn}>Ações do documento</Button>
                            </Whisper> 
                        </div>
                    </div>
                    <div className={styles.column_1_body}>
                        {openAnexo ? (
                            <div className={styles.document_anexos} style={{overflowY: 'scroll'}}>
                                {Array(documentMaster).map((item, index)=>(
                                    <>
                                    <strong>Principal</strong>
                                        <div className={styles.document_anexos_item} key={index} onClick={()=>{setOpenAnexo(false);setDocumentSelected(item)}}>
                                            <div><strong>{item?.codigo} - {item?.titulo}</strong></div>
                                        </div>
                                    <strong>Anexos</strong>    
                                        {item?.children?.map((anexo, index)=>(
                                            <div className={styles.document_anexos_item} key={index} onClick={()=>{setOpenAnexo(false);setDocumentSelected(anexo)}}>
                                                <div><strong>{anexo?.anexo_name}</strong></div>
                                            </div>
                                        ))}
                                    </>
                                
                                ))}
                            </div>
                        ):(
                            <VisualizadorComponent documento={documentSelected}/>
                        )}
                        
                    </div>
                </div>
                <Drawer className='drawer-document-property' closeButton={false} size='xs' placement='right' open={expand} onClose={() => setExpand(false)}>
                    <Drawer.Body style={{padding: 0, margin: 0, position: 'relative'}}>
                    <Tabs defaultActiveKey="1" style={{display: 'flex', alignItems: 'space-between', justifyContent: 'center', padding: 10}} appearance="subtle">
                        
                        <Tabs.Tab eventKey="1" title="Sobre">
                        <div className={styles.column_2}>
                            <div className={styles.document_properts}>
                                <div className={styles.document_properts_colum_2}>
                                <Form fluid readOnly>
                                    <Form.Group className={styles.rs_custom_control}>
                                    <Form.ControlLabel>Titulo</Form.ControlLabel>
                                    <Form.Control className={styles.document_input} name='text' value={documentMaster?.titulo}/>
                                    </Form.Group>

                                    <Form.Group className={styles.rs_custom_control}>
                                    <Form.ControlLabel>Codigo</Form.ControlLabel>
                                    <Form.Control className={styles.document_input} name='text' value={documentMaster?.codigo}/>
                                    </Form.Group>

                                    <Form.Group className={styles.rs_custom_control}>
                                    <Form.ControlLabel>Criado em</Form.ControlLabel>
                                    <Form.Control className={styles.document_input} name='text' value={documentMaster?.data_criacao ? moment(documentMaster?.data_criacao).format('L') : null}/>
                                    </Form.Group>

                                    <Form.Group className={styles.rs_custom_control}>
                                    <Form.ControlLabel>Processo</Form.ControlLabel>
                                    <Form.Control className={styles.document_input} name='text' value={documentMaster?.document_processo || 'Sem Processo'}/>
                                    </Form.Group>

                                    <Form.Group className={styles.rs_custom_control}>
                                    <Form.ControlLabel>Autor</Form.ControlLabel>
                                    <Form.Control className={styles.document_input} name='text' value={documentMaster?.autor ? documentMaster?.autor?.first_name + ' ' + documentMaster?.autor?.last_name: null}/>
                                    </Form.Group>

                                    <Form.Group className={styles.rs_custom_control}>
                                    <Form.ControlLabel>Versão</Form.ControlLabel>
                                    <Form.Control className={styles.document_input} name='text' value={documentMaster?.versao}/>
                                    </Form.Group>

                                    <Form.Group className={styles.rs_custom_control}>
                                    <Form.ControlLabel>Privacidade</Form.ControlLabel>
                                    <Form.Control className={styles.document_input} name='text' value={documentMaster?.privacidade === '3' ? 'Uso Interno' : documentMaster?.privacidade === '2' ? 'Confidencial' : documentMaster?.privacidade === '1' ? 'Restrita' : documentMaster?.privacidade === '0' ? 'Publica' : null}/>
                                    </Form.Group>
                                </Form>

                                </div>
                                
                            </div>
                        </div>
                        </Tabs.Tab>
                        <Tabs.Tab eventKey="2" title="Histórico">
                        <div>        
                        <ul>
                            {historyDoc?.map((item, index)=>(
                                <>
                                <li key={index} className={versionActive === item.id ? 'version-data active' : 'version-data'} style={{marginBottom: 10, cursor: 'pointer'}} onClick={()=>handleSelectVersion(item)}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10, borderRadius: 5}}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <CiFileOn style={{fontSize: 30}}/>
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: 12}}>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <strong style={{marginRight: 5}}>{item?.autor_first_name}</strong>
                                            <strong>{item?.autor_last_name}</strong>
                                            </div>
                                            <span>Versão: {item?.versao}</span>
                                        </div>
                                        </div>
                                    </div>
                                </li>
                                </>
                            ))}
                        </ul>
                        </div>
                        </Tabs.Tab>
                        {/* <Tabs.Tab eventKey="3" title="Comentários">
                        <span>03</span>
                        </Tabs.Tab> */}
                        
                    </Tabs>
                    <IoMdClose onClick={()=>setExpand(false)} style={{position: 'absolute', top: '3%', right: '5%', cursor: 'pointer'}}>Fechar</IoMdClose>
                    
                    </Drawer.Body>
                </Drawer>
            </div>
            <Modal open={openModal} onClose={()=>setOpenModal(false)}>
                <Modal.Header>
                    <Modal.Title>Motivo de reprovação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input as="textarea" rows={3} onChange={(text)=>setMotivoReprovacao(text.toUpperCase())} placeholder="Textarea" />
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={()=>ApproveDocument('reprovado')} appearance="primary">
                    Ok
                </Button>
                <Button onClick={()=>setOpenModal(false)} appearance="subtle">
                    Cancel
                </Button>
                </Modal.Footer>
            </Modal>
            <ModalCompartilhar id={id} open={openCompartilhar} setOpen={setOpenCompartilhar} tipo='document'/>
            <TimeLine open={openTimeLine} setOpen={setOpenTimeLine} document={documentSelected?.id}/>
            <ModalConclused showModal={openModalConcluir} setShowModal={setOpenModalConcluir} reserva_id={null} getDocuments={null}/>
            <ModalVersion showModal={openModalVerion} setShowModal={setOpenModalVersion} documentList={historyDoc}/>
            <ModalConcluirCheckIn showModal={showModal} setShowModal={setShowModal} document={documentMaster}/>
        
        </div> 
        )}
        
        
        </Drawer.Body>
        </Drawer>
        
    )
}
export default DocumentView;


