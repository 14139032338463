import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { BASE_URL, ENVIROMENT } from './base';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { logout } from '../store/auth';
import { useState } from 'react';

export const useAxios = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const auth = useSelector(state => state.auth);
  const baseURL = ENVIROMENT === 'dev' ? `${BASE_URL}/dev` : BASE_URL;

  const api = axios.create({
    baseURL: `${baseURL}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });


  

  api.interceptors.request.use(
    (config) => {
      const token = auth?.token?.access;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isCancel(error)) {
        console.log("Requisição cancelada:", error.message);
        return new Promise(() => {});
      }
      let errorMessage = "Erro desconhecido";
      if(error.config && error.config.url === "/pasta/main/") {
        errorMessage = "Acesso negado";
        enqueueSnackbar(errorMessage, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 1000,
        });
        return Promise.reject(error);
      }
      if (
        error.config &&
        error.config.url.includes("/documento/") &&
        error.config.url !== "/api/v1/documento/checkin/"
      ) {
        errorMessage = "Acesso negado";
        enqueueSnackbar(errorMessage, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 1000,
        });
        return Promise.reject(errorMessage);
      }
      if(error.config && error.config.url === "/api/v1/documento/checkin/") {
        errorMessage = `${error.response.data.error}`;
        enqueueSnackbar(errorMessage, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 1000,
        });
        return Promise.reject(errorMessage);
      }

      if (error.response) {
        if (error.response.status === 401) {
          errorMessage = "Credenciais inválidas. Faça login novamente.";
          window.location.href = "/login";
          dispatch(logout());
        } else if (error.response.status === 500) {
          errorMessage =
            "Erro interno do servidor. Tente novamente mais tarde.";
        } else {
          errorMessage = `Erro: ${error.response.statusText}`;
        }
      } else if (error.request) {
          errorMessage = "Nenhuma resposta do servidor. Verifique sua conexão.";
      } else if (auth.uuid === "") {
          errorMessage = "Efetue o login novamente.";
          window.location.href = "/login";
          dispatch(logout());
      } else {
          errorMessage = "Erro ao processar a solicitação.";
          
      }
      enqueueSnackbar(errorMessage, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 1000,
      });

      return Promise.reject(errorMessage);
    }
  );

  return api;
};
