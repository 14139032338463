import { useState, useEffect } from "react";
import { Realtime } from "ably";
import { useDispatch, useSelector } from "react-redux";
import { updateMessage } from "../store/channelMessage";
import { ABLY_KEY } from "./base";

const useAbly = (channelName, eventName, userId) => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.channelMessage.messages);

  useEffect(() => {
    const client = new Realtime({ key: ABLY_KEY });
    const ablyChannel = client.channels.get(channelName);

    const messageHandler = (message) => {
      if (parseInt(message.data.userId) === parseInt(userId)) {
        const formattedMessage = {
          userId: message.data.userId,
          id: message.data.id,
          time: message.data.time,
          message: message.data.message,
          uuid: message.data.uuid,
          tipo: message.data.tipo,
          numero: message.data.numero,
          error_message: message.data.error_message,
          path: message.data.path,
          is_update: false,
          is_view: false,
        };
        dispatch(updateMessage(formattedMessage));
      }
    };

    ablyChannel.subscribe(eventName, messageHandler);

    return () => {
      ablyChannel.unsubscribe(eventName, messageHandler);
      client.close();
    };
  }, [channelName, eventName, userId, dispatch]);

  return { messages };
};

export default useAbly;
