export const atualizarStatusGrupo = (
  prevGrupos,
  uuid,
  novoStatus,
  novoNumero,
  errorMessage,
  isError,
  success
) => {
  return prevGrupos.map((grupo) =>
    grupo.uuid === uuid
      ? {
          ...grupo,
          status: novoStatus,
          numero: novoNumero,
          error_message: errorMessage,
          is_error_sync: isError,
          sync_success: success,
          data: grupo.data.map((item) => ({
            ...item,
            status: novoStatus,
          })),
        }
      : grupo
  );
};

export const agruparSolicitacoes = (dados) => {
  return dados.reduce((acc, item) => {
    const grupoExistente = acc.find((grupo) => grupo.uuid === item.uuid);

    if (grupoExistente) {
      grupoExistente.data.push(item);
    } else {
      acc.push({
        numero: item.cp_num,
        uuid: item.uuid,
        solicitante: item.cp_solicit,
        is_error_sync: item.is_error_sync,
        sync_success: null,
        error_message: item.error_message,
        filial: item.cp_filial,
        status: item.status,
        data: [item],
      });
    }
    return acc;
  }, []);
};

export const atualizarGrupos = (prevGrupos, novosDados) => {
  const novosGrupos = agruparSolicitacoes(novosDados);

  return prevGrupos
    .map((grupoExistente) => {
      const grupoNovo = novosGrupos.find((g) => g.uuid === grupoExistente.uuid);

      if (grupoNovo) {
        return {
          ...grupoExistente,
          ...grupoNovo,
          data: atualizarItens(grupoExistente.data, grupoNovo.data),
        };
      }
      return grupoExistente;
    })
    .concat(
      novosGrupos.filter(
        (g) => !prevGrupos.some((grupo) => grupo.uuid === g.uuid)
      )
    );
};

export const atualizarItens = (itensExistentes, novosItens) => {
  return itensExistentes
    .map((itemExistente) => {
      const itemNovo = novosItens.find(
        (novoItem) =>
          (itemExistente.r_e_c_n_o_field ?? itemExistente.id) ===
          (novoItem.r_e_c_n_o_field ?? novoItem.id)
      );

      return itemNovo ? { ...itemExistente, ...itemNovo } : itemExistente;
    })
    .concat(
      novosItens.filter(
        (novoItem) =>
          !itensExistentes.some(
            (itemExistente) =>
              (itemExistente.r_e_c_n_o_field ?? itemExistente.id) ===
              (novoItem.r_e_c_n_o_field ?? novoItem.id)
          )
      )
    );
};
