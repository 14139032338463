import { useNavigate } from 'react-router-dom';


const GestaoQualidade = () => {
    const navigate = useNavigate();
    
    return(
        <div className="folder-content">
            <div className="folder-row-2">
                <div className='table-documents main'>
                    <div className='cards-container-gestao-compras'>
                        {/* <div className="card-gestao-compras"  style={{border: '1px solid gray'}} onClick={()=>navigate('solicitacao-compras')}>
                            <span>GESTÃO</span>
                        </div> */}
                        <div className="card-gestao-compras" onClick={()=>navigate('lista-mestra')} style={{border: '1px solid gray'}}>
                           <span>LISTA MESTRA</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GestaoQualidade;

