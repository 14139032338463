import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channel_name: "",
  messages: [],
  userId: "",
};

export const channelMessage = createSlice({
  name: "channelMessage",
  initialState,
  reducers: {
    changeChannel: (state, { payload }) => {
      state.channel_name = payload.channel_name || "";
      state.messages = payload.messages || [];
      state.userId = payload.userId || "";
    },
    deleteChannel: () => {
      return { ...initialState };
    },
    deleteMessage: (state) => {
      state.messages = [];
    },
    deleteDistincMessage: (state, { payload }) => {
      state.messages = state.messages.filter((msg) => msg.uuid !== payload);
    },
    updateMessage: (state, { payload }) => {
      state.messages = state.messages.filter(
        (msg) => msg.uuid !== payload.uuid
      );
      state.messages.push(payload);
    },
    toggleIsUpdate: (state, { payload }) => {
      const { id, path, is_update } = payload;
      state.messages = state.messages.map((msg) =>
        msg.id === id && msg.path === path ? { ...msg, is_update } : msg
      );
    },
    toggleIsView: (state, { payload }) => {
      const { id, path, is_view } = payload;
      state.messages = state.messages.map((msg) =>
        msg.id === id && msg.path === path ? { ...msg, is_view } : msg
      );
    },
  },
});

export const {
  changeChannel,
  deleteChannel,
  deleteMessage,
  updateMessage,
  deleteDistincMessage,
  toggleIsUpdate,
  toggleIsView,
} = channelMessage.actions;

export const selectChannelMessage = (state) => state.channelMessage;

export default channelMessage.reducer;
