import './list-solicitacoes.css';
import { useState, useEffect, useRef } from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { Loader, Progress, Drawer, Button, Modal, Whisper, Dropdown, Popover } from 'rsuite';
import { MdOutlineErrorOutline } from "react-icons/md";
import TimeLineSc from '../timeLine';
import MoreIcon from '@rsuite/icons/legacy/More';
import { FaRegEye } from "react-icons/fa";
import SaOrderView from '../saView';
import { RiLoader3Fill } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import {toggleIsUpdate} from '../../../../../../utils/store/channelMessage';
import {atualizarGrupos, atualizarStatusGrupo} from '../../../../utils/groupdata'
import { FaRegCircleCheck } from "react-icons/fa6";

const ListSolicitacoesAmz = ({ solicitacoes, loading, setLoading, setSolicitacoes, handleGroupData }) => {
    const api = useAxios();
    const dispatch = useDispatch();
    const channelsMessage = useSelector(state => state.channelMessage.messages);
    const [expandId, setExpandId] = useState(null);
    const [openView, setOpenView] = useState(false);
    const [ItemSelect, setItemSelect] = useState(null);
    const [open, setOpen] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [dataError, setDataError] = useState(null);
    const modalRef = useRef(null);

    

    

    const toggleExpand = (index) => {
        setExpandId(prevId => (prevId === index ? null : index));
    };



    const renderMenu = ({ onClose, left, top, className }, ref, Data) => {
        return (
            <Popover ref={ref} className={className} style={{ left, top, margin: 0, padding: 0 }}>
            <Dropdown.Menu style={{padding: 0, margin: 0}}>
                <Dropdown.Item eventKey={1} 
                    icon={<FaRegEye className='rs-icon'/>}
                    onClick={(e) => {
                        e.stopPropagation();
                        setItemSelect(Data);
                        setOpenView(true);
                        onClose();
                    }}
                >
                    Visualizar
                </Dropdown.Item>
                {/* <Dropdown.Separator style={{padding: 0, margin: 0}}/>
                <Dropdown.Item 
                    disabled={Data?.numero !== '00000' && !Data?.data[0]?.is_error_sync ? true : false}
                    icon={<MdDeleteOutline 
                    className='rs-icon'/>} 
                    eventKey={4}
                    onClick={(e) => {
                        onClose();
                        e.stopPropagation();
                        handleDelete(Data.uuid);
                        }
                    }
                    >
                        Excluir
                </Dropdown.Item> */}
            </Dropdown.Menu>
            </Popover>
        );
    };


    const handleUpdateStatus = (data) =>{
        const {uuid, tipo, numero, error_message} = data;
        let status = tipo === 'SUCESSO' ? 'PENDENTE' : 'SY';
        let sync_success = tipo === 'SUCESSO' ? true : false;
        let IsError = tipo === 'ERRO' ? true : false;
        setSolicitacoes(prevGrupos => atualizarStatusGrupo(prevGrupos, uuid, status, numero, error_message ,IsError, sync_success));
    }


    useEffect(() => {
        if (channelsMessage.length > 0) {
            const mensagensNaoAtualizadas = channelsMessage.filter(msg => !msg.is_update && msg.path === 'solicitacao_amz');
            if (mensagensNaoAtualizadas.length > 0) {
                mensagensNaoAtualizadas.forEach((msg) => {
                    dispatch(toggleIsUpdate({ id: msg.id, path: "solicitacao_amz", is_update: true }));
                    handleUpdateStatus(msg);
                });
            }
        }
    }, [channelsMessage]);


    

    const handleReeviarSc = async (uuid) => {  
        try {
            setLoading(true);
            setOpenModalError(false);
            const response = await api.get('/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/reeviar_solicitacao/', {
                params: {
                    uuid: uuid
                }
            });
            setLoading(false);
            setSolicitacoes(prevData => atualizarGrupos(prevData, response.data));
        } catch (error) {
            console.log(error);
        }
    }

    

    const handleStatus = (values) => {
        if(values === 'PRÉ-REQUISIÇÃO PENDENTE DE ATENDIMENTO'){
            return 'PRÉ-REQ. PENDENTE'
        }
        return values
    }

    

    return (
        <div className="sc-purchase-list-container">
            <div className="sc-purchase-list-titles amz">
                <div className="sc-purchase-list-title-item item-left" style={{display: 'flex', alignItems: 'flex-end'}}>FILIAL</div>
                <div className="sc-purchase-list-title-item item-left">NUMERO</div>
                <div className="sc-purchase-list-title-item">SOLICITANTE</div>
                <div className="sc-purchase-list-title-item">DATA DA SOLICITAÇÃO</div>
                <div className="sc-purchase-list-title-item">CENTRO DE CUSTO</div>
                <div className="sc-purchase-list-title-item">QTD. ITENS</div>
                <div className="sc-purchase-list-title-item">STATUS</div>
                <div className="sc-purchase-list-title-item item-right">...</div>
            </div>
            <>
                {loading ? (
                    <div style={{ marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader center content="Carregando..." style={{ position: 'relative', marginTop: '5%' }} />
                    </div>
                ) : (
                    <>
                        {solicitacoes.length === 0 ? (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, width: '100%'}}>
                                <span style={{fontSize: 16, color: 'gray'}}>Nenhuma solicitação encontrada</span>
                            </div>
                        ) : (
                            <>
                                {solicitacoes.map((processos, index) => {
                                    return (
                                        <ul key={index} className='sc-purchase-list-items' style={{textTransform: 'uppercase'}}>
                                            <li>
                                                <div className='sc-pushase-item-container'>
                                                    <div className="sc-purchase-list-item-content amz" onClick={(e) => {e.stopPropagation();toggleExpand(index)}} style={{cursor: 'pointer'}}>
                                                        <div className="sc-purchase-list-item" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginLeft: 5}}>
                                                            <span className="item-manager">
                                                                {processos.filial}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                            <span className="item-manager">
                                                                {processos.numero}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                SOLICITANTE
                                                            </span>
                                                            <span className="item-manager">
                                                                {processos.solicitante}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                DATA DA SOLICITAÇÃO
                                                            </span>
                                                            <span className="item-manager">
                                                                {moment(processos.data[0]?.cp_emissao).format('DD/MM/YYYY')}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                CENTRO DE CUSTO
                                                            </span>
                                                            <span className="item-manager desktop">
                                                                {processos.data[0]?.centro_custo || 'SEM CENTRO DE CUSTO'}
                                                            </span>
                                                            <span className="item-manager mobile">
                                                                {processos.data[0]?.centro_custo || 'SEM CC'}
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                QTD. ITENS
                                                            </span>
                                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                {processos.data.length}
                                                            </span>
                                                        </div>

                                                        <div className="purchase-list-item">
                                                            <span className="item-manager" title={processos?.status} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                                {processos?.status === 'SY' ? (
                                                                <>
                                                                    {processos?.is_error_sync ? (
                                                                        <>
                                                                        <MdOutlineErrorOutline style={{fontSize: 30, cursor: 'pointer'}} className="text-4xl text-red-500" onClick={(e)=>{e.stopPropagation();setDataError({message: processos.error_message, uuid: processos.uuid}); setOpenModalError(true)}} title='ERRO NO ENVIO'/>
                                                                        </>
                                                                    ):(
                                                                        <RiLoader3Fill style={{fontSize: 30, cursor: 'pointer'}} title='ENVIANDO..' className="animate-spin text-4xl text-blue-600"/>
                                                                    )}
                                                                </>
                                                                ):processos.sync_success ? (
                                                                    <>
                                                                    <FaRegCircleCheck style={{fontSize: 25, cursor: 'pointer'}} className="text-4xl text-green-700" title='ENVIADO COM SUCESSO'/>
                                                                    </>
                                                                ): (
                                                                    <>
                                                                    {handleStatus(processos.status)}
                                                                    </>
                                                                )}
                                                                
                                                            </span>
                                                        </div>

                                                        <div className="sc-purchase-list-item">
                                                            <span className="item-title-sm">
                                                                ...
                                                            </span>
                                                            <Whisper placement="auto" trigger="click" speaker={(props, ref) => renderMenu(props, ref, processos)}>
                                                                <MoreIcon className="list-documents-icon" style={{ cursor: 'pointer', fontSize: 30, marginLeft: 5, backgroundColor: 'gray' }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                />
                                                            </Whisper>
                                                        </div>
                                                    </div>
                                                    {expandId === index && (
                                                        <>
                                                            {processos?.data?.map((item, index) => (
                                                                <div index={index} onClick={(e)=>e.stopPropagation()} className="sa-sub-purchase-list-item-content" style={{ cursor: 'pointer' }}>
                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand' title={`${item?.cp_produto?.trim()} - ${item?.cp_descri?.trim()}`}>
                                                                            <span className="item-title-expadend">
                                                                                ITEM
                                                                            </span>
                                                                            <span className="item-data-expand" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 150}}>
                                                                                {item?.cp_descri}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa' title='NUMERO DO ARMAZÉM'>
                                                                            <span className="item-title-expadend">
                                                                                AMZ
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_local || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa' title='NUMERO DA SOLICITAÇÃO DE COMPRAS'>
                                                                            <span className="item-title-expadend">
                                                                                NÚMERO DA SC
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.sc?.value || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa' title='NUMERO DO PEDIDO DE COMPRAS'>
                                                                            <span className="item-title-expadend">
                                                                                NÚMERO DO PEDIDO
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.pd?.value || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa' title='NUMERO DA NOTA FISCAL'>
                                                                            <span className="item-title-expadend">
                                                                                N° NF
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.relacoes_pd?.pd?.nf_data || 'SEM NF'}
                                                                            </span>
                                                                        </div>
                                                                    </div>


                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa'>
                                                                            <span className="item-title-expadend" title='NUMERO DA ORDEM DE SERVIÇO'>
                                                                                O.S
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_numos?.trim() || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa' title='NUMERO DA ORDEM DE PRODUÇÃO'>
                                                                            <span className="item-title-expadend">
                                                                                O.P
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_op?.trim() || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa' title='CLASSE DE VALOR'>
                                                                            <span className="item-title-expadend">
                                                                                CVLR
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_clvl?.trim() || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa' title='UNIDADE DE MEDIDA'>
                                                                            <span className="item-title-expadend">
                                                                                UN.
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_um || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item">
                                                                        <div className='item-expand sa'>
                                                                            <span className="item-title-expadend">
                                                                                QTD.
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {item?.cp_quant}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="sc-sub-purchase-list-item" style={{whiteSpace: 'nowrap', marginLeft: 5}}>
                                                                        <div className='item-expand sa'>
                                                                            <span className="item-title-expadend">
                                                                                STATUS
                                                                            </span>
                                                                            <span className="item-data-expand">
                                                                                {handleStatus(item?.status)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </>

            
            <Modal size="full" overflow={false} open={openView} onClose={()=>setOpenView(false)}>
                <Modal.Header>
                    <div style={{backgroundColor: '#00664e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                            <Modal.Title style={{fontSize: 15, color: '#fff'}}>SOLICITAÇÃO ARMAZÉM</Modal.Title>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Button onClick={()=>setIsPrint(true)}  appearance="primary" style={{backgroundColor: '#fff', color: 'black', fontSize: 12, borderRadius: 3, marginRight: 10}}>
                                    IMPRIMIR
                                </Button>
                                <Button onClick={()=>setOpenView(false)} appearance="primary" style={{backgroundColor: '#fff', color: 'black', fontSize: 12, borderRadius: 3}}>
                                    FECHAR
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <SaOrderView data={ItemSelect} print={isPrint} isPrint={setIsPrint}/>
                </Modal.Body>
            </Modal>
            
            <Modal overflow={false} open={openModalError} onClose={()=>setOpenModalError(false)} size="sm">
                <Modal.Header>
                    <Modal.Title style={{fontSize: 12}}>Erro na sincronização</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{minHeight: 300, backgroundColor: '#c7c6c698', padding: 10}}>
                        <code style={{fontSize: 12, fontWeight: 'bold'}}>
                            {
                                dataError?.message || 'Erro não identificado'
                            }
                        </code>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button appearance="primary" onClick={()=>handleReeviarSc(dataError.uuid)} style={{backgroundColor: 'gray'}}>
                    Reenviar
                </Button>
                <Button onClick={()=>setOpenModalError(false)} appearance="subtle">
                    Sair
                </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default ListSolicitacoesAmz;