import ListSolicitacoesAmz from "./components/listSolicitacoesAmz";
import PlusIcon from '@rsuite/icons/Plus';
import { IconButton, Input, InputGroup, Popover, InputPicker, Button, Whisper, Loader, Pagination } from "rsuite";
import DataFormDrawerAmz from "./components/dataFormDrawerAmz";
import { useState, useEffect, useRef, useContext, useCallback } from "react";
import DataCardsAmz from "./components/dataCardsAmz";
import { useAxios } from "../../../../utils/api/api";
import FunnelIcon from '@rsuite/icons/Funnel';
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import { SaAmzContext } from '../../utils/AmzContext';
import { debounce } from 'lodash';
import {agruparSolicitacoes} from '../../utils/groupdata';
import { useSelector } from "react-redux";



const statusList = [
    {label: 'Pré-requisição pendente', value: 'pendente_pre'},
    {label: 'Convertida em sc', value: 'convertida_compra'},
    {label: 'Em transporte', value: 'transporte'},
    {label: 'Concluida por requisição', value: 'baixado'},
    {label: 'Concluida por pedido de compra', value: 'atendido'},
]





const SolicitacaoAmz = () => {
    const api = useAxios();
    const datasets = useSelector((state) => state.datasets);
    const location = useLocation();
    const uiidState = location.state?.uuid;
    const { cardSelected, setCardSelected } = useContext(SaAmzContext);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const triggerRef = useRef();
    const [isOpen, setIsOpen] = useState(false) 
    const openFilter = () => triggerRef.current.open();
    const closeFilter = () => triggerRef.current.close();
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterOption, setFilterOption] = useState('1');
    const [filialList, setFilialList] = useState([]);
    const [loadingPicker, setLoadingPicker] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [centro_custos, setCentroCustos] = useState([]);
    const [functionPage, setFunctionPage] = useState('pd');

    const [listUser, setListUser] = useState([]);
    const [listClasse, setListClasse] = useState([]);
    const [listArmazem, setListArmazem] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [filterDinamic, setFilterDinamic] = useState(false);
    const [pagination, setPagination] = useState({
      links: {
        next: null,
        previous: null,
      },
      querys: {
        filter: "",
        status: "",
        type: "",
        option: ""
      },
      path: "",
      current: 1,
      total_pages: 1,
      total_items: 1,
      page_size: 1,
    });
    const [filterValue, setFilterValue] = useState({
        solicitante: '',
        data_inicio: '',
        armazem: '',
        classe: '',
        produto: '',
        data_fim: '',
        centro_custo: '',
        status: '',
        filial: ''
    });

   

    useEffect(() => {
        if(!isFilter){
            setFunctionPage('pd');
            setFilterDinamic(false);
            const controller = new AbortController();
            if(cardSelected === 'pendentes'){
                handleGetSolicitacoes("pendentes", controller);
            }else if(cardSelected === 'convertidos'){
                handleGetSolicitacoes("convertidos", controller);
            }else if(cardSelected === 'transporte'){
                handleGetSolicitacoes("geradas", controller);
            }else if(cardSelected === 'todas'){
                handleGetSolicitacoes("todas", controller);
            }else if(cardSelected === 'sync'){
                handleGetSolicitacoes("sync", controller);
            }
            return () => {
                controller.abort();
            }
        }
    }, [cardSelected]);


    useEffect(() => {
        if(uiidState){
            handleGetSolicitacoesByUiid(uiidState);
        }
    }, [uiidState, location.key]);

    const handleGetSolicitacoesByUiid = async (uuid) => { 
        setLoading(true);
        setCardSelected(null);
        try {
            const response = await api.get(`/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/filter_solicitacoes_by_uuid/${uuid}/`);
            setLoading(false);
            const data = response.data.map(item => ({
            ...item,
            r_e_c_n_o_field: item.r_e_c_n_o_field ?? item.id
            }));
            const gruposFormatados = agruparSolicitacoes(data);
            setSolicitacoes(gruposFormatados);
        } catch (error) {
            console.log(error);
        }
    }
    

    const handleGetSolicitacoes = async (tipo, controller) => {
        setSolicitacoes([]);
        setLoading(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/',{
                params: {
                    type: tipo
                },
                signal: controller.signal
            });
            if(tipo != "sync"){
                setLoading(false);
                console.log(response.data);
                const gruposFormatados = agruparSolicitacoes(response.data.results);
                setSolicitacoes(gruposFormatados);
                setPagination({
                    links: {
                        next: response.data.links.next,
                        previous: response.data.links.previous
                    },
                    querys:{
                        type: tipo
                    },
                    path: '/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/',
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });
                return;
            }else{
                setLoading(false);
                const gruposFormatados = agruparSolicitacoes(response.data);
                setSolicitacoes(gruposFormatados);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateFilterValue = (field, value) => {
        setFilterValue(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleSearchDynamic = useCallback(
        debounce(async (value, setCardSelected, setLoading, filterOption, agruparSolicitacoes, setSolicitacoes) => {
            if (value.length > 0) {
            setLoading(true);
            setCardSelected(null);
            try {
                const response = await api.get('/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/sa_data_filter',{
                    params: {
                        filter: value,
                        option: filterOption
                        
                    }
                });
                setSolicitacoes([]);
                const gruposFormatados = agruparSolicitacoes(response.data.results);
                setSolicitacoes(gruposFormatados);
                setPagination({
                    links: {
                        next: response.data.links.next,
                        previous: response.data.links.previous
                    },
                    querys:{
                        filter: value,
                        option: filterOption
                    },
                    path: '/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/sa_data_filter',
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
            }
        }, 500),
        []
    );


    const handleCustomFilter = (value) => {
        setInputValue(value.toUpperCase());
        handleSearchDynamic(value, setCardSelected, setLoading, filterOption, agruparSolicitacoes, setSolicitacoes);
    }

    useEffect(() => {
        setInputValue('');
    }, [filterOption]);


    useEffect(() => {
        const nnrData = datasets['Nnr010'] ?? [];
        const filialData = datasets['Filial'] ?? [];
        setFilialList(filialData);
        setListArmazem(nnrData);
    },[])

    

    const handleCentroCusto = async (value) => {
        setLoadingPicker(true);
        if(value.length > 2){
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                params: {
                    filter: value,  
                    table: 'Ctt010'
                }
            });
            setLoadingPicker(false);
            setCentroCustos(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
            setLoadingPicker(false);
        }
    }else{
        setLoadingPicker(false);
    }
    };


    const handleClasseValor = async (value) => {
        setLoadingPicker(true);
        if(value.length > 1){
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
            params: {
                filter: value,  
                table: 'Cth010'
            }
            });
            setLoadingPicker(false);
            setListClasse(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
            setLoadingPicker(false);
        }
        }else{
            setLoadingPicker(false);
        }
    };




    const handleFilterSolicitacoes = async () => {
        if(filterValue.data_inicio > filterValue.data_fim){
            enqueueSnackbar("Data inicial maior que data final", {
                variant: "default",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                autoHideDuration: 2000,
            });
            return;
        }
        if(filterValue.data_inicio === '' || filterValue.data_fim === ''){
            enqueueSnackbar("O campo de data e obrigatório", {
                variant: "default",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                autoHideDuration: 2000,
            });
            return;
        }
        setFilterDinamic(false);
        const filterString = Object.entries(filterValue)
            .filter(([_, value]) => value)
            .map(([key, value]) => `${key}:${value}`)
            .join(',');

        setLoading(true);
        handleOpenFilter();
        setIsFilter(true);
        if(filterValue.status != ""){
            if(filterValue.status === 'pendente_pre'){
                setCardSelected('pendentes');
            }else if(filterValue.status === 'sol_pendente'){
                setCardSelected('convertidos');
            }else if(filterValue.status === 'transporte'){
                setCardSelected('transporte');
            }else if(filterValue.status === 'pre_baixado'){
                setCardSelected('todas');
            }else if(filterValue.status === 'atendido'){
                setCardSelected('todas');
            }
        }

        try {
            setSolicitacoes([]);
            const response = await api.get('/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/sa_custom_filter',{
                params: {
                    filter: filterString,  
                }
            });
            setFilterValue({
                solicitante: '',
                data_inicio: '',
                produto: '',
                data_fim: '',
                centro_custo: '',
                status: '',
                armazem: '',
                classe: '',
                filial: ''
            })
            const gruposFormatados = agruparSolicitacoes(response.data.results);
            setSolicitacoes(gruposFormatados);
            setPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys:{
                    filter: filterString
                },
                path: '/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/sa_custom_filter',
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
            setLoading(false);
            setIsFilter(false);
        } catch (error) {
            console.log(error);
        }
    }



// TODO - ADICIONAR TAMBEM NO PEDIDO DE COMPRA
    const hadleActivePagePd = async (page) => {
        setLoading(true);
        

        try{
            const filteredParams = Object.fromEntries(
                Object.entries(pagination.querys).filter(([_, value]) => value !== null && value !== "")
            );
            const response = await api.get(`${pagination.path}`, {
                params: {
                    ...filteredParams,
                    page: page
                }
            });
            setLoading(false);
            const gruposFormatados = agruparSolicitacoes(response.data.results);
            setSolicitacoes(gruposFormatados);
            setPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys: {
                    ...pagination.querys,
                },
                path: pagination.path,
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        }catch(error){
            console.log(error)
        }
    }

     const handelUsuarios = async () => {
        try{
            const response = await api.get('/api/v2/membros/users/get_users/');
            const users = response.data.map(user => ({
                ...user,
                is_propagate: false,
                is_view: true,
            }));
            setListUser(users);
    
        }catch(error){
            console.log(error)
        }
      
    }
    
    
    useEffect(() => {
    handelUsuarios()
    }, []);


    const speaker = (
        <Popover className="custom-popover">
          <div className="container-filter">
            <div className="filter-header">
                <span style={{fontSize: 14}}>Filtro</span>
            </div>
            <div className="filter-body">
                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        value={filterValue.filial}
                        data={filialList.map((item) => 
                        ({
                            label: item.codigo + ' - ' + item.descricao,
                            value: item?.codigo
                        }))}
                        placeholder="Filial"
                        menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                        }}
                        onSelect={(value, item) => updateFilterValue('filial', item.value)}
                        onClean={() => updateFilterValue('filial', '')}
                        renderMenu={(menu) => {
                        return(
                            <div style={{fontSize: 10}}>
                                {menu}
                            </div>
                        )
                        }}
                    />    
                </div>
                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        data={listArmazem.map((item) => 
                            ({
                                label: item.nnr_codigo + ' - ' + item.nnr_descri,
                                value: item?.nnr_codigo
                            }))}
                        placeholder="Armazém"
                        onSelect={(value, item) => updateFilterValue('armazem', item.value.trim())}
                        // onSearch={(data) => handleArmazem(data.toUpperCase())}
                        menuMaxHeight={200}
                        renderMenuItem={(label, item) => (
                        <div 
                            style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '250px',
                            display: 'block',
                            fontSize: 10
                        }}
                        title={label?.props?.children}
                        >
                            {label}
                        </div>
                        )}
                        renderMenu={(menu) => {
                        if (loadingPicker) {
                            return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                                <Loader content="Carregando..." center />
                            </div>
                            );
                        }
                        return menu;
                        }}
                        />     
                </div>
                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        data={listClasse.map((item) => 
                            ({
                                label: item.cth_desc01,
                                value: item?.cth_clvl
                            }))}
                        placeholder="Classe de valor"
                        onSelect={(value, item) => updateFilterValue('classe', item.value.trim())}
                        onSearch={(data) => handleClasseValor(data.toUpperCase())}
                        menuMaxHeight={200}
                        renderMenuItem={(label, item) => (
                        <div 
                            style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '250px',
                            display: 'block',
                            fontSize: 10
                        }}
                        title={label?.props?.children}
                        >
                            {label}
                        </div>
                        )}
                        renderMenu={(menu) => {
                        if (loadingPicker) {
                            return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                                <Loader content="Carregando..." center />
                            </div>
                            );
                        }
                        return menu;
                        }}
                        />    
                </div>

                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        value={filterValue.status}
                        data={statusList}
                        placeholder="Status"
                        onSelect={(value, item) => updateFilterValue('status', item.value)}
                        onClean={() => updateFilterValue('status', '')}
                        renderMenu={(menu) => {
                        return(
                            <div style={{fontSize: 10}}>
                                {menu}
                            </div>
                        )
                        }}
                    />    
                </div>
                <div className="filter-item">
                    <Input placeholder="Solicitante" onChange={(value)=>updateFilterValue('solicitante', value.toUpperCase())}/>
                </div>
                <div className="filter-item" style={{zIndex: 9999}}>
                    <InputPicker
                        className="custom-picker"
                        style={{width: '100%', zIndex: 9999}}
                        data={centro_custos.map((item) => 
                            ({
                                label: item.ctt_custo + ' - ' + item.ctt_desc01,
                                value: item?.ctt_custo
                            }))}
                        placeholder="Centro de custo"
                        onSelect={(value, item) => updateFilterValue('centro_custo', item.value.trim())}
                        onSearch={(data) => handleCentroCusto(data.toUpperCase())}
                        menuMaxHeight={200}
                        renderMenuItem={(label, item) => (
                        <div 
                            style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '250px',
                            display: 'block',
                            fontSize: 10
                        }}
                        title={label?.props?.children}
                        >
                            {label}
                        </div>
                        )}
                        renderMenu={(menu) => {
                        if (loadingPicker) {
                            return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                                <Loader content="Carregando..." center />
                            </div>
                            );
                        }
                        return menu;
                        }}
                        />
                    
                </div>
                <div className="filter-body-more" style={{display: 'flex', alignItems: 'center', justifyContent: 'stretch'}}>
                    <div className="filter-item" style={{width: '100%'}} >
                        <Input
                            type="date" 
                            style={{width: '100%'}} 
                            className="rs-input"
                            onChange={(value)=>updateFilterValue('data_inicio', value)}
                        />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', padding: 10}}>
                        <span style={{marginBottom: 15}}>Até</span>
                    </div>
                    <div className="filter-item" style={{width: '100%'}} >
                        <Input
                            style={{width: '100%'}}
                            type="date"  
                            className="rs-input"
                            onChange={(value)=>updateFilterValue('data_fim', value)}
                        />
                    </div>
                </div>
                
            </div>
            <div className="filter-footer">
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: 'gray', marginRight: 10}} onClick={()=>handleOpenFilter()}>Sair</Button>
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: '#00664e'}} onClick={()=>handleFilterSolicitacoes()}>Pesquisar</Button>
            </div>
          </div>
        </Popover>
    );


    const handleOpenFilter = () => {
        if (isOpen) {
            closeFilter();
        } else {
            openFilter();
        }
        setIsOpen(!isOpen);
    };
    


    return (
        <div className="folder-content">
            <div className='container-puchare'>
                <div className="folder-row-2" style={{width: '100%'}}>
                    <div className="container-data-main top" >
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginRight: 5, alignItems: 'center'}}>
                            
                            <Whisper placement="bottomStart" speaker={speaker} ref={triggerRef} trigger="none">
                                <IconButton onClick={()=>handleOpenFilter()} style={{backgroundColor: '#f1f1f1', marginRight: 5}} icon={<FunnelIcon style={{height: '100%'}} title="Filtrar"/>}/>
                            </Whisper>
                            <InputGroup style={{height: 36}}>
                                    <InputGroup.Addon style={{padding: 0, margin: 0}}>
                                    <select className="input-select" defaultValue="1" onChange={(e)=>setFilterOption(e.target.value)}  style={{fontSize: 10, height: '100%',  width: 75, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                        <option value="1" title="NUMERO DA SOLICITAÇÃO ARMAZÉM">N°. (SA)</option>
                                        <option value="6" title="NUMERO DA SOLICITAÇÃO DE COMPRAS">N°. (SC)</option>
                                        <option value="2" title="NUMERO DA ORDEM DE SERVIÇO">N°. (OS)</option>
                                        <option value="7" title="NUMERO DA ORDEM DE PRODUÇÃO">N°. (OP)</option>
                                        <option value="5" title="NUMERO DO PEDIDO">N°. (PD)</option>
                                        <option value="3" title="SOLICITANTE">SOLICIT</option>
                                    </select>
                                    </InputGroup.Addon>
                                <Input placeholder="Pesquisar" value={inputValue} onChange={(value)=>handleCustomFilter(value)}/>
                            </InputGroup>
                        </div>
                        <div>
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc mobile" icon={<PlusIcon  style={{color: '#fff', height: '100%'}}/>}></IconButton>
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc" icon={<PlusIcon  style={{color: '#fff', backgroundColor: '#014838', height: '100%'}}/>}>Nova solicitação</IconButton>
                        </div>
                        
                    </div>
                    <div className="data-cards-content">
                        <DataCardsAmz/>
                    </div>
                                        
                    <div className='container-data-main'>
                        <ListSolicitacoesAmz solicitacoes={solicitacoes} setSolicitacoes={setSolicitacoes} loading={loading} setLoading={setLoading}/>
                        <Pagination 
                            total={pagination.total_items}
                            limit={pagination.page_size}
                            disabled={filterDinamic}
                            size="xs"
                            activePage={pagination.current}
                            maxButtons={7}
                            onSelect={hadleActivePagePd} 
                        />
                    </div>
                </div>    
            </div>
            <DataFormDrawerAmz open={open} setOpen={setOpen} listUser={listUser} solicitacoes={solicitacoes} setSolicitacoes={setSolicitacoes}/>
        </div>
    );
}
export default SolicitacaoAmz;

