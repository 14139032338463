import '../../../../../../tailwind.css';
import { useState, useEffect } from "react"
import { CiUser,  CiFileOn } from "react-icons/ci";
import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";


const handleStatus = (value) => {
  switch(value){
    case 'sol_pendente':
      return 'PENDENTE';
    case 'pendente':
      return 'PENDENTE';
    case 'aprovacao':
      return 'EM APROVAÇÃO';
    case 'aprovado':
      return 'APROVADO';
    case 'rejeitado':
      return 'REJEITADO';
    case 'pendente_aprovacao':
      return 'PENDENTE DE APROVAÇÃO';
    case 'atendido':
      return 'ATENDIDO';
    case 'transporte':
      return 'EM TRANSPORTE';
    default:
      return 'SEM STATUS';
        
  }
}


function OrderItem({ item }) {
  return (
    <div>
      <div style={{minHeight: 300}}>
        <table className="w-full border border-gray-300 text-sm shadow-md rounded-lg">
          <thead className='bg-gray-300 text-gray-700 uppercase text-[10px] sticky top-0 z-10'>
            <tr>
              <th className="border p-1">COD. DO PRODUTO</th>
              <th className="border p-1">DESCRIÇÃO DO PRODUTO</th>
              <th className="border p-1">NUMERO DA SA</th>
              <th className="border p-1">NUMERO DO PEDIDO</th>
              <th className="border p-1">NUMERO DA OS</th>
              <th className="border p-1">NUMERO DA OP</th>
              <th className="border p-1">CLASSE DE VALOR</th>
              <th className="border p-1">QTD.</th>
              <th className="border p-1">CENTRO DE CUSTO</th>
              <th className="border p-1">OBSERVAÇÃO</th>
              <th className="border p-1">STATUS</th>
              <th className="border p-1">EMISSÃO</th>
            </tr>
          </thead>
          <tbody>
            {item?.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100 even:bg-gray-50 text-[10px]">
                <td className="border p-1 text-center">{item?.c1_produto}</td>
                <td className="border p-1 text-center">{item?.c1_descri}</td>
                <td className="border p-1 text-center">{item?.sa?.value || "N/A"}</td>
                <td className="border p-1 text-center">{item?.pd?.value || "N/A"}</td>
                <td className="border p-1 text-center">{item?.c1_os?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{item?.c1_op?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{item?.c1_clvl?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{item?.c1_quant}</td>
                <td className="border p-1 text-center">{item?.c1_cc}</td>
                <td className="border p-1 text-center" title={item.c1_obs}>{item?.c1_obs?.trim() || "N/A"}</td>
                <td className="border p-1 text-center">{item?.status}</td>
                <td className="border p-1 text-center">{moment(item?.c1_emissao).format('DD/MM/YYYY')}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ScOrderView = ({data, print, isPrint}) => {
  const getCurrentItems = () => {
    return data.data;
  };



  
  const exportToPDF = async () => {
    const element = document.getElementById("purchase-order");

    if (!element) {
      console.error("Erro: Elemento #purchase-order não encontrado!");
      return;
    }

    // Ajusta o zoom para capturar melhor os estilos
    const scale = 2;
    const canvas = await html2canvas(element, {
      scale,
      useCORS: true, // Permite capturar imagens externas
      backgroundColor: "#ffffff", // Mantém o fundo branco
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 210; // Largura total do A4
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pageHeight = 297; // Altura de uma página A4

    let position = 0;

    while (position < imgHeight) {
      pdf.addImage(imgData, "PNG", 0, position * -1, imgWidth, imgHeight);
      position += pageHeight;

      if (position < imgHeight) {
        pdf.addPage();
        pdf.setFontSize(12);
        pdf.text("Pedido de Compra - Continuação", 10, 15);
      }
    }

    pdf.save(`${data.numero}.pdf`);
    isPrint(false);
  };

  useEffect(() => {
    if (print) {
      exportToPDF();
    }
  }, [print]);




  

  return (
    <div style={{ width: '100%', margin: 0, borderRadius: 5, height: 'auto', position: 'relative', overflow: 'hidden', padding: 5}} id="purchase-order">
      <div className="p-3 border-b border-gray-200" style={{border: '1px solid #e4e2e2', marginTop: 5, borderRadius: 5, height: '15%'}}>
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <h2 className="text-base font-bold">N°: {data.numero}</h2>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-y-2 text-[13px]">
          <div className="flex items-center gap-1">
            <CiFileOn style={{fontSize: 20, color: 'gray'}} />
            <span>FILIAL: <span className="font-semibold text-[12px]">{data.data[0]?.c1_filial}</span></span>
          </div>
          <div className="flex items-center gap-1">
            <CiUser style={{fontSize: 20, color: 'gray'}} />
            <span>SOLICITANTE: <span className="font-semibold text-[12px]">{data.data[0]?.c1_solicit}</span></span>
          </div>
        </div>
      </div>

      <div className="divide-y divide-gray-200">
        <div className="px-3 py-1 bg-gray-100 flex justify-between items-center">
          <h3 className="text-xs font-semibold">ITENS DA SC</h3>
          <h3 className="text-xs font-semibold">{data?.data?.length} ITEMS</h3>
        </div>

        <div style={{margin: 0, padding: 0}}>
          <OrderItem item={getCurrentItems()} />
        </div>
      </div>
    </div>
  )
}
export default ScOrderView
