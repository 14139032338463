import React, { createContext, useState, useEffect } from "react";

export const SaAmzContext = createContext();

export const SaProvider = ({ children }) => {
  const [cardSelected, setCardSelected] = useState("pendentes");

  return (
    <SaAmzContext.Provider
      value={{ cardSelected, setCardSelected }}
    >
      {children}
    </SaAmzContext.Provider>
  );
};
