import './pdlist.css';
import { useState, useContext, useEffect} from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { Loader, Popover, Whisper, Modal, Button, Pagination, Dropdown} from 'rsuite';
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import RemindIcon from '@rsuite/icons/legacy/Remind';
import { MdOutlineErrorOutline } from "react-icons/md";
import { ListScContext} from '../../utils/scListContext';
import ModalExort from '../modalExport';
import { CiImport } from "react-icons/ci";
import { useSelector, useDispatch } from 'react-redux';
import MoreIcon from '@rsuite/icons/legacy/More';
import { FaRegEye } from "react-icons/fa";
import PurchaseOrderView from '../pedidoView';
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import NfView from '../nfView';
import { atualizarGrupos, agruparSolicitacoes, atualizarStatusGrupo } from '../../utils/groupdata';
import { FaRegCircleCheck } from "react-icons/fa6";
import { RiLoader3Fill } from "react-icons/ri";
import {toggleIsUpdate} from '../../../../../../utils/store/channelMessage';



const PdList = ({handleOpenEdit}) => {
    const {auth, channelMessage} = useSelector(state => state);
    const dispatch = useDispatch();
    const api = useAxios();
    const [openModalView, setOpenModalView] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [dataError, setDataError] = useState({
        message: null,
        uuid: null
    });
    const [openNfView, setOpenNfView] = useState(false);
    const { pedidoList, loadingSc, setLoadingSc, Pdpagination, setPdPagination, setPedidoList } = useContext(ListScContext);
    const [expandId, setExpandId] = useState(null);
    const [open, setOpen] = useState(false);
    const [pedidoNum, setPedidoNum] = useState({
        numero: null,
        uuid: null
    });
    const [openDocument, setOpenDocument] = useState(false);
    const [document, setDocument] = useState(null);
    const [pedido, setPedido] = useState(null);
    const [openModalExport, setOpenModalExport] = useState(false);
    const [pedidoView, setPedidoView] = useState(null);
   


    const handleUpdateStatus = (data) =>{
        const {uuid, tipo, numero, error_message} = data;
        let status = tipo === 'SUCESSO' ? 'PENDENTE' : 'SY';
        let sync_success = tipo === 'SUCESSO' ? true : false;
        let IsError = tipo === 'ERRO' ? true : false;
        setPedidoList(prevGrupos => atualizarStatusGrupo(prevGrupos, uuid, status, numero, error_message ,IsError, sync_success));
    }


    useEffect(() => {
        if (channelMessage.messages.length > 0) {
            const mensagensNaoAtualizadas = channelMessage.messages.filter(msg => !msg.is_update && msg.path === 'pedido_compra');
            if (mensagensNaoAtualizadas.length > 0) {
                mensagensNaoAtualizadas.forEach((msg) => {
                    dispatch(toggleIsUpdate({ id: msg.id, path: "pedido_compra", is_update: true }));
                    handleUpdateStatus(msg);
                });
            }
        }
    }, [channelMessage.messages]);





    

    const hadleActivePagePd = async (page) => {
        setLoadingSc(true)
        try{
            const filteredParams = Object.fromEntries(
                Object.entries(Pdpagination.querys).filter(([_, value]) => value !== null && value !== "")
            );
            const response = await api.get(`${Pdpagination.path}`, {
                params: {
                    ...filteredParams,
                    page: page
                }
            });
            setLoadingSc(false)
            const gruposFormatados = agruparSolicitacoes(response.data.results);
            setPedidoList(gruposFormatados);
            setPdPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys: {
                    ...Pdpagination.querys,
                },
                path: Pdpagination.path,
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        }catch(error){
            console.log(error)
        }
    }


    
   


    






    const toggleExpand = (index) => { 
        setExpandId(prevId => (prevId === index ? null : index));
    };

   

  


    const handleStatus = (values) => {
        if(values === 'sol_pendente' || values === 'pendente' || values === "PD"){
            return 'PENDENTE';
        }else if(values === 'SY'){
            return 'NÃO ENVIADO';
        }else if(values === 'EA'){
            return 'APROVACAO';
        }
        else{
            return values.toUpperCase();
        }
    }



    const parseCurrency = (formattedValue) => {
        if (!formattedValue) return 0;

        if (typeof formattedValue === 'number') {
            return parseFloat(formattedValue.toFixed(2)); // Garante 2 casas decimais
        }

        // Remove espaços extras
        formattedValue = formattedValue.trim();

        // Substitui todas as vírgulas por pontos (caso seja separador decimal)
        formattedValue = formattedValue.replace(',', '.');

        // Captura apenas os números e o último separador decimal válido
        const validCurrency = formattedValue.match(/[\d]+(?:\.\d{0,2})?/g);

        if (!validCurrency) return 0;

        return parseFloat(validCurrency[0]);
    };



    

    const renderMenu = ({ onClose, left, top, className }, ref, Data) => {
        return (
            <Popover ref={ref} className={className} style={{ left, top, margin: 0, padding: 0 }}>
            <Dropdown.Menu style={{padding: 0, margin: 0}}>
                <Dropdown.Item eventKey={1} onClick={(e)=>{onClose();setOpenModalView(true); e.stopPropagation(); setPedidoView(Data)}} icon={<FaRegEye/>}>Visualizar</Dropdown.Item>
                <Dropdown.Item 
                    icon={<MdEdit className='rs-icon'/>}  
                    eventKey={2}
                     onClick={(e) => {
                        onClose();
                        e.stopPropagation();
                        if(Data.status === 'ATENDIDO' || parseInt(auth.userId) !== parseInt(Data.data[0].comprador_id)){
                            enqueueSnackbar("Vocé não tem permissão para essa ação", { variant: "default", autoHideDuration: 2000, preventDuplicate: true, anchorOrigin: { vertical: 'top', horizontal: 'center' } });
                            return;

                        }
                        handleOpenEdit(Data)
                    }}
                    >
                        Editar
                </Dropdown.Item>
                <Dropdown.Item 
                    icon={<CiImport className='rs-icon'/>} 
                    eventKey={3}
                    onClick={(e) => {
                    onClose();    
                    e.stopPropagation();
                    handleImport(Data)}}
                >
                Exportar
                </Dropdown.Item>
                <Dropdown.Separator style={{padding: 0, margin: 0}}/>
                <Dropdown.Item 
                    icon={<MdDeleteOutline 
                    className='rs-icon'/>} 
                    eventKey={4}
                    onClick={(e) => {
                        onClose();
                        e.stopPropagation();
                        if(Data.status === 'ATENDIDO' || parseInt(auth.userId) !== parseInt(Data.data[0].comprador_id)){
                            enqueueSnackbar("Vocé não tem permissão para essa ação", { variant: "default", autoHideDuration: 2000, preventDuplicate: true, anchorOrigin: { vertical: 'top', horizontal: 'center' } });
                            return;
                        }
                        setOpen(true); setPedidoNum({
                            numero: Data.numero,
                            uuid: Data.uuid
                        })
                        }
                    }
                    >
                        Excluir
                </Dropdown.Item>
            </Dropdown.Menu>
            </Popover>
        );
    };


    const handleDelete = async (numero, uuid) => { 
        const data = {
            numero: numero,
            uuid: uuid
        }
        try {
            const response = await api.patch('/api/v2/modules/gestao-compras/pedido-compra/delete_pedido/', { numero: numero, uuid: uuid });
            if(response.status === 200){
                setOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleResync = async (uuid) => {
        setLoadingSc(true);
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/reenviar_pedido/', { 
                params: {
                    uuid: uuid
                }
             });
            setPedidoList(prevData => atualizarGrupos(prevData, response.data));
            setLoadingSc(false);
            setOpenModalError(false);
        }
        catch(error){
            console.log(error);
            setLoadingSc(false);
        }
        
    }


    


    const handleImport = async (data) => {
        setOpenModalExport(true);
        setPedido(data);
    }
    const handleTotal = (data) => {
        let total = 0;
        data.map((item) => {
            total += parseCurrency(item.total);
        });
        return total.toFixed(2);
    }


    const handleTotalDesconto = (data) => { 
        let total = 0;
        data.map((item) => {
            total += parseCurrency(item.vlrDesconto);
        });
        return total.toFixed(2);

    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value);
    };

    


    return (
        <>
        <div className="purchase-list-container" style={{position: 'relative'}}>
            <SnackbarProvider maxSnack={3}/>
            <div className="purchase-list-titles-pd">
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>FILIAL</div>
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>NUMERO</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left', marginLeft: '15%'}}>COMPRADOR</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left', marginLeft: '-2%'}}>FORNECEDOR</div>
                <div className="purchase-list-title-item item-center">DATA DE EMISSÃO</div>
                <div className="purchase-list-title-item item-center">QTD. ITENS</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'center', marginRight: '18%'}}>STATUS</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'center', marginRight: '10%'}}>DESCONTO TOTAL</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'center', marginRight: '18%'}}>TOTAL</div>
                <div className="purchase-list-title-item item-center">...</div>
            </div>
            <>
            {loadingSc ? (
                <div style={{marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader center content="Carregando..." style={{position: 'relative', marginTop: '5%'}}/>
                </div>
            ):(
                <>
                {pedidoList.length === 0 ? (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, width: '100%'}}>
                        <span style={{fontSize: 16, color: 'gray'}}>Nenhum pedido encontrado</span>
                    </div>
                ): (
                    <>
                    {pedidoList?.map((processos, index) => {
                        return (
                            <ul key={index}  className='purchase-list-items'>
                                <li>
                                    <div className='pushase-item-container'>
                                        <div className="purchase-list-item-content-pd" onClick={(e) => {e.stopPropagation();toggleExpand(index)}}>
                                            <div className="purchase-list-item" style={{textAlign: 'center', marginRight: 10}}>
                                                <span className="item-title-sm">
                                                    FILIAL
                                                </span>
                                                <span className="item-manager">
                                                    {processos?.filial}
                                                </span>
                                            </div>
                                            
                                            <div className="purchase-list-item" style={{textAlign: 'center'}}>
                                                <span className="item-title-sm">
                                                    NUMERO
                                                </span>
                                                <span className="item-manager">
                                                    {`${processos?.numero?.toUpperCase()}`}
                                                </span>
                                            </div>
                                                
                                            <div className="purchase-list-item" style={{display: 'flex', alignItems: 'flex-start', paddingLeft: '20px'}}>
                                                <span className="item-manager">
                                                    {!processos?.comprador ? "INDEFINIDO" : processos?.comprador}
                                                </span>
                                            </div>
                                            
                                            <div className="purchase-list-item custom">
                                                <span className="item-title-sm">
                                                    FORNECEDOR
                                                </span>

                                                <span className="item-manager">
                                                    {processos?.fornecedor}
                                                </span>
                                            </div>

                                            

                                            <div className="purchase-list-item">
                                                <span className="item-manager">
                                                    {moment(processos?.data_criacao).format('DD/MM/YYYY')}
                                                </span>
                                            </div>


                                            <div className="purchase-list-item">
                                                <span className="item-title-sm">
                                                    QTD. ITENS
                                                </span>
                                                <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {processos?.data?.length}
                                                </span>
                                            </div>

                                            <div className="purchase-list-item">
                                                <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {processos?.status === 'SY' ? (
                                                    <>
                                                        {processos?.is_error_sync ? (
                                                            <>
                                                            <MdOutlineErrorOutline style={{fontSize: 30, cursor: 'pointer'}} className="text-4xl text-red-500" onClick={(e)=>{e.stopPropagation();setDataError({message: processos.error_message, uuid: processos.uuid}); setOpenModalError(true)}} title='ERRO NO ENVIO'/>
                                                            </>
                                                        ):(
                                                            <RiLoader3Fill style={{fontSize: 30, cursor: 'pointer'}} title='ENVIANDO..' className="animate-spin text-4xl text-blue-600"/>
                                                        )}
                                                    </>
                                                    ):processos.sync_success ? (
                                                        <>
                                                        <FaRegCircleCheck style={{fontSize: 25, cursor: 'pointer'}} className="text-4xl text-green-700" title='ENVIADO COM SUCESSO'/>
                                                        </>
                                                    ): (
                                                        <>
                                                        {processos?.status}
                                                        </>
                                                    )}
                                                    
                                                </span>
                                            </div>


                                            <div className="purchase-list-item">
                                                <span className="item-title-sm">
                                                    DESCONTO TOTAL
                                                </span>
                                                <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    {formatCurrency(handleTotalDesconto(processos?.data))}
                                                </span>
                                            </div>

                                            <div className="purchase-list-item">
                                                <span className="item-title-sm">
                                                    VALOR TOTAL
                                                </span>
                                                <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    {formatCurrency(handleTotal(processos?.data))}
                                                </span>
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    ...
                                                </span>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    
                                                    <Whisper placement="auto" trigger="click" speaker={(props, ref) => renderMenu(props, ref, processos)}>
                                                        <MoreIcon className="list-documents-icon" style={{ cursor: 'pointer', fontSize: 30, marginLeft: 5, backgroundColor: 'gray' }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        />
                                                    </Whisper>
                                                
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {expandId === index && (
                                            <>
                                            {processos?.data?.map((item, index) => (
                                                <div onClick={(e)=>e.stopPropagation()} className="pd-sub-purchase-list-item-content-child" style={{cursor: 'pointer'}} key={index}>
                                                    <div className="sc-sub-purchase-list-item item-left">
                                                        <div className='item-expand'>
                                                            <span className="item-title-expadend">
                                                                PRODUTO
                                                            </span>
                                                            <span className="item-data-expand" title={item.descri} style={{width: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                                {item?.descri?.trim()}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand' title='ARMAZÉM' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                            <span className="item-title-expadend" style={{marginTop: 4}}>
                                                                AMZ
                                                            </span>
                                                            <span className="item-data-expand">
                                                                {item?.armazem || 'N/A'}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand' title='NUMERO DA SOLICITAÇÃO AO ARMAZÉM' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                            <span className="item-title-expadend">
                                                                SOLICITANTE
                                                            </span>
                                                            <span className="item-data-expand">
                                                                {item?.solicitante || 'N/A'}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand' title='NUMERO DA SOLICITAÇÃO AO ARMAZÉM' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                            <span className="item-title-expadend">
                                                                NÚMERO DA SA
                                                            </span>
                                                            <span className="item-data-expand">
                                                                {item?.sa?.value || 'N/A'}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand' title='NUMERO DA SOLICITAÇÃO DE COMPRAS' style={{display: 'flex', alignItems: 'center', marginRight: '35%'}}>
                                                            <span className="item-title-expadend">
                                                                NÚMERO DA SC
                                                            </span>
                                                            <span className="item-data-expand">
                                                                {item?.sc?.value || 'SEM SC'}
                                                            </span>
                                                        </div>
                                                    </div>


                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand' title='NUMERO DA NOTA FISCAL' onClick={()=>setOpenNfView(true)} style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                            <span className="item-title-expadend">
                                                                N° NF
                                                            </span>
                                                            <span className="item-data-expand">
                                                                {item?.relacoes_pd?.pd?.nf_data || 'SEM NF'}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand qtd' title='UNIDADE DE MEDIDA'>
                                                            <span className="item-title-expadend">
                                                                UN.
                                                            </span>
                                                            <span className="item-data-expand">
                                                                {item.um}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand qtd'>
                                                            <span className="item-title-expadend">
                                                                QTD.
                                                            </span>
                                                            <span className="item-data-expand">
                                                                {parseFloat(item?.quantidade).toFixed(2)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="sc-sub-purchase-list-item">
                                                        <div className='item-expand' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                            <span className="item-title-expadend">
                                                                STATUS
                                                            </span>
                                                            <span className="item-data-expand" style={{ whiteSpace: 'nowrap' }}>
                                                                {item.status}
                                                            </span>
                                                        </div>
                                                    </div>                                                
                                                </div>
                                                
                                            ))}
                                            </>
                                            )}
                                    </div>
                                </li>
                                
                            </ul>
                        );
                    })}
                    </>
                )}
                
                
                </>
                
            )}
            </>
            <Modal overflow={false} open={openModalError} onClose={()=>setOpenModalError(false)} size="sm">
                <Modal.Header>
                    <Modal.Title style={{fontSize: 12}}>LOG DE ERRO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{minHeight: 300, backgroundColor: '#c7c6c698', padding: 10}}>
                        <code style={{fontSize: 12, fontWeight: 'bold'}}>
                            {
                                dataError.message || 'ERRO DESCONHECIDO'
                            }
                        </code>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button appearance="primary" onClick={()=>{setOpenModalError(false);handleResync(dataError.uuid)}} style={{backgroundColor: 'gray'}}>
                    Reenviar
                </Button>
                <Button onClick={()=>setOpenModalError(false)} appearance="subtle">
                    Sair
                </Button>
                </Modal.Footer>
            </Modal>


            <Modal overflow={false} open={openModalView} style={{paddingLeft: 0, paddingRight: 0}} onClose={()=>setOpenModalView(false)} size="full">
                <Modal.Header>
                    <div style={{backgroundColor: '#00664e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                            <Modal.Title style={{fontSize: 15, color: '#fff'}}>PEDIDO DE COMPRA</Modal.Title>
                            <Button onClick={()=>setOpenModalView(false)} appearance="primary" style={{backgroundColor: '#fff', color: 'black', fontSize: 12, borderRadius: 3}}>
                                FECHAR
                            </Button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body style={{overflow: 'auto'}}>       
                    <PurchaseOrderView data={pedidoView}/>
                </Modal.Body>
            </Modal>

            <Modal open={openNfView} onClose={()=>setOpenNfView(false)} style={{overflow: 'hidden', marginTop: 5}}>
                <Modal.Header>
                    <div style={{backgroundColor: '#00664e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                            <Modal.Title style={{fontSize: 15, color: '#fff'}}>INFORMAÇÕES FISCAIS</Modal.Title>
                            <Button onClick={()=>setOpenNfView(false)} appearance="primary" style={{backgroundColor: '#fff', color: 'black', fontSize: 12, borderRadius: 3}}>
                                FECHAR
                            </Button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body style={{maxHeight: "100vh"}}>
                    <div style={{height: '100%', width: '100%'}}>
                        <NfView/>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal backdrop="static" role="alertdialog" open={open} onClose={()=>setOpen(false)} size="xs">
            <Modal.Body>
                <RemindIcon style={{ color: '#ffb300', fontSize: 24 }} />
                    Deseja proceder com a remoção do pedido de compra?
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={()=>handleDelete(pedidoNum.numero, pedidoNum.uuid)} appearance="primary">
                    Confirmar
                </Button>
                <Button onClick={()=>setOpen(false)} appearance="subtle">
                    Cancelar
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
        <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
            <Pagination 
                total={Pdpagination.total_items}
                limit={Pdpagination.page_size}
                size="xs"
                activePage={Pdpagination.current}
                maxButtons={7}
                onSelect={hadleActivePagePd} 
            />
        </div>
        <ModalExort open={openModalExport} setOpen={setOpenModalExport} data={pedido} setDocument={setDocument} setOpenDocument={setOpenDocument}/>
        </>
    );
}
export default PdList;