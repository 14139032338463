import './data-card.css';
import { useAxios } from '../../../../../../utils/api/api';
import { useEffect, useState, useContext } from 'react';
import { ScContext } from '../../utils/solicitacaoContext';


const DataCards = () => {
    const api = useAxios();
    const { cardSelected, setCardSelected } = useContext(ScContext);
    const [data, setData] = useState({
        aberto: 0,
        aprovacao: 0,
        transporte: 0,
        total: 0,
        sync: 0
    });

    const handleData = async () => {
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/list_data');
            const {aberto, aprovacao, total, transporte, sync} = response.data;
            
        
            const newData = {
                total,
                aprovacao,
                aberto,
                transporte,
                aprovacao,
                sync
            };

            setData(newData);

        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        
        handleData();
        
        const interval = setInterval(() => {
            handleData();
        }, 60000);

        return () => clearInterval(interval);
        }, []);





    return(
        <div className='container-data-cards'>
            <div className='data-card-item' onClick={()=>setCardSelected('pendentes')}>
                <span className='data-card-title'>PENDENTES</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.aberto}</span>
                </div>
                {cardSelected === 'pendentes' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.5%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' onClick={()=>setCardSelected('aprovacao')}>
                <span className='data-card-title'>AGUARDANDO APROVAÇÃO</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.aprovacao}</span>
                </div>
                {cardSelected === 'aprovacao' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.5%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' onClick={()=>setCardSelected('transporte')}>
                <span className='data-card-title'>EM TRANSPORTE</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.transporte}</span>
                </div>
                {cardSelected === 'transporte' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.5%', borderRadius: 0}}/>
                )}
            </div>
            
            
            <div className='data-card-item' onClick={()=>setCardSelected('todas')}>
                <span className='data-card-title'>CONCLUIDAS</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.total}</span>
                </div>
                {cardSelected === 'todas' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.5%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' onClick={()=>setCardSelected('sync')}>
                <span className='data-card-title'>SYNCRONIZAÇÃO PENDENTE</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.sync}</span>
                </div>
                {cardSelected === 'sync' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.5%', borderRadius: 0}}/>
                )}
            </div>
        </div>
    )
}

export default DataCards;