import './dashboard.css';
import ReviewPassIcon from '@rsuite/icons/ReviewPass';
import ReviewRefuseIcon from '@rsuite/icons/ReviewRefuse';
import WaitIcon from '@rsuite/icons/Wait';
import { useState, useEffect } from 'react'
import BarChart from '../../components/barChart';
import RadialChart from '../../components/radialChart';
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import LeaderBoard from '../../components/leaderBoard';
import { Progress, Divider, Loader } from 'rsuite';
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal';
import { useAxios } from '../../utils/api/api';

const DaShboardScreen = () => {
    const api = useAxios();
    const[loading, setLoading] = useState(false);
    const [documentCount, setDocumentCount] = useState({
        document_approved: 0,
        document_pending: 0,
        document_rejected: 0,
        document_total: 0,
        growth_rate: 0,
        documents_acess: 0,
        access_rate: 0,
    });
    const[processos,setProcessos] = useState({
        total_processos: 0,
        processos_concluidos: 0,
        total_subprocessos: 0,
        subprocessos_concluidos: 0,
        media_mes: 0,

    })


    useEffect(() => {
        handleGetData();
        handleGetProcessos();
    }, []);

    const handleGetData = async () => {
        try {
            const response = await api.get('/api/v1/relatorios/documents_summary/');
            setDocumentCount({
                document_approved: response.data?.document_approved,
                document_pending: response.data?.document_pending,
                document_rejected: response.data?.document_rejected,
                document_total: response.data?.document_total,
                growth_rate: response.data?.growth_rate,
                documents_acess: response.data?.documents_acess?.taxa_acessos,
                access_rate: response.data?.documents_acess?.restante_taxa ,
            });
            
        }catch (error) {
            console.log(error);
        }
        
        
    };

    const handleGetProcessos = async () => {
        try {
            const response = await api.get('/api/v1/relatorios/processo_relatorios/');
            setProcessos({
                total_processos: response.data?.total_processos,
                processos_concluidos: response.data?.processos_concluidos,
                total_subprocessos: response.data?.total_subprocessos,
                subprocessos_concluidos: response.data?.subprocessos_concluidos,
                media_mes: response.data?.media_mes,
            })
        }catch (error) {
            console.log(error);
        }
        
        
    };


    return (
        <div className="folder-content">
            <div className="folder-row-2">
                <div className="input-table-folder-01 dashboard">
                    <div style={{width: '100%', height: 40, border: '1px solid #f1f0f0', borderRadius: 7, position: 'sticky', top: 0,backgroundColor: '#fff', zIndex: 100, marginBottom: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <SettingHorizontalIcon style={{color: '#abaaaa', fontSize: '2.2em', marginLeft: 5}}/>
                    </div>
                    <div className="container-cards-dashboard">
                        <div className="cards-dashboard first">
                            <div className='cards-dashboard-02'>
                                <strong>TODOS OS DOCUMENTOS</strong>
                                <hr/>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <h2 style={{fontSize: '4.5em', color: '#000', marginTop: 5}}>{documentCount?.document_total || 0}</h2>
                                    <div style={{display: 'flex', flexDirection: 'column', height: '100%', marginLeft: 5, justifyContent: 'flex-start'}}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5}}>
                                            <sub title='Comparação ao més passado' style={{fontSize: 12, color: '#000', marginLeft: 5}}>{Math.round(documentCount?.growth_rate * 10) / 10}%</sub>
                                            {documentCount?.growth_rate > 0 ? <IoMdArrowDropup style={{color: 'green', fontSize: 20, marginTop: 5}}/> : <IoMdArrowDropdown style={{color: 'red', fontSize: 20, marginTop: 5}}/>}
                                        </div>
                                        <sub style={{ color: '#000', fontSize: 9, marginLeft: 5}}>Taxa de comparação ao més passado</sub>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="cards-dashboard">
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', marginRight: 10}}>
                                <ReviewPassIcon className='icon-dash'/>
                            </div>
                            <div className='cards-dashboard-02'>
                                <p>DOCUMENTOS APROVADOS</p>
                                <hr/>
                                <div>
                                    <h2>{documentCount?.document_approved || 0}</h2>
                                </div>
                                
                            </div>
                        </div>
                        <div className="cards-dashboard">
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', marginRight: 10}}>
                                <WaitIcon className='icon-dash pending'/>
                            </div>
                            <div className='cards-dashboard-02'>
                                <p>DOCUMENTOS EM APROVAÇÃO</p>
                                <hr/>
                                <div>
                                    <h2>{documentCount?.document_pending || 0}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="cards-dashboard">
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', marginRight: 10}}>
                                <ReviewRefuseIcon className='icon-dash refuse'/>
                            </div>
                            <div className='cards-dashboard-02'>
                                <p>DOCUMENTOS REPROVADOS</p>
                                <hr/>
                                <div>
                                    <h2>{documentCount?.document_rejected || 0}</h2>
                                </div>
                                
                            </div>
                        </div>
                    </div>              
                </div>
                <div className='dashboard-data'>
                    <div className='dashboard-content'>
                        <div className='data-content'>
                            <div className='data-options'>
                                <span>STATUS DOS DOCUMENTOS POR ÁREA</span>
                            </div>
                            <BarChart/>
                        </div>

                        <div className='data-content'>
                            <div className='data-options'>
                                <span>PROCESSOS</span>
                            </div>
                            <div style={{height: '95%', marginTop: '5%', padding: 10}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <h1>{processos?.total_processos}</h1>
                                    <div style={{display: 'flex', flexDirection: 'column', height: 40, justifyContent: 'flex-end', marginLeft: 10}}>
                                        <sub style={{fontSize: 13, color: 'green', marginBottom: 10}}> +{processos?.media_mes}</sub>
                                        <span style={{fontSize: 10}}>Novos Processos</span>
                                    </div>
                                </div>
                                <Divider style={{marginTop: 50}}/>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: '5s%'}}>
                                    <div style={{margin: 10}}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <span style={{fontSize: 12}}>SUBPROCESSOS</span>
                                        <span>{processos?.total_subprocessos}/{processos?.total_processos}</span>
                                        </div>
                                        <Progress.Line showInfo={false} active title='progresso' percent={processos?.total_subprocessos} style={{ fontSize: 12}} strokeColor='#01795da4' />
                                    </div>
                                    <div style={{margin: 10}}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <span style={{fontSize: 12}}>PROCESSOS CONCLUÍDOS</span>
                                        <span>{processos?.processos_concluidos}/{processos?.total_processos}</span>
                                        </div>
                                        <Progress.Line showInfo={false} active title='progresso' percent={0} style={{ fontSize: 12}} strokeColor='#01795da4' />
                                    </div>
                                    <div style={{margin: 10}}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <span style={{fontSize: 12}}>SUBPROCESSOS CONCLUÍDOS</span>
                                        <span>{processos?.subprocessos_concluidos}/{processos?.total_subprocessos}</span>
                                        </div>
                                        <Progress.Line showInfo={false} active title='progresso' percent={0} style={{ fontSize: 12}} strokeColor='#01795da4' />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='content-dash'>
                        <div className='content-dash-01' style={{width: '100%'}}>
                            <div className='data-options'>
                                <span>AVANÇO DA ÁREA</span>
                                {loading && <Loader backdrop content="Carregando..."/>}
                            </div>
                            <LeaderBoard loading={loading} setLoading={setLoading}/>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default DaShboardScreen;
