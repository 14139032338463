import { Input, InputGroup, IconButton, Loader, Popover, Button, Whisper, SelectPicker, CheckPicker} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { useState, useEffect, useCallback, useRef } from "react";
import moment from 'moment';
import { IoEye } from "react-icons/io5";
import { Pagination } from 'rsuite';
import './listaMestra.css';
import { LuPrinter } from "react-icons/lu";
import {useAxios} from '../../../../../../utils/api/api';
import { debounce } from 'lodash';
import DocumentView from '../../../../../../components/documentView';
import FunnelIcon from '@rsuite/icons/Funnel';
import CustomComboBox from './customComboBox';
import useFilterValues from './useFilterValues';


const BtnFilter = () =>{
    return(
        <span style={{margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 11, width: 11, cursor: 'pointer'}}>
            <svg 
                style={{width: '100%', height: '100%', color: 'blue'}}
                aria-hidden="true" 
                xmlns="http://www.w3.org/2000/svg" 
                fill="currentColor" 
                viewBox="0 0 24 24"
            >
            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
            </svg>
        </span>
    )
}


const BtnFilterAsc = () =>{
    return(
        <span style={{margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 11, width: 11, cursor: 'pointer'}}>
            <svg
            style={{width: '100%', height: '100%', color: 'blue'}}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            >
            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Z" />
            </svg>
        </span>
    )
}



const BtnFilterDesc = () =>{
    return(
        <span style={{margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 11, width: 11, cursor: 'pointer'}}>
            <svg
            style={{width: '100%', height: '100%', color: 'blue'}}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            >
            <path d="M15.426 12.976H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
            </svg>
        </span>
    )
}

const ListTable = ({ item, loading, orderin, handleOrder, listUser, listArea, handleCustomFilter, FilteredDocuments, PrintDocuments}) => {
    const [openDocument, setOpenDocument] = useState(false);
    const triggerRef = useRef();
    const [isOpen, setIsOpen] = useState(false) 
    const [inputValue, setInputValue] = useState('');
    const openFilter = () => triggerRef.current.open();
    const closeFilter = () => triggerRef.current.close();
    const versionList = [
        {label: 'Ativa', value: 'ativa'},
        {label: 'Todas', value: 'todas'},
    ]; 
    const statusList = [
        {label: 'Pendente', value: '0'},
        {label: 'Aprovado', value: '1'},
        {label: 'Reprovado', value: '2'},

    ];
    const { filterValue, setFilterValue, mergeFilters, resetFilters, dataValues, setAreas, setAutores, updateFilter } = useFilterValues();
    const[selectedDocument, setSelectedDocument] = useState({
        id: null,
        title: null,
        name: null
    });
    const handelOpenDocument = (data) =>{
      if(data){
        setSelectedDocument({
            id: data.documents,
            title: data.titulo,
            name: data.codigo
        })
        setOpenDocument(true);
      }
    }

    const SortButton = ({ column, orderin }) => {
        if (orderin.orderBy === column) {
            return orderin.order === 'asc' ? <BtnFilterAsc /> : <BtnFilterDesc />;
        }

        return <BtnFilter/>;
    };


    const handleOpenFilter = () => {
        if (isOpen) {
            closeFilter();
        } else {
            openFilter();
        }
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        const data = listUser?.map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id
            }
        });
        setAutores(data);
    }, [listUser]);


    useEffect(() => {
        const data = listArea?.map((item) => {
            return {
                label: item.nome,
                value: item.id
            }
        });
        setAreas(data);
    }, [listArea]);



    const handleFilterSolicitacoes =  () => {
        closeFilter();
        FilteredDocuments(filterValue);
        
    }



    


    const speaker = (
        <Popover className="custom-popover">
          <div className="container-filter">
            <div className="filter-header">
                <span style={{fontSize: 14}}>Filtros</span>
            </div>
            <div className="filter-body" style={{paddingTop: 5, paddingLeft: 15, paddingRight: 15}}>
                <div className="filter-item" style={{marginBottom: 10}}>
                    <label style={{marginLeft: 5, fontSize: 9}}>Status</label>
                    <SelectPicker 
                    data={statusList} 
                    value={filterValue.status}
                    onSelect={(value, item)=> updateFilter('status', item.value)}
                    block
                    placeholder="Selecione"
                    searchable={false} 
                    />
                </div>
                <div className="filter-item" style={{marginBottom: 10}}>
                    <label style={{marginLeft: 5, fontSize: 9}}>Autor</label>
                    <CheckPicker 
                        menuClassName="custom-menu"
                        data={dataValues.autores} 
                        onSelect={(value, item)=> {
                            const exists = filterValue.autor.some((a) => a.value === item.value);
                            if (exists) {
                                updateFilter(
                                "autor",
                                filterValue.autor.filter((a) => a.value !== item.value)
                                );
                            } else {
                                updateFilter("autor", [...filterValue.autor, item]);
                            }
                        }}
                        onClean={(value)=> 
                            resetFilters('autor')
                        }
                        appearance="default" 
                        block
                        countable={false}
                        menuMaxHeight={200}
                        placeholder="Selecione" 
                        renderMenu={(menu) => {
                            return (
                                <div style={{fontSize: 10}}>
                                    {menu}
                                </div>
                            );
                        }}
                        />
                </div>
                <div className="filter-item" style={{marginBottom: 10}}>
                    <label style={{marginLeft: 5, fontSize: 9}}>Area</label>
                    <CheckPicker 
                        menuClassName="custom-menu"
                        menuStyle={{maxWidth: '10%'}}
                        data={dataValues.areas} 
                        onSelect={(value, item)=> {
                            const exists = filterValue.area.some((a) => a.value === item.value);
                            if (exists) {
                                updateFilter(
                                "area",
                                filterValue.area.filter((a) => a.value !== item.value)
                                );
                            } else {
                                updateFilter("area", [...filterValue.area, item]);
                            }
                        }}
                        onClean={(value)=> 
                            resetFilters('area')
                        }
                        appearance="default" 
                        block
                        countable={false}
                        menuMaxHeight={200}
                        placeholder="Selecione" 
                        renderMenu={(menu) => {
                            return (
                                <div style={{fontSize: 10}}>
                                    {menu}
                                </div>
                            );
                        }}
                        />
                </div>

                              
            </div>
            <div className="filter-footer">
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: 'gray', marginRight: 10}} onClick={()=>handleOpenFilter()}>Sair</Button>
                <Button appearance="primary" size="sm" style={{fontSize: 12, backgroundColor: '#00664e'}} onClick={()=>handleFilterSolicitacoes()}>Pesquisar</Button>
            </div>
          </div>
        </Popover>
    );




  return (
    <div style={{minHeight: '100vh'}}>
        <div style={{border: 'none', paddingLeft: 0, paddingRight: 0, width: '100%', marginLeft: 0, display: 'flex', marginBottom: '20px', position: 'sticky', top: 0, zIndex: 200, backgroundColor: '#fff'}}>
            <div className='folder-row-3' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'stretch', width: '100%', position: 'relative'}}> 
                <Whisper placement="bottomStart" speaker={speaker} ref={triggerRef} trigger="none">
                    <IconButton onClick={()=>handleOpenFilter()} style={{backgroundColor: '#f1f1f1'}} icon={<FunnelIcon style={{height: 14}} title="Filtrar"/>}/>
                </Whisper>
                
                <InputGroup className="custom-input-sc">
                        <InputGroup.Addon>
                        <SearchIcon style={{fontSize: 14}} />
                        </InputGroup.Addon>
                    <Input autoComplete="off" value={inputValue} placeholder="Pesquisar" onChange={(value)=>{handleCustomFilter(value); setInputValue(value?.toUpperCase())}}/>
                </InputGroup>
                <IconButton onClick={()=>PrintDocuments()} style={{backgroundColor: '#00664e', color: '#fff', height: 33}} icon={<LuPrinter className='rs-icon' style={{backgroundColor: '#014d3b', color: '#fff'}}/>}><span style={{fontSize: 11}}>IMPRIMIR</span></IconButton>  
        </div>   
    </div>
    <div className="relative sm:rounded-[5px]" style={{border: '1px solid #ecebeb'}}>
        <table className="w-full table-fixed text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-[10px] text-gray-600 uppercase bg-[#f7f7f7]" style={{position: 'sticky', top:  35}}>
                <tr>
                    <th scope="col" className="w-[130px]" onClick={() => handleOrder('versao')}>
                        <div className="flex items-center justify-center">
                            <span>VERSAO</span>
                            {/* <SortButton column="versao" orderin={orderin}/> */}
                        </div>
                    </th>   

                    <th scope="col"  className="px-3 py-3" onClick={() => handleOrder('codigo')}>
                        <div className="flex items-center justify-start">
                            CODIGO
                            {/* <SortButton column="codigo" orderin={orderin} /> */}
                        </div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <div className="flex items-center">
                            TITULO
                        </div>
                    </th>
                    <th scope="col" className="flex justify-start items-center px-6 py-3" onClick={() => handleOrder('autor')}>
                        <div className="flex items-center">
                        AUTOR
                            {/* <SortButton column="autor" orderin={orderin} /> */}
                        </div>
                    </th>
                    <th scope="col" className="px-6 py-3" onClick={() => handleOrder('processo')}>
                        <div className="flex items-center">
                        PROCESSO
                            {/* <SortButton column="processo" orderin={orderin} /> */}
                        </div>
                    </th>
                    <th scope="col" className="px-6 py-3" onClick={() => handleOrder('area')}>
                        <div className="flex justify-start items-center">
                        AREA
                            {/* <SortButton column="area" orderin={orderin} /> */}
                        </div>
                    </th>
                    
                    <th scope="col" className="w-[120px]" onClick={() => handleOrder('data_criacao')}>
                        <div className="flex items-center justify-center whitespace-nowrap">
                            DATA DA RESERVA
                            {/* <SortButton column="data_criacao" orderin={orderin} /> */}
                        </div>
                    </th>

                    <th scope="col" className="w-[120px]" onClick={() => handleOrder('data_criacao')}>
                        <div className="flex items-center justify-center whitespace-nowrap">
                            DATA DE CRIAÇÃO
                            {/* <SortButton column="data_criacao" orderin={orderin} /> */}
                        </div>
                    </th>
                    <th scope="col" className="w-[120px]" onClick={() => handleOrder('data_vencimento')}>
                        <div className="flex items-center justify-center whitespace-nowrap">
                        DATA DE VENCIMENTO
                            {/* <SortButton column="data_vencimento" orderin={orderin} /> */}
                        </div>
                    </th>
                    <th scope="col" className="w-[100px]" onClick={() => handleOrder('status')}>
                        <div className="flex items-center justify-center">
                        STATUS
                            {/* <SortButton column="status" orderin={orderin} /> */}
                        </div>
                    </th>

                    <th scope="col" className="px-6 py-3" style={{width: '90px'}}>
                        <div className="flex justify-center">
                            AÇÕES	
                        </div>
                    </th>
                </tr>
            </thead>
            {item && item.length > 0 && loading ? (
                <div style={{height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0}}>
                        <Loader center content="loading" />
                    </div>
                </div>
            ): (
                <tbody>
                    {item?.map((item, index) => (
                        <tr key={index} className={`${
                                index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            } hover:bg-gray-100 border-b dark:bg-gray-800 text-[10px] uppercase dark:border-gray-700 border-gray-200`}
                            >
                            <td className="w-[100px] px-5 py-3">
                                <div className="flex items-center justify-center">
                                    {item?.version}
                                </div>
                                
                            </td>
                            
                            <td>
                                <div className="flex items-center justify-center">
                                    {item?.codigo}
                                </div>
                            </td>
                            <td className="px-6 py-3">
                                {item?.titulo}
                            </td>
                            <td className="px-6 py-3">
                                {`${item?.autor?.first_name} ${item?.autor?.last_name}`}
                            </td>
                            <td className="px-6 py-3">
                                {item.document_processo || 'SEM PROCESSO'}
                            </td>
                            <td className="px-6 py-3">
                                {item?.area?.nome}
                            </td>
                            <td className="px-6 py-3 text-center">
                               {moment(item?.data_reserva).format('DD/MM/YYYY')}
                            </td>
                            <td className="px-6 py-3 text-center">
                               {item.data_upload ? moment(item?.data_upload).format('DD/MM/YYYY') : 'N/A'}
                            </td>
                            <td className="px-6 py-3 text-center">
                                {item.data_vencimento ? moment(item?.data_vencimento).format('DD/MM/YYYY') : 'N/A'}
                            </td>
                            <td className="px-6 py-3 text-center"
                            style={{color: item.status === "Pendente" ? '#eec230' : item.status === 'Aprovado' ? 'green' : item.status === 'Reprovado' ? 'red' :'#8cb7fd'}}
                            >
                                {item?.status}
                            </td>
                            <td className="px-6 py-3 text-center">
                                <span className="font-medium text-blue-600 dark:text-blue-500 hover:underline" style={{cursor: 'pointer'}} onClick={()=>handelOpenDocument(item)}>Ver</span>
                            </td>
                        </tr>
                    ))}
                    
                    
                </tbody>
            )}
            
        </table>
    </div>
      <DocumentView  open={openDocument} setOpen={setOpenDocument} id={selectedDocument.id} documentTitle={selectedDocument.title} documentName={selectedDocument.name} />
    </div>
  )
}



const ListaMestraComponent = () =>{
    const api = useAxios();
    const { resetFilters} = useFilterValues();
    const [documents, setDocuments] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [listArea, setListArea] = useState([]);
    const [loading, setLoading] = useState(false);
    const [orderin, setOrderin] = useState({
        order: '',
        orderBy: ''
    });


    const handleOrder = (orderBy) => {
        setOrderin((prev) => {
            if (prev.orderBy === orderBy) {
            return {
                orderBy,
                order: prev.order === 'asc' ? 'desc' : 'asc'
            };
            } else {
            return {
                orderBy,
                order: 'asc'
            };
            }
        });
    };


    
    const [pagination, setPagination] = useState({
      links: {
        next: null,
        previous: null,
      },
      querys: {
        filter: "",
        status: "",
        autores: [],
        areas: [],
      },
      print_key: null,
      path: "/api/v1/documento/list_mestra/",
      current: 1,
      total_pages: 1,
      total_items: 1,
      page_size: 1,
    });

    const handelUsuarios = async () => {
        try{
            const response = await api.get('/api/v1/documento/list_autores/')
            setListUser(response.data);
    
        }catch(error){
            console.log(error)
        }
      
    }

    const handleAreas = async () => {
        try{
            const response = await api.get('/api/v1/documento/list_areas/')
            setListArea(response.data);
    
        }catch(error){
            console.log(error)
        }
      
    }

    useEffect(() => {
    handelUsuarios()
    handleAreas()
    }, []);

    
   

    const getDocuments = async (page) => {
        setLoading(true);
        try{
            const filteredParams = Object.fromEntries(
                Object.entries(pagination.querys).filter(([_, value]) => value !== null && value !== "")
            );
            const response = await api.get(`${pagination.path}`,{
                params: {
                    ...filteredParams,
                    page: page
                },
                paramsSerializer: {
                    indexes: null,
                },

            });
            setLoading(false);
            setDocuments(response.data.results);
            setPagination({
                    links: {
                        next: response.data.links.next,
                        previous: response.data.links.previous
                    },
                    querys:{
                        ...pagination.querys
                    },
                    path: pagination.path,
                    print_key: response.data.print_key,
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });
        }catch(error){
            console.log(error);
        }
    }


    const FilteredDocuments = async (data_filter) => {
        setLoading(true);
        try{
            const response = await api.get('/api/v1/documento/documents_query/',{
                params: {
                    autores: data_filter.autor.map((a) => a.value),
                    areas: data_filter.area.map((a) => a.value),
                    status: data_filter.status,
                },
                paramsSerializer: {
                    indexes: null,
                },
                
            });
            resetFilters(
                "autor",
                "area"
            );
            setLoading(false);
            setDocuments(response.data.results);
            setPagination({
                    links: {
                        next: response.data.links.next,
                        previous: response.data.links.previous
                    },
                    querys:{
                        ...pagination.querys,
                        autores: data_filter.autor.map((a) => a.value),
                        areas: data_filter.area.map((a) => a.value),
                        status: data_filter.status,
                    },
                    path: '/api/v1/documento/documents_query/',
                    print_key: response.data.print_key,
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });
        }catch(error){
            console.log(error);
        }
    }


    const PrintDocuments = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/v1/documento/print/', {
                params: {
                    print_key: pagination.print_key,
                },
                responseType: 'blob', // Importante para arquivos binários
            });

            setLoading(false);

            // Criar um blob a partir da resposta
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            // Criar um link temporário para download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'documentos.xlsx'); // Nome do arquivo
            document.body.appendChild(link);
            link.click();

            // Remover o link temporário
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

        } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
            setLoading(false);
        }
    };




    
    useEffect(()=>{
        getDocuments();
    }, [])



    const handleSearchDynamic = useCallback(
        debounce(async (value, setLoading, setDocuments, setPagination) => {
            setLoading(true);
            try {
                const response = await api.get('/api/v1/documento/list_mestra/',{
                    params: {
                        filter: value,
                    }
                });
                
                setPagination({
                    links: {
                        next: response.data.links.next,
                        previous: response.data.links.previous
                    },
                    querys:{
                        filter: value,
                    },
                    path: '/api/v1/documento/list_mestra/',
                    print_key: response.data.print_key,
                    current: response.data.current_page,
                    total_pages: response.data.total_pages,
                    total_items: response.data.total_items,
                    page_size: response.data.page_size
                });
                setDocuments([]);
                setDocuments(response.data.results);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }, 500),
        []
    );


    const handleCustomFilter = (value) => {
        handleSearchDynamic(value, setLoading, setDocuments, setPagination);
    }

    


    return (
        <div className="folder-content">
            <div className='folder-row-2' style={{marginLeft: '1.2%', marginRight: '1.2%'}}>
                <div className="containter-table-documents">
                    <ListTable item={documents} loading={loading} orderin={orderin} handleOrder={handleOrder} listUser={listUser} listArea={listArea} handleCustomFilter={handleCustomFilter} FilteredDocuments={FilteredDocuments} PrintDocuments={PrintDocuments}/>
                </div>
                <div className='w-full border-gray-300 text-sm shadow-md rounded-lg p-1' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 5, height: 35, backgroundColor: '#ebe9e9', borderRadius: 5}}>
                    <Pagination
                        last
                        size='xs'
                        first
                        limit={pagination.page_size}
                        total={pagination.total_items}
                        activePage={pagination.current}
                        maxButtons={7}
                        onSelect={(eventKey) => getDocuments(eventKey)}
                    />
                </div>
            </div>
        </div>
    );
}
export default ListaMestraComponent;



