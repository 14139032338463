import './gestao-amz.css'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import amz from './assets/amz_img.png';
import { useDispatch } from 'react-redux';
import { createDataset} from '../../utils/store/datasets';
import { useAxios } from '../../utils/api/api';

const GestaoArmazen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useAxios();

    const getNnrData = async () => {
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                params: {  
                    table: 'Nnr010'
                }
            });
            dispatch(createDataset({datasetName: 'Nnr010', data: response.data}));
        } catch (error) {
            console.error("Error fetching products:", error);
            

        }
       
    }
    

    const getFilialData = async () => {
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                params: {  
                    table: 'Filial'
                }
            });
            dispatch(createDataset({datasetName: 'Filial', data: response.data}));
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    }

    useEffect(() => {
        getNnrData();
        getFilialData();
    }, []);



    return(
        <div className="folder-content">
            <div className="folder-row-2">
                
                <div className='table-documents main'>
                    <div className='cards-container-gestao-compras'>
                        <div className="card-gestao-compras"  onClick={()=>navigate('solicitacao-armazem')}>
                            <img src={amz} alt="sc" style={{width: '100%', height: '100%', borderRadius: 5}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GestaoArmazen;
