import '../../../../../../tailwind.css';
import { useState } from "react"
import { FaRegFileAlt } from "react-icons/fa";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { CiUser, CiCalendar, CiDeliveryTruck, CiFileOn, CiMoneyBill } from "react-icons/ci";
import { BiPackage } from "react-icons/bi";
import moment from 'moment';
import { BsBox2 } from "react-icons/bs";
import './pedido-view.css';
import currency from 'currency.js';


const parseCurrency = (formattedValue) => {
        if (!formattedValue) return 0;

        if (typeof formattedValue === 'number') {
            return parseFloat(formattedValue.toFixed(2)); // Garante 2 casas decimais
        }

        // Remove espaços extras
        formattedValue = formattedValue.trim();

        // Substitui todas as vírgulas por pontos (caso seja separador decimal)
        formattedValue = formattedValue.replace(',', '.');

        // Captura apenas os números e o último separador decimal válido
        const validCurrency = formattedValue.match(/[\d]+(?:\.\d{0,2})?/g);

        if (!validCurrency) return 0;

        return parseFloat(validCurrency[0]);
  };


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value);
  };

  const handleTotal = (data) => {
        let total = 0;
        data.map((item) => {
            total += parseCurrency(currency(item.total).subtract(item.vlrDesconto).add(item?.valor_frete).add(item?.vlrDespesa).value);
        });
        return total.toFixed(2);
  }


  const handleTotalFinal = (data) => {
        let total = 0;
        data.map((item) => {
            total += parseCurrency(currency(item.total).value);
        });
        return total.toFixed(2);
  }

  const handleTotalDesconto = (data) => { 
        let total = 0;
        data.map((item) => {
            total += parseCurrency(item.vlrDesconto);
        });
        return total.toFixed(2);

  }

  const handleTotalFrete = (data) => { 
        let total = 0;
        data.map((item) => {
            total += parseCurrency(item?.valor_frete);
        });
        return total.toFixed(2);

  }


function OrderItem({ item }) {
  

  return (
    <div>
      <div style={{minHeight: 300}}>
      <table className="w-full border border-gray-300 text-sm">
        <thead className="bg-gray-300 text-gray-700 uppercase text-[10px] sticky z-10" style={{top: 0}}>
          <tr>
            <th className="border p-1">COD. DO PRODUTO</th>
            <th className="border p-1">DESCRIÇÃO DO PRODUTO</th>
            <th className="border p-1">QTD</th>
            <th className="border p-1">VALOR UN.</th>
            <th className="border p-1">CENTRO DE CUSTO</th>
            <th className="border p-1">N° SC - ITEM</th>
            <th className="border p-1">N° SA - ITEM</th>
            <th className="border p-1">SOLICITANTE</th>
            <th className="border p-1">EMISSÃO</th>
            <th className="border p-1">NUMERO NF</th>
            <th className="border p-1">OBSERVAÇÃO</th>
            <th className="border p-1">VALOR TOTAL DO ITEM</th>
          </tr>
        </thead>
        <tbody>
          {item?.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100 even:bg-gray-50 text-[10px]">
              <td className="border p-1 text-center">{item?.produto}</td>
              <td className="border p-1 text-center">{item?.descri}</td>
              <td className="border p-1 text-center">{parseFloat(item?.quantidade).toFixed(2)}</td>
              <td className="border p-1 text-center">{formatCurrency(item?.precoUnitario)}</td>
              <td className="border p-1 text-center">{item?.centroCusto}</td>
              <td className="border p-1 text-center">{item?.numSc ? `${item.numSc} - ${item?.itemSc}` : 'N/A'}</td>
              <td className="border p-1 text-center">{item.sa?.value || 'N/A'}</td>
              <td className="border p-1 text-center">{item?.solicitante?.toUpperCase() || "N/A"}</td>
              <td className="border p-1 text-center">{moment(item?.data_criacao).format("DD/MM/YYYY")}</td>
              <td className="border p-1 text-center">
                {item?.relacoes_pd?.pd?.nf_data || 'SEM NF'}
              </td>
              <td className="border p-1 text-center">{item?.observacao?.trim() || "SEM OBS"}</td>
              <td className="border p-1 text-center">{formatCurrency(item?.total)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  )
}

const PurchaseOrderView = ({data}) => {




  const getCurrentItems = () => {
      return data.data;
  };


  const handleStatus = (value) => {
        if(value === 'PENDENTE'){
            return 'APROVADA';
        }else if(value === "BLOQUEADO"){
            return 'EM APROVAÇÃO';
        }else if (value === "TRANSPORTE"){
            return 'EM TRANSPORTE';
        }
        else{
            return value;
        }
        
    }

  return (
    <div style={{ width: '100%', margin: 0, borderRadius: 5, height: 'auto', position: 'relative', padding: 5}}>
      <div className="p-3 border-b border-gray-200" style={{border: '1px solid #e4e2e2', marginTop: 5, borderRadius: 5, height: '20%'}}>
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-5">
            <h2 className="text-base font-bold">N° {data.numero}</h2>
            <span className="px-2 py-0.5 bg-red-100 text-red-700 text-xs font-semibold rounded-md">{handleStatus(data.status)}</span>
          </div>
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div className='pedido-grid'>
            
            <div className="flex items-center gap-1 text[10px]">
              <BsBox2 style={{fontSize: 15, color: 'gray', marginLeft: 4}} />
              <span>FILIAL: <span className="font-semibold">{data.data[0]?.filial}</span></span>
            </div>

            <div className="flex items-center gap-1">
              <CiUser style={{fontSize: 20, color: 'gray'}}/>
              <span>COMPRADOR: <span className="font-semibold">{data.data[0]?.comprador}</span></span>
            </div>

            

            
            <div className="flex items-center gap-1">
              <CiDeliveryTruck style={{ fontSize: 20, color: 'gray' }} />
              <span className="truncate overflow-hidden whitespace-nowrap max-w-[250px]">
                FORNECEDOR: <span className="font-semibold" title={data.fornecedor}>{data.fornecedor}</span>
              </span>
            </div>


            <div className="flex items-center gap-1">
              <CiCalendar style={{fontSize: 20, color: 'gray'}} />
              <span>ENTREGA: <span className="font-semibold">{moment(data.data[0]?.datPrf).format('DD/MM/YYYY')}</span></span>
            </div>
        
          
            

            
            
            
            <div className="flex items-center gap-1">
              <CiFileOn style={{fontSize: 20, color: 'gray'}} />
              <span>TIPO DE FRETE: <span className="font-semibold">{data?.tipoFrete}</span></span>
            </div>
            
            

            <div className="flex items-center gap-1">
              <BiPackage style={{fontSize: 20, color: 'gray'}} />
              <span>VALOR DO FRETE: <span className="font-semibold">{formatCurrency(handleTotalFrete(data?.data))}</span></span>
            </div>   

            <div className="flex items-center gap-1">
              <CiMoneyBill style={{fontSize: 20, color: 'red'}} />
              <span>DESCONTOS CONCEDIDOS: <span className="font-semibold">{formatCurrency(handleTotalDesconto(data?.data))}</span></span>
            </div>       
            
            <div className="flex items-center gap-1">
              <CiMoneyBill style={{fontSize: 20, color: 'green'}}/>
              <span>SUBTOTAL: <span className="font-semibold">{formatCurrency(handleTotal(data?.data))}</span></span>
            </div>

          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column', width: '7%', fontSize: 12, marginRight: 25, marginBottom: 5}}>
              <span>VALOR FINAL</span>
              <span className="font-semibold">{formatCurrency(handleTotalFinal(data?.data))}</span>
          </div>
        </div>



      </div>

      <div className="divide-y divide-gray-200">
        <div className="px-3 py-1 bg-gray-100 flex justify-between items-center">
          <h3 className="text-xs font-semibold">ITENS DO PEDIDO</h3>
           <h3 className="text-xs font-semibold">{data?.data?.length} ITEMS</h3>
        </div>

  

        <div style={{margin: 0, padding: 0}}>
          <OrderItem item={getCurrentItems()} />
        </div>
      </div>
    </div>
  )
}
export default PurchaseOrderView
