import '../../tailwind.css';
import React, { useState } from "react";
import { FaCircle } from "react-icons/fa6";
import { useNavigate} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { toggleIsView } from "../../utils/store/channelMessage";

const  CustomDropdown = ({notifications, onClose}) => {
    const [showAll, setShowAll] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNavigate = (id, path, uuid) => {
        dispatch(toggleIsView({ id: id, path:path, is_view: true }));
        onClose();  
        if(path === "solicitacao_compra"){
            navigate("/aplicativos/gestao-compras/solicitacao-compras", { state: { uuid } });
        }else if(path === "pedido_compra"){
            navigate("/aplicativos/gestao-compras/pedido-compras", { state: { uuid } });
        }else if(path === "solicitacao_amz"){
            navigate("/aplicativos/gestao-armazem/solicitacao-armazem", { state: { uuid } });
        }
    }

        

    const renderIcon = (tipo) => {
        if (tipo === "ERRO") {
        return <FaCircle style={{fontSize: 9, marginTop: -2, marginRight: 6, color: 'red'}}/>;
        }else if(tipo === "SUCESSO") {
        return <FaCircle style={{fontSize: 9, marginTop: -2, marginRight: 6, color: 'green'}}/>;
        }else if(tipo === "INFO") {
        return <FaCircle style={{fontSize: 9, marginTop: -2, marginRight: 6, color: 'blue'}}/>;
        }
        
    }

    

    return (
        <div className="w-80 bg-white shadow-lg rounded-lg p-4 border border-gray-200">
            <div style={{width: '100%', paddingBottom: 10}}>
                <span className="font-semibold text-[12px]">NOTIFICAÇÕES</span>
            </div>
            <div className="divide-y divide-gray-200">
                {(showAll ? [...notifications].sort((a, b) => b.id - a.id) : [...notifications].sort((a, b) => b.id - a.id).slice(0, 5)).map((item) => (
                    <div key={item.id} className="py-3 border-b border-gray-200 last:border-b-0" style={{cursor: 'pointer'}}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {renderIcon(item.tipo)}
                            <span className="text-gray-500 text-[10px] block mb-1">{item.time}</span>
                        </div>
                        <p onClick={()=>handleNavigate(item.id, item.path, item.uuid)} className="text-gray-600 text-[11px] leading-relaxed hover:underline hover:text-blue-800 duration-200">{item.message}</p>
                    </div>
                ))}
                </div>

            {!showAll && (
                <button
                onClick={() => setShowAll(true)}
                className="mt-2 w-full text-center py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg"
                >
                Mais notificações
                </button>
            )}
        </div>
    );
}
export default CustomDropdown;
