import './drawer-pedido-form.css';
import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@rsuite/icons/Close';
import { IconButton, Input, Loader, InputPicker, Modal, Button} from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import CheckIcon from '@rsuite/icons/Check';
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import PageIcon from '@rsuite/icons/Page';
import { useFormik } from 'formik';
import { ListScContext } from '../../utils/scListContext';
import ListScPendente from '../listScPendente';
import currency from 'currency.js';
import {atualizarGrupos} from '../../utils/groupdata';
import { debounce, set } from 'lodash';
import { v5 as uuidv5 } from 'uuid';
import CustomTagInput from '../customTagInput';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});




const listCond = [
  { e4_codigo: '001', e4_descri: 'A VISTA' },
  { e4_codigo: '003', e4_descri: '60 Dias' },
  { e4_codigo: '002', e4_descri: '1X 30 Dias' },
  { e4_codigo: '004', e4_descri: 'ENTRADA,30,60' },
  { e4_codigo: '005', e4_descri: '00,30,60,90' },
  { e4_codigo: '006', e4_descri: 'TODO DIA 10' },
  { e4_codigo: '007', e4_descri: 'PGTO ANTECIPADO' },
  { e4_codigo: '008', e4_descri: '2X' },
  { e4_codigo: '009', e4_descri: '3X' },
  { e4_codigo: '010', e4_descri: '4X' },
  { e4_codigo: '011', e4_descri: '5X' },
  { e4_codigo: '012', e4_descri: '6X' },
  { e4_codigo: '013', e4_descri: 'TODO DIA 14' },
  { e4_codigo: '014', e4_descri: 'TODO DIA 28' },
  { e4_codigo: '015', e4_descri: 'TODO DIA 15' },
  { e4_codigo: '016', e4_descri: 'TODO DIA 15' },
  { e4_codigo: '017', e4_descri: 'TODO DIA 05' },
  { e4_codigo: '018', e4_descri: '8X' },
  { e4_codigo: '019', e4_descri: '6X' },
  { e4_codigo: '020', e4_descri: 'TODO DIA 24' },
  { e4_codigo: '021', e4_descri: 'TODO DIA 20' },
  { e4_codigo: '022', e4_descri: '7X' },
  { e4_codigo: '023', e4_descri: '8X' },
  { e4_codigo: '024', e4_descri: '9X' },
  { e4_codigo: '025', e4_descri: '10X' },
  { e4_codigo: '026', e4_descri: '12X' },
  { e4_codigo: '027', e4_descri: 'TODO DIA 31' },
  { e4_codigo: '028', e4_descri: '5X PCC + IR 1º PARCELA' },
  { e4_codigo: '029', e4_descri: 'TODO DIA 09' },
  { e4_codigo: '030', e4_descri: '2X' },
  { e4_codigo: '031', e4_descri: 'TODO DIA 05' },
  { e4_codigo: '032', e4_descri: 'SETE DIAS' },
  { e4_codigo: '033', e4_descri: '14 DIAS' },
  { e4_codigo: '034', e4_descri: '16 DIAS' },
  { e4_codigo: '035', e4_descri: '17 DIAS' },
  { e4_codigo: '036', e4_descri: '18 DIAS' },
  { e4_codigo: '037', e4_descri: '5% CAUCAO' },
  { e4_codigo: '038', e4_descri: '1 X 90 DIAS' },
  { e4_codigo: '039', e4_descri: '11 DIAS' },
  { e4_codigo: '040', e4_descri: '35 DIAS' },
  { e4_codigo: '041', e4_descri: '65 DIAS' },
  { e4_codigo: '042', e4_descri: '15 DIAS' },
  { e4_codigo: '043', e4_descri: '150 DIAS' },
  { e4_codigo: '044', e4_descri: '25 DIAS' },
  { e4_codigo: '045', e4_descri: '31 DIAS' },
  { e4_codigo: '046', e4_descri: '10 DIAS' },
  { e4_codigo: '047', e4_descri: '87 DIAS' },
  { e4_codigo: '048', e4_descri: '20 DIAS' },
  { e4_codigo: '049', e4_descri: '27 DIAS' },
  { e4_codigo: '050', e4_descri: '28 DIAS' },
  { e4_codigo: '051', e4_descri: 'S/ENTRADA, 30 E 60 DIAS' },
  { e4_codigo: '052', e4_descri: '59 DIAS' },
  { e4_codigo: '053', e4_descri: 'TODO DIA 29' },
  { e4_codigo: '054', e4_descri: 'TODO DIA 30' },
  { e4_codigo: '055', e4_descri: 'TODO DIA 17' },
  { e4_codigo: '056', e4_descri: 'TODO DIA 13' },
  { e4_codigo: '057', e4_descri: '24X' },
  { e4_codigo: 'C01', e4_descri: 'CARTÃO 1X' },
  { e4_codigo: 'C02', e4_descri: 'CARTÃO 2X' },
  { e4_codigo: 'C03', e4_descri: 'CARTÃO 3X' },
  { e4_codigo: 'C04', e4_descri: 'CARTÃO 4X' },
  { e4_codigo: 'C05', e4_descri: 'CARTÃO 5X' },
  { e4_codigo: 'C06', e4_descri: 'CARTÃO 6X' },
  { e4_codigo: 'C07', e4_descri: 'CARTÃO 7X' },
  { e4_codigo: 'C08', e4_descri: 'CARTÃO 8X' },
  { e4_codigo: 'C09', e4_descri: 'CARTÃO 9X' },
  { e4_codigo: 'C10', e4_descri: 'CARTÃO 10X' },
  { e4_codigo: 'C11', e4_descri: 'CARTÃO 11X' },
  { e4_codigo: 'C12', e4_descri: 'CARTÃO 12X' },
  { e4_codigo: 'C13', e4_descri: 'DIA 20' },
  { e4_codigo: '058', e4_descri: 'TODO DIA 30' }
];





const DrawerPedidoForm = ({ open, setOpen, dataEdit, setDataEdit, setOpenCard }) => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const api = useAxios();
  const user = useSelector((state) => state.auth);
  const datasets = useSelector((state) => state.datasets);
  const { handleCheckboxChange, selectedSolicitacao, setSelectedSolicitacao, setCardSelected, setPedidoList, handleRemoveItem, listUser } = useContext(ListScContext);
  const [ setListSelected] = useState([]);
  const [openImport, setOpenImport] = useState(false);
  const [IsImport, setIsImport] = useState(false);
  const [listFornec, setListFornec] = useState([]);
  const [amzList, setAmzList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [listLoja, setListLoja] = useState([]);
  const [loadingInput, setLoadingInput] = useState(false);
  const [centroList, setCentroList] = useState([]);
  const [openList, setOpenList] = useState(true);
  const [ItemBkp, setItemBkp] = useState(null);
  const [tipoDesconto, setTipoDesconto] = useState('');
  const [filialList, setFilialList] = useState([]);
  

  const FreteList = [
    { value: 'C', label: 'C-CIF' },
    { value: 'F', label: 'F-FOB' },
    { value: 'S', label: 'S-SEM FRETE' },
  ]

  
  const formik = useFormik({
    initialValues: {
      valor_frete: 0.0,
      tipoFrete: '',
      vlrDespesa: 0.0,
      filial: '',
      TotalDescontoP: 0,
      TotalDescontoM: 0,
      noFornecedor: '',
      fornecedor_desc: '',
      loja: '',
      condicaoPago: '',
      condicaoPago_desc: '',
      totalPedido: 0.0,
      totalPgto: 0.0,
      moeda: '1',
      vendedor: '',
      tel_vendedor: '',
      total_ipi: 0.0,
      datPrf: '',
      items: []
    }
  });


  const itemsFormik = useFormik({
    initialValues: {
      item: '',
      id: '',
      virtualId: '',
      produto: '',
      quantidade: 0.0,
      precoUnitario: 0.0,
      um: '',
      numSc: '',
      itemSc: '', 
      quantsc: 0.0,
      datPrf: '',
      total: 0.0,
      descri: '',
      observacao: '',
      armazem: '',
      centroCusto: '',
      centroCusto_desc: '',
      vlrDescontoPerc: 0.0,
      vlrDesconto: 0.0,
      imposto_ipi: 0.0,
      desconto: 0.0,
      usuarios: []

    }
  });

  const handleSearchFornec = async (value) => {
    if(value.length > 1){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value.toUpperCase(),
              table: 'Sa2010'  
          }
          
      });
      const uniqueArray = response.data.filter((item, index, self) =>
        index === self.findIndex(t => t.a2_cod.trim() === item.a2_cod.trim())
      );
      setLoadingInput(false);
      setListFornec(uniqueArray);
      } catch (error) {
        console.log(error)
      }
    }
  }




  const handleAcompanhantes = (action, data) => {
    itemsFormik.setValues((prevState) => {
      const updatedAcompanhantes = Array.isArray(prevState.usuarios) ? [...prevState.usuarios] : [];

      switch (action) {
        case "add":
          if (!updatedAcompanhantes.some(acomp => acomp.id === data.id)) {
            updatedAcompanhantes.push({
              id: data.id,
              first_name: data.first_name,
              last_name: data.last_name,
              is_propagate: data.is_propagate ?? false
            });
          }
          break;

        case "update":
          for (let i = 0; i < updatedAcompanhantes.length; i++) {
            if (updatedAcompanhantes[i].id === data.id) {
              updatedAcompanhantes[i] = { ...updatedAcompanhantes[i], ...data };
              break;
            }
          }
          break;

        case "remove":
          return { ...prevState, usuarios: updatedAcompanhantes.filter(acomp => acomp.id !== data.id) };

        default:
          console.warn("Ação inválida para handleAcompanhantes");
      }

      return { ...prevState, usuarios: updatedAcompanhantes };
    });
  };


  const handleSearchLoja = async (value) => {
    if(value.length > 3){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,
              table: 'Loja'  
          }
      });
      setLoadingInput(false);
      setListLoja(response.data);
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleSearchP = useCallback(
        debounce(async (value, setLoadingInput, setProductList) => {
              try {
                  const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
                      params: {
                          filter: value,  
                          table: 'Sb1010'
                      }
                    });
                  setLoadingInput(false);
                  setProductList(response.data);
              } catch (error) {
                  console.log(error);
              } finally {
                  setLoadingInput(false);
              }
        }, 500),
    []
  );
  


  const handleSearchProduct = (value) => {
    setOpenList(true);
    if(value.length > 1){
      setLoadingInput(true);
      handleSearchP(value, setLoadingInput, setProductList);
    }else{
      setLoadingInput(false);
    }
    
  }


  const handleSearchCentro = async (value) => {
    setLoadingInput(true);
    try {
      const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
        params: {
            filter: value,  
            table: 'Ctt010'
        }
    });
      setLoadingInput(false);
      setCentroList(response.data);
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    const nnrData = datasets['Nnr010'] ?? [];
    const filialData = datasets['Filial'] ?? [];
    setFilialList(filialData);
    setAmzList(nnrData);
  },[])



  

  

  


  function removerCaracteresEspeciais(texto) {
    return texto
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')     // Remove acentos
      .replace(/ç/g, 'c')                  // Substitui ç
      .replace(/Ç/g, 'C')                  // Substitui Ç
      .replace(/[^\w\s\/-]/g, '');         // Remove tudo, exceto letras, números, espaços, / e -
  }



  const formatCurrency = (value) => {
      return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2
      }).format(value);
  };






  
  

  
  
  const parseCurrency = (formattedValue) => {
    if (!formattedValue) return 0;
  
    if (typeof formattedValue === 'number') {
      return formattedValue;
    }
  
    const onlyDigits = formattedValue.replace(/\D/g, '');
    
    // Se não houver dígitos, retorne 0
    if (!onlyDigits) return 0;
  
    // Considerando que a moeda tem duas casas decimais fixas
    const intValue = parseInt(onlyDigits, 10);
    const value = intValue / 100;
  
    return value;
  };

  const generateUUID = (produto, numsc, quantidade, itemsc, cc) => {
    const uuid = uuidv5(`${produto}${numsc}${quantidade}${itemsc}${cc}`, uuidv5.DNS);
    return uuid;
  };

  const handleImportItems = () => {
  if (selectedSolicitacao.length > 0) {
    const currentItems = formik.values.items || [];

    const newItems = selectedSolicitacao
      .filter(solicitacao => 
        !currentItems.some(item => item.numSc === solicitacao.c1_num && item.itemSc === solicitacao.c1_item)
      )
      .map((solicitacao, index) => {
        let lastItemNumber = currentItems.length > 0 
          ? Math.max(...currentItems.map(item => parseInt(item.item, 10)).filter(num => !isNaN(num)), 0)
          : 0;

        let nextItem = String(lastItemNumber + 1).padStart(4, '0');

        return {
          item: nextItem,
          r_e_c_n_o_field: solicitacao.r_e_c_n_o_field,
          virtualId: generateUUID(solicitacao.c1_produto, solicitacao.c1_num, solicitacao.c1_quant, solicitacao.c1_item, solicitacao.c1_cc),
          id: '',
          produto: solicitacao.c1_produto,
          quantidade: solicitacao.c1_quant,
          precoUnitario: 0.0,
          um: solicitacao.c1_um,
          numSc: solicitacao.c1_num,
          itemSc: solicitacao.c1_item, 
          quantsc: solicitacao.c1_quant,
          datPrf: '',
          total: 0.0,
          descri: solicitacao.c1_descri,
          observacao: solicitacao.c1_obs,
          armazem: solicitacao.c1_local,
          centroCusto: solicitacao.c1_cc,
          centroCusto_desc: solicitacao.centro_custo,
          vlrDescontoPerc: 0.0,
          vlrDesconto: 0.0,
          imposto_ipi: 0.0,
          desconto: 0.0,
          usuarios: []
        };
      });

    if (newItems.length > 0) {
      formik.setFieldValue("items", [...currentItems, ...newItems]);
    }
  }
};

useEffect(() => {
  handleImportItems();
}, [selectedSolicitacao, IsImport]);


  


  const SumtotalValues = (items) => {
    const totalSum = items.reduce((sum, item) => 
      currency(sum).add(currency(item?.total || 0)), currency(0)
    );
    
    formik.setFieldValue('totalPgto', totalSum.value);
    formik.setFieldValue('totalPedido', totalSum.value);

  };

  const handleAddItem = () => {
    setItemBkp([]);

    if (!itemsFormik.values.produto) {
        enqueueSnackbar("Produto é obrigatório", { variant: "error", autoHideDuration: 2000 });
        return;
    }
    if (!itemsFormik.values.quantidade) {
        enqueueSnackbar("Quantidade é obrigatória", { variant: "error", autoHideDuration: 2000 });
        return;
    }
    if (!itemsFormik.values.precoUnitario) {
        enqueueSnackbar("Preço unitário é obrigatório", { variant: "error", autoHideDuration: 2000 });
        return;
    }
    if (!itemsFormik.values.centroCusto) {
        enqueueSnackbar("Centro de custo é obrigatório", { variant: "error", autoHideDuration: 2000 });
        return;
    }
    if (!itemsFormik.values.armazem) {
        enqueueSnackbar("Armazém é obrigatório", { variant: "error", autoHideDuration: 2000 });
        return;
    }

    let lastItemNumber = 0;
    if (formik.values.items.length > 1) {
      lastItemNumber = formik?.values?.items
      .map(item => parseInt(item.item, 10)) 
      .filter(num => !isNaN(num)) 
      .sort((a, b) => b - a)[0] || 0; 
    }

    let nextItem = itemsFormik.values.item || String(lastItemNumber + 1).padStart(4, '0');

 

    const newItem = {
      ...itemsFormik.values,
      virtualId: generateUUID(itemsFormik.values.produto, itemsFormik.values.numSc, itemsFormik.values.quantidade, itemsFormik.values.itemSc, itemsFormik.values.centroCusto),
      vlrDescontoPerc: 0.0,
      vlrDesconto: 0.0,
      imposto_ipi: 0.0,
      desconto: 0.0,
      item: nextItem
    };

    const values = [...formik.values.items, newItem];
    SumtotalValues(values);
    formik.setFieldValue('items', values);

    const data = {
      ...formik.values,
      items: values,
      totalPedido: currency(formik.values.totalPedido).add(currency(newItem.total)).value
    };
    setItemBkp(data);

    itemsFormik.resetForm({
      values: {
        ...itemsFormik.initialValues,
      usuarios: itemsFormik.values.usuarios
    },
  });
    setProductList([]);
    setCentroList([]);
  };


 
  
  // useEffect(() => {
  //   if(dataEdit){
  //     if(dataEdit.data[0]?.percentDesconto > 0 || dataEdit.data[0]?.moedaDesconto > 0){
  //       setTimeout(() => {
  //         enqueueSnackbar("Os descontos devem ser aplicados novamente.", { variant: "default", autoHideDuration: 5000 });
  //       }, 500);
  //     }


  //     const dataItems = dataEdit.data.map((item, index) => {
  //       const totalImport = currency(item.quantidade, { precision: 4 }).multiply(item.precoUnitario);
  //       return {
  //         item: String(index + 1).padStart(4, '0'),
  //         id: item?.id,
  //         produto: item.produto.trim(),
  //         quantidade: item.quantidade,
  //         precoUnitario: item.precoUnitario,
  //         um: item.um,
  //         numSc: item.numSc,
  //         itemSc: item.itemSc, 
  //         datPrf: item.datPrf,
  //         total: totalImport.value,
  //         descri: item.descri,
  //         observacao: item.observacao,
  //         armazem: item.armazem,
  //         centroCusto: item.centroCusto,
  //         centroCusto_desc: item.centroCusto_desc,
  //         imposto_ipi: 0,
  //         desconto: 0
  //       }
  //     })
  //     setListFornec([{
  //       a2_cod: dataEdit.data[0].noFornecedor,
  //       a2_nome: dataEdit.data[0].fornecedor_desc,
  //     }]);
  //     setListLoja([{
  //       a2_loja: dataEdit.data[0].loja
  //     }]);
  //     handleSearchLoja(dataEdit.data[0].noFornecedor);
  //     formik.setFieldValue('datPrf', moment(dataEdit.data[0].datPrf).format('YYYY-MM-DD'));
  //     formik.setFieldValue('condicaoPago', dataEdit.data[0].condicaoPago);
  //     formik.setFieldValue('condicaoPago_desc', dataEdit.data[0].condicaoPago_desc);
  //     formik.setFieldValue('filial', dataEdit.data[0].filial);
  //     formik.setFieldValue('noFornecedor', dataEdit.data[0].noFornecedor);
  //     formik.setFieldValue('fornecedor_desc', dataEdit.data[0].fornecedor_desc);
  //     formik.setFieldValue('loja', dataEdit.data[0].loja);
  //     formik.setFieldValue('moeda', dataEdit.data[0].moeda);
  //     formik.setFieldValue('items', dataItems);
  //     formik.setFieldValue('totalPedido', 0);
  //     formik.setFieldValue('vendedor', dataEdit.data[0]?.vendedor);
  //     formik.setFieldValue('tel_vendedor', dataEdit.data[0]?.tel_vendedor);
  //     formik.setFieldValue('total_ipi', 0);
  //     formik.setFieldValue('percentDesconto', 0); 
  //     formik.setFieldValue('moedaDesconto', 0); 
  //     formik.setFieldValue('desconto', 0);
  //     setSelectedSolicitacao([]);

  //     const totalSumImport = dataItems.reduce((sum, item) => sum + (item?.total || 0), 0);
      

  //     const data = {
  //     ...formik.values,
  //       items: dataItems,
  //       totalPedido: currency(totalSumImport, { precision: 4 }).value
  //     };
  //     setItemBkp(data);
  //   }
  // }, [open]);
  
 


  


 
  // APLICAÇÃO DE DESCONTO EM PERCENTUAL
  






  function calcularTotais(pedido, name) {
    pedido.items.forEach(item => {
      item.total = currency(item.quantidade).multiply(item.precoUnitario).value;
    });

    pedido.totalPedido = pedido.items.reduce((acc, item) => currency(acc).add(item.total).value, 0);

    if (name === "money") {
      pedido.TotalDescontoM = currency(pedido.totalPedido).multiply(pedido.TotalDescontoP).divide(100).value;
    } else if (name === "percent") {
      pedido.TotalDescontoP = currency(pedido.TotalDescontoM).divide(pedido.totalPedido).multiply(100).value;
    }

    pedido.items.forEach(item => {
      item.proporcao = currency(item.total).divide(pedido.totalPedido).value;
      item.vlrDescontoPerc = currency(item.proporcao).multiply(pedido.TotalDescontoP).value; 
      item.vlrDesconto = currency(item.proporcao).multiply(pedido.TotalDescontoM).value;
    });

    pedido.items.forEach(item => {
        item.imposto_ipi = currency(item.total).multiply(pedido.total_ipi).divide(pedido.totalPedido).value;
    });

    pedido.totalPgto = pedido.items.reduce((acc, item) => {
        const totalItemComDesconto = currency(item.total)
            .subtract(item.vlrDesconto)
            .add(item.imposto_ipi);
        return currency(acc).add(totalItemComDesconto).value;
    }, 0);


    pedido.totalPgto = currency(pedido.totalPgto).add(pedido.valor_frete).add(pedido.vlrDespesa).value;

    return pedido;
  }

  const handleBlur = (name) => {
    let val = calcularTotais(formik.values, name);
    formik.setValues(val)
};

  
  



  


  const handleChange = (name, value) => {
    itemsFormik.setFieldValue(name, value);
    if (["quantidade", "precoUnitario"].includes(name)) {
        if(name === "quantidade"){
          const total = currency(value || 0).multiply(itemsFormik.values.precoUnitario || 0).value;
          itemsFormik.setFieldValue("total", currency(total, { precision: 2 }).value);
        }else if(name === "precoUnitario"){
          const total = currency(itemsFormik.values.quantidade || 0).multiply(value || 0).value;
          itemsFormik.setFieldValue("total", currency(total, { precision: 2 }).value);
        }
    }
  };




  const openEdit = (item) => {
   
    itemsFormik.setValues({
      item: item.item,
      id: item.id,
      produto: item.produto,
    });
    setProductList([{
      b1_cod: item.produto,
      b1_desc: item.descri
    }]);
    setCentroList([{
      ctt_custo: item.centroCusto,
      ctt_desc01: item.centroCusto_desc
    }]);
    itemsFormik.setValues(item);
  }
  


  const handleConclusion = async () => {
    if (!formik.values.noFornecedor) {
      enqueueSnackbar("Fornecedor é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return;
    }
    if (!formik.values.condicaoPago) {
      enqueueSnackbar("Condição de pagamento é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return;
    }

    if (!formik.values.filial) {
      enqueueSnackbar("Filial é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return;
    }

    if (!formik.values.datPrf) {
      enqueueSnackbar("Data de entrega é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return
    }
    
    if (!formik.values.loja) {
      enqueueSnackbar("Loja é obrigatório", { variant: "error", autoHideDuration: 2000 });
      return
    }
    

    if(formik.values.items.length === 0){
      enqueueSnackbar("Adicione um item", { variant: "error", autoHideDuration: 2000 });
      return;
    }
    if (formik.values.items.some(item => item.precoUnitario === 0)) {
      enqueueSnackbar("Preço unitário é obrigatório", { variant: "error", autoHideDuration: 2000 });
    }


    const { items, datPrf, ...rest } = formik.values;
    
    const data = {
      ...rest,
      items: items.map((item, index) => ({
        ...item,
        item: String(index + 1).padStart(4, '0'),
        datPrf: moment(datPrf).format('YYYYMMDD'),
      }))
    };
    try {
      setLoadingRequest(true);
      const response = await api.post(`/api/v2/modules/gestao-compras/pedido-compra/`, data);
      setPedidoList(prevData => atualizarGrupos(prevData, response.data));
      setCardSelected("sync");
      enqueueSnackbar("Pedido criado com sucesso!", { variant: "success", autoHideDuration: 2000 });
      setLoadingRequest(false);
      setTimeout(() => {
        setOpen(false);
      }, 2000);
      setListFornec([]);
      setProductList([]);
      setCentroList([]);
      setSelectedSolicitacao([]);
      resetData();
    } catch (error) {
      console.error("Error creating request:", error);
      enqueueSnackbar("Erro ao criar pedido", { variant: "error", autoHideDuration: 2000 });
      setLoadingRequest(false);
    }
  }


  const handleConclusionEdit = async () => {
    const prymariKey = formik.values.items[0].id;
    const data = {
      ...formik.values,
      id: prymariKey,
      uuid: dataEdit.uuid,
      items: formik.values.items.map((item) => {
        return {
          ...item,
          datPrf: moment(formik.values.datPrf).format('YYYYMMDD'),
          uuid: dataEdit.uuid,
        }
      })
    };
    try {
      const response = await api.put(`/api/v2/modules/gestao-compras/pedido-compra/update_pedido/${dataEdit.numero}/${dataEdit.uuid}/`, data);
      setPedidoList(prevData => atualizarGrupos(prevData, response.data));
      enqueueSnackbar("Pedido Atualizada com sucesso!", { variant: "success", autoHideDuration: 2000 });
      setLoadingRequest(false);
      setListFornec([]);
      setProductList([]);
      setCentroList([]);
      setListSelected([]);
      setSelectedSolicitacao([]);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      setCardSelected("sync");
      resetData();
      setDataEdit(null);
    } catch (error) {
      console.error("Error creating request:", error);
      enqueueSnackbar("Erro ao criar solicitação", { variant: "error", autoHideDuration: 2000 });
      setLoadingRequest(false);
    }
  }


  const resetData = () => {
    setListFornec([]);
    setProductList([]);
    setCentroList([]);
    formik.resetForm();
    itemsFormik.resetForm();
    setDataEdit(null);

  }


  const formatPhone = (value) => {
    // Remove tudo que não é número
    const phoneNumber = value.replace(/\D/g, '');
    
    // Verifica o tamanho para determinar se é celular ou fixo
    if (phoneNumber.length <= 10) {
      // Telefone fixo: (XX) XXXX-XXXX
      return phoneNumber
        .replace(/^(\d{2})(\d)/g, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .substr(0, 14);
    } else {
      // Celular: (XX) XXXXX-XXXX
      return phoneNumber
        .replace(/^(\d{2})(\d)/g, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .substr(0, 15);
    }
  };

  const formatCpfCnpj = (value) => {
    if (!value) return "";

    const cleaned = value.replace(/\D/g, "");

    if (cleaned.length <= 11) {
        return cleaned
            .replace(/^(\d{3})(\d)/, "$1.$2")
            .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
            .replace(/\.(\d{3})(\d{1,2})$/, ".$1-$2");
    } else {
        return cleaned
            .replace(/^(\d{2})(\d)/, "$1.$2")
            .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            .replace(/\.(\d{3})(\d)/, ".$1/$2")
            .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    }
}


  return (
    <div className='modal-custom'>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ backgroundColor: '#00664e', padding: 2 }}>
            <IconButton icon={<CloseIcon />} size='sm' onClick={() => {setOpen(false); resetData(); setIsImport(false)}} />
            <div style={{width: '100%', padding: 10}}>
              <h3 style={{fontSize: 18}}>PEDIDO DE COMPRAS</h3>
            </div>
          </Toolbar>
        </AppBar>

        <div className='form-container'>
          <div className='form-pushare-pd' style={{padding: 10}}>
            <div className='form-pushare-top' style={{padding: 15}}>
              <div className='form-row-product item-01'>
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Cond. Pgto</label>
                  <InputPicker
                    id="product"
                    style={{width: '100%'}}
                    placeholder="Código"
                    value={formik?.values?.condicaoPago}
                    menuMaxHeight={200}
                    menuStyle={{
                      maxWidth: '10%',
                    }}
                    data={listCond.map((item) => 
                      ({
                          label: `${item.e4_codigo} - ${item.e4_descri}`,
                          value: item?.e4_codigo
                      }))}
                    renderMenu={(menu) => {
                          return(
                            <div style={{fontSize: 10}}>
                              {menu}
                            </div>
                          )
                        } 
                    }
                    onSelect={(value) => {
                      const item = listCond.find((item) => item.e4_codigo === value);
                      formik.setFieldValue('condicaoPago', item.e4_codigo)
                      formik.setFieldValue('condicaoPago_desc', item.e4_descri)
                    }}
                    onClean={() => {
                      formik.setFieldValue('condicaoPago', '')
                      formik.setFieldValue('condicaoPago_desc', '')
                    }}
                  />
                </div>


                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Moeda</label>
                  <Input
                    style={{width: '100%'}}
                    value='1'
                    readOnly
                  />
                </div>
                    
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Filial</label>
                  <InputPicker
                    id="product"
                    style={{width: '100%'}}
                    value={formik.values.filial}
                    placeholder="Filial"
                    menuStyle={{
                      maxWidth: '10%',
                      overflowX: 'auto',
                    }}
                    data={filialList.map((item) => 
                    ({
                          label: `${item.codigo} - ${item.descricao}`,
                          value: item?.codigo
                    }))}
                    renderMenu={(menu) => {
                      return(
                        <div style={{fontSize: 11}}>
                          {menu}
                        </div>
                      )
                    }}
                    onSelect={(value) => {
                      formik.setFieldValue('filial', value)
                    }}
                    onClean={() => {
                      formik.setFieldValue('filial', '')
                    }}
                  />
                </div>
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Data Entrega</label>
                  <Input
                    id="product"
                    type='date'
                    style={{width: '100%'}}
                    value={formik.values.datPrf}
                    onChange={(e)=>formik.setFieldValue('datPrf', e)}
                  />
                </div>

                
              </div>

              <div className='form-row-product item-02' style={{marginBottom: 10}}>
                
                {/* FORNECEDOR */}
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Fornecedor</label>
                  <InputPicker
                    id="product"
                    style={{width: '100%'}}
                    placeholder="Fornecedor"
                    value={formik?.values?.noFornecedor}
                    onSearch={(value)=>handleSearchFornec(value)}
                    menuStyle={{
                      maxWidth: '10%',
                      overflowX: 'auto',
                    }}
                    data={listFornec.map((item) => 
                      ({
                          label: `${item.a2_cod} - ${item.a2_nome}`,
                          value: item?.a2_cod
                    }))}
                    renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                          <div style={{fontSize: 11}}>
                            {menu}
                          </div>
                        )
                      }}
                      onSelect={(value) => {
                        const item = listFornec.find((item) => item.a2_cod === value);
                        formik.setFieldValue('noFornecedor', item.a2_cod)
                        handleSearchLoja(item.a2_cod);
                        formik.setFieldValue('fornecedor_desc', item.a2_nome)
                      }}
                      onClean={() => {
                        formik.setFieldValue('noFornecedor', '');
                        formik.setFieldValue('fornecedor_desc', '')
                      }}
                  
                  />
                </div>

                {/* LOJA */}
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Loja</label>
                  <InputPicker
                    id="product"
                    style={{width: '100%'}}
                    placeholder="Loja"
                    value={formik?.values?.loja}
                    menuMaxHeight={200}
                    menuStyle={{
                      maxWidth: '10%',
                      overflowX: 'auto',
                    }}
                    data={listLoja.map((item) => 
                      ({
                          label: `${item.a2_loja} - ${formatCpfCnpj(item.a2_cgc)}`,
                          value: item?.a2_loja
                      }))}
                    renderMenu={(menu) => {
                        return(
                          <div style={{fontSize: 11}}>
                            {menu}
                          </div>
                        )
                      }}
                      onSelect={(value) => {
                        formik.setFieldValue('loja', value)
                      }}
                      onClean={() => {
                        formik.setFieldValue('loja', '')
                    }}
                  
                  />
                </div>

                {/* VENDEDOR */}
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Vendedor</label>
                  <Input
                    id="product"
                    style={{width: '100%'}}
                    value={formik.values.vendedor}
                    placeholder="Nome do vendedor"
                    onChange={(value) => formik.setFieldValue('vendedor', value.toUpperCase())}
                  />
                </div>
                {/* CONTATO */}
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Contato</label>
                  <Input
                    id="product"
                    style={{width: '100%'}}
                    placeholder="Telefone"
                    value={formik.values.tel_vendedor}
                    onChange={(value) => formik.setFieldValue('tel_vendedor', formatPhone(value))}
                  />
                </div>
              </div>

              <div className='form-row-product' style={{marginBottom: 0}}>
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Marcar Usuarios</label>
                  <CustomTagInput acompanhantes={itemsFormik.values.usuarios} usuarios={listUser} handleAcompanhantes={handleAcompanhantes}/>
                </div>
              </div>

            </div>


            <div className='form-pushare-bottom' style={{padding: 15}}>
              <div className='form-row-product item-03'>
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Produto</label>
                  
                  <InputPicker
                    block
                    id="product"
                    menuMaxHeight={300}
                    virtualized
                    style={{width: '100%'}}
                    value={itemsFormik.values.produto}
                    onSearch={(value) => handleSearchProduct(value)}
                    placeholder="Produto"
                    data={productList.map((item) => ({
                        label: item?.b1_cod,
                        value: item?.b1_cod,
                        item
                    }))}
                    menuStyle={{
                      display: openList ? 'block' : 'none',
                    }}
                    menuClassName='rs-custom-picker'
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                        <>
                          {productList.length > 0 && (
                            <div className='menu-custom-picker-titles'>
                              <div className='item-custom-picker-title'>COD.</div>
                              <div className='item-custom-picker-title'>DESCRIÇÃO</div>
                              <div className='item-custom-picker-title'>ESPECIFICAÇÕES</div>
                              <div className='item-custom-picker-title'>UNIDADE DE MEDIDA</div>
                            </div>
                          )}
                          {menu}
                        </>
                        
                      )
                    }}
                    searchBy={() => true}
                    renderMenuItem={(label, item) => (
                      <div className='menu-custom-picker'>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker'>
                            {label}
                          </span>
                        </div>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker' title={item.item.b1_desc} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 250, display: 'inline-block'}}>
                            {item.item.b1_desc}
                          </span>
                        </div>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker' title={item.item.b1_especif} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200, display: 'inline-block'}}>
                            {item.item.b1_especif || ''}
                          </span>
                        </div>
                        <div className='item-custom-picker'>
                          <span className='item-custom-data-picker' style={{marginLeft:'10%'}}>
                            {item.item.b1_um}
                          </span>
                        </div>
                    </div>
                      
                    )}
                    onSelect={(value) => {
                      setOpenList(false);
                      const item = productList.find((item) => item.b1_cod === value);
                      itemsFormik.setFieldValue("produto", item.b1_cod);
                      itemsFormik.setFieldValue("um", item.b1_um);
                      itemsFormik.setFieldValue("descri", item.b1_desc);
                      setProductList([item])
                    }}
                    onClean={() => {
                      itemsFormik.setFieldValue("produto", "");
                      itemsFormik.setFieldValue("um", "");
                      itemsFormik.setFieldValue("descri", "");
                    }}
                  />
                </div>
              </div>

              <div className='form-row-product item-04'>

                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Descrição</label>
                  <Input
                    id="product"
                    placeholder='Descrição do produto'
                    style={{width: '100%'}}
                    value={itemsFormik.values.descri}
                    title={itemsFormik.values.descri}
                    readOnly
                  />
                </div>



                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Unidade</label>
                  <Input
                    id="product"
                    style={{width: '100%'}}
                    placeholder='UN.'
                    value={itemsFormik.values.um}
                    readOnly
                  />
                </div>

                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Qtd.</label>
                  <Input
                    type='number'
                    autoComplete='off'
                    onFocus={(e) => {
                      if(e.target.value === "0"){
                        e.target.value = "";
                      }
                    }}
                    onBlur={(e)=>{
                      const val = e.target.value;
                      if(val === ""){
                          e.target.value = 0.0;
                      }
                    }}
                    onChange={(value)=>{handleChange('quantidade', value)}}
                    value={itemsFormik.values.quantidade}
                    style={{width: '100%'}}
                  />
                </div>

                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Prc Unitario</label>
                  <Input
                    type='text'
                    autoComplete='off'
                    onChange={(value) => {
                      handleChange('precoUnitario', parseCurrency(value));
                    }}
                    value={formatCurrency(itemsFormik.values.precoUnitario)}
                    style={{width: '100%'}}
                  />
                </div>

                

                
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Vlr. Total</label>
                  <Input
                    id="product"
                    type='text'
                    readOnly
                    value={formatCurrency(itemsFormik.values.total)}
                    style={{width: '100%'}}
                  />
                </div>
              </div>


              <div className='form-row-product item-05'>
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Numero (SC)</label>
                  <Input
                    id="product"
                    readOnly
                    placeholder='Numero (SC)'
                    style={{width: '100%'}}
                    value={itemsFormik.values.numSc}
                  />
                </div>
                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Item (SC)</label>
                  <Input
                    id="product"
                    type='number'
                    placeholder='Item (SC)'
                    style={{width: '100%'}}
                    readOnly
                    value={itemsFormik.values.itemSc}
                  />
                </div>

                

                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Centro de Custo</label>
                  <InputPicker
                    id="product"
                    style={{width: '100%'}}
                    onSearch={(value)=>handleSearchCentro(value)}
                    placeholder="Centro de custo"
                    value={itemsFormik.values.centroCusto}
                    searchBy={() => true}
                    menuStyle={{
                      maxWidth: '10%',
                      overflowX: 'auto',
                    }}
                    menuMaxHeight={200}
                    renderMenu={(menu) => {
                      if(loadingInput){
                        return(
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                            <Loader content="Carregando..." center/>
                          </div>
                        )
                      } return(
                          <div style={{fontSize: 10}}>
                            {menu}
                          </div>
                      )
                    }}
                    data={centroList.map((item) => 
                      ({
                        label: `${item.ctt_custo} - ${item.ctt_desc01}`,
                        value: item?.ctt_custo
                    }))}
                    onSelect={(value) => {
                      const item = centroList.find((item) => item.ctt_custo === value);
                      itemsFormik.setFieldValue('centroCusto', item.ctt_custo)
                      itemsFormik.setFieldValue('centroCusto_desc', item.ctt_desc01)
                    }}
                    onClean={() => {
                      itemsFormik.setFieldValue('centroCusto', '')
                      itemsFormik.setFieldValue('centroCusto_desc', '')
                    }}
                  />
                </div>

                

                <div className='form-group input-product-container'>
                  <label style={{marginLeft: 4, fontSize: 12}}>Armazém</label>
                  <InputPicker
                    id="product"
                    style={{width: '100%'}}
                    placeholder="Armazém"
                    value={itemsFormik.values.armazem}
                    menuMaxHeight={150}
                    renderMenu={(menu) => {
                      return(
                        <div style={{fontSize: 10}}>
                          {menu}
                        </div>
                      )
                    }}
                    data={amzList.map((item) => 
                      ({
                        label: `${item.nnr_codigo} - ${item.nnr_descri}`,
                        value: item?.nnr_codigo
                    }))}
                    onSelect={(value) => {
                      itemsFormik.setFieldValue('armazem', value)
                    }}
                    onClean={() => {
                      itemsFormik.setFieldValue('armazem', '')
                    }}
                  />
                </div>

                
              </div>

              <div style={{marginTop: 10}}>
                <Input as="textarea" rows={3} placeholder="Observação" value={itemsFormik.values.observacao} onChange={(value)=> itemsFormik.setFieldValue('observacao', removerCaracteresEspeciais(value.toUpperCase()))}/>
              </div>
            </div>
            <div style={{width: '100%', position: 'absolute', bottom: 0, height: 80, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', left: 0, zIndex: 9999}}>
                <IconButton  icon={<PageIcon style={{backgroundColor: '#dbd9d9'}}/>} style={{width: 150, backgroundColor: '#dbd9d9'}} size='sm' onClick={()=>setOpenImport(true)}>
                  Importar (SC)
                </IconButton>
                <IconButton icon={<ExpandOutlineIcon style={{backgroundColor: '#dbd9d9'}}/>} style={{width: 150, backgroundColor: '#dbd9d9'}} onClick={()=>handleAddItem()} size='sm'>
                  Adicionar
                </IconButton>
                <IconButton appearance='primary'  style={{backgroundColor: '#f03838', width: 150}} onClick={()=> resetData()} icon={<CloseIcon style={{backgroundColor: '#f03838', color: '#fff'}}/>} size='sm'>
                  Cancelar
                </IconButton>
                {dataEdit ? (
                  <IconButton appearance='primary' style={{backgroundColor: '#00664e', width: 150}} icon={<CheckIcon style={{backgroundColor: '#00664e', color: '#fff'}}/>} onClick={()=>handleConclusionEdit()} size='sm'>
                    Atualizar
                  </IconButton>
                ):(
                  <IconButton appearance='primary'  style={{backgroundColor: '#00664e', width: 150}} icon={<CheckIcon style={{backgroundColor: '#00664e', color: '#fff'}}/>} onClick={()=>handleConclusion()} size='sm'>
                    Finalizar
                  </IconButton>
                )}
            </div>
          </div>

          <div className='list-pushare' style={{position: 'relative', padding: 0, overflowY: 'hidden'}}>
            <div style={{padding: 10, height: '100vh', overflowY: 'auto'}}>
              <>
              {loadingRequest ? (
                <Loader content="Salvando..." center/>
              ):(
                <>
                {formik.values?.items?.length > 0 ? (
                  <>
                  <div className='list-pushare-top'>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div className='pushare-top-item'>
                        <strong>Fornecedor:</strong>
                        <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 300, cursor: 'pointer'}}>
                          {formik.values?.fornecedor_desc ? (
                            formik.values?.fornecedor_desc
                          ):(
                            <>
                            Indefinido
                            </>
                          )}
                        </span>
                      </div>
                      
                      <div className='pushare-top-item'>
                        <strong>Comprador: </strong>
                        <span>{user.name} {user.last_name}</span>
                      </div>
                      <div className='pushare-top-item'>
                        <strong>Filial: </strong>
                        <span>
                          {formik.values?.filial ? (
                            formik.values?.filial
                          ):(
                            <>
                            Indefinido
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className='pushare-top-item'>
                        <strong>Data de Entrega</strong>
                        <span>
                          {formik.values?.datPrf ? (
                            moment(formik.values?.datPrf).format('DD/MM/YYYY')
                          ):(
                            <>
                            Indefinido
                            </>
                          )}
                        </span>
                      </div>

                      <div className='pushare-top-item'>
                        <strong>Qtd. Items</strong>
                        <span>
                          {formik.values?.items?.length}
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-container-puchare" style={{marginBottom: 200}}>
                    <div className='list-item-puchare-title pd'>
                      <span style={{textAlign: 'center', marginLeft: 0}}>Produto</span>
                      <span style={{textAlign: 'center'}}>Qtd.</span>
                      <span style={{textAlign: 'center', marginLeft: 10}}>Preço Un.</span>
                      <span style={{textAlign: 'center', marginLeft: 10}}>Desconto</span>
                      <span style={{textAlign: 'center', marginLeft: 10}}>Preço Total</span>
                      <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>...</span>
                    </div>
                  {formik.values?.items.map((item, index) => (
                    <>
                    <li key={item?.virtualId} className="list-item-puchare pd">
                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
                        <span title={item?.produto} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 100}}>{item?.produto}</span>
                        <span title={item?.descri} style={{fontSize: 9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 150, cursor: 'pointer'}}>{item?.descri}</span>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{item?.quantidade}</span>
                      </div>
                      
                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{formatCurrency(item.precoUnitario)}</span>
                      </div>

                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{textAlign: 'center'}}>{formatCurrency(item.vlrDesconto)}</span>
                      </div>

                      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                      <span style={{textAlign: 'center'}}>{formatCurrency(item?.total)}</span>
                      </div>

                      

                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <IconButton 
                        appearance='primary' 
                        style={{backgroundColor: '#bbbaba', marginRight: 5}} 
                        // disabled={formik.values.TotalDescontoM > 0 || formik.values.TotalDescontoP > 0 || formik.values.valor_frete > 0 || formik.values.vlrDespesa > 0 || formik.values.total_ipi > 0 ? true : false}
                        onClick={() => {
                          openEdit(item);
                          const updatedItems = formik.values.items.filter((value, i) => value.virtualId !== item.virtualId);
                          formik.setFieldValue('items', updatedItems);
                        }}
                        icon={<EditIcon style={{backgroundColor: '#bbbaba', color: '#fff'}}/>} 
                        size='sm'
                        />

                        <IconButton
                          appearance='primary'
                          style={{backgroundColor: 'red', marginRight: 5}}
                          // disabled={formik.values.TotalDescontoM > 0 || formik.values.TotalDescontoP > 0 || formik.values.valor_frete > 0 || formik.values.vlrDespesa > 0 || formik.values.total_ipi > 0 ? true : false}
                          onClick={() => {
                            if(selectedSolicitacao.length > 0){
                              handleRemoveItem(item?.r_e_c_n_o_field);
                            }
                            const updatedItems = formik.values.items.filter((value, i) => value.virtualId !== item.virtualId);
                            
                            formik.setFieldValue('items', updatedItems);
                          }}
                          icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>}
                          size='sm'
                        />
                      </div>
                      
                    </li>
                    
                    </>
                    
                  ))}
                  </ul>
                  </>
              ):(
                <span>Nenhum item adicionado</span>
              )}
                </>
              )}
              </>
              
            </div>
            
            <div style={{position: 'absolute', bottom: 0, height: 125, zIndex: 9999, backgroundColor: '#fff', width: '100%', left: 0, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', padding: 5}}>
              <div style={{border: '1px solid #acacac', borderRadius: 5, height: '100%', paddingLeft: 10, paddingTop: 8, paddingRight: 10}}>
                <div className='form-row-product item-06'>
                  <div className='input-product-descont'>
                    <span style={{fontSize: 9}}>DESC (R$)</span>
                    <Input 
                      value={formatCurrency(formik.values.TotalDescontoM) || null} 
                      onBlur={(e)=>{
                        handleBlur("percent")
                        if(e.target.value === "" || parseCurrency(e.target.value) === 0){
                          setTipoDesconto('');
                        }
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('TotalDescontoM', parseCurrency(value));
                        setTipoDesconto('money');
                      }}
                    />
                  </div>
                  <div className='input-product-descont'>
                    <span style={{fontSize: 9}}>DESC (%)</span>
                    <Input 
                      type='number'
                      autoComplete='off'
                      value={formik.values.TotalDescontoP}
                      min={0}
                      max={100} 
                      
                      onFocus={(e) => {
                        if(e.target.value === "0" || e.target.value === 0){
                          e.target.value = "";
                        }
                      }}
                      
                      onBlur={(e)=>{
                        const val = e.target.value;
                        if(val === "" || val === 0){
                            formik.setFieldValue('TotalDescontoP', 0);
                        };
                        handleBlur("money")
                       
                      }}
                      onChange={(value) => {
                        formik.setFieldValue("TotalDescontoP", parseInt(value));
                        setTipoDesconto('percent');
                        
                      }}
                    />
                    
                  </div>

                  <div className='input-product-descont'>
                    <span style={{fontSize: 9}}>VLR IPI. (R$)</span>
                    <Input 
                      type='text'
                      value={formatCurrency(formik.values.total_ipi)}
                      onBlur={(e)=>{
                        handleBlur()
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('total_ipi', parseCurrency(value));

                      }}
                    />
                  </div>

                  <div className='input-product-descont'>
                    <span style={{fontSize: 9}}>TIPO FRETE</span>
                    <InputPicker
                      value={formik.values.tipoFrete}
                      size='sm'
                      placeholder="Frete"
                      menuStyle={{
                        zIndex: 9999,
                      }}
                      data={FreteList}
                      placement='topStart'
                      onSelect={(value) => {
                        formik.setFieldValue('tipoFrete', value);
                        formik.setFieldValue('valor_frete', 0);
                        formik.setFieldValue('vlrDespesa', 0);
                      }}
                      onClean={() => {
                        formik.setFieldValue('tipoFrete', '');
                        formik.setFieldValue('valor_frete', 0);
                        formik.setFieldValue('vlrDespesa', 0);
                        
                      }}
                    />
                  </div>
                </div>

                <div className='form-row-product item-07' style={{marginTop: 5}}>
                  <div className='input-product-descont'>
                    <span style={{fontSize: 9}}>VLR FRETE (R$)</span>
                    <Input 
                      type='text'
                      value={formatCurrency(formik.values.valor_frete)} 
                      disabled={formik.values.tipoFrete === 'C' ? false : true}
                      onBlur={(e)=>{
                        handleBlur()
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('valor_frete', parseCurrency(value));
                      }}
                    />
                  </div>
                  <div>
                    <span style={{fontSize: 9}}>VLR DESPESA (R$)</span>
                    <Input 
                      type='text'
                      value={formatCurrency(formik.values.vlrDespesa)} 
                      disabled={formik.values.tipoFrete === 'F' ? false : true}
                      onBlur={(e)=>{
                        handleBlur()
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('vlrDespesa', parseCurrency(value));
                      }}
                    />
                    
                  </div>

                  <div>
                    <span style={{fontSize: 9}}>TOTAL DESC. (R$)</span>
                    <Input 
                      type='text'
                      value={formatCurrency(
                        formik.values.TotalDescontoM || 0
                      )}
                      style={{border: 'orange 1.5px solid'}}
                    />
                  </div>

                  <div>
                    <span style={{fontSize: 9}}>TOTAL PGR (R$)</span>
                    <Input 
                      type='text'
                      readOnly
                      style={{border: 'green 1.5px solid'}}
                      value={formatCurrency(formik.values.totalPgto)} 
                    />
                  </div>
                  <div>
                    <span style={{fontSize: 9}}>VALOR TOTAL (R$)</span>
                    <Input 
                      type='text'
                      readOnly
                      style={{border: 'green 1.5px solid'}}
                       
                      value={formatCurrency(formik.values.totalPedido)} 
                    />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <Modal size="full" open={openImport} onClose={()=>{setOpenImport(false)}} style={{zIndex: 9999}}>
            <Modal.Body style={{padding: 0, margin: 0}}>
            <div style={{minHeight: 400}}>
                <ListScPendente openImport={open}/>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <div>
                <Button style={{backgroundColor: 'gray', color: '#fff'}} onClick={()=>setOpenImport(false)} appearance="primary">
                Sair
                </Button>
                </div>
            </div>
            </Modal.Footer>
          </Modal>
        </div>
        <SnackbarProvider maxSnack={1} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}/>
      </Dialog>
    </div>
  );
};

export default DrawerPedidoForm;
