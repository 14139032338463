import { Modal, Button, IconButton, Input } from 'rsuite';
import { CiImport } from "react-icons/ci";
import { useState, useEffect } from 'react';
import './modal-export.css'; 
import moment from 'moment';  
import { useAxios } from '../../../../../../utils/api/api'; 
import { useContext } from 'react';
import { ListScContext} from '../../utils/scListContext';
import PurchaseOrderExport from '../pedidoExport';
import { FaRegCircleCheck } from "react-icons/fa6";


const ModalExort = ({open, setOpen, data, setDocument, setOpenDocument}) => {
    const { setCardSelected } = useContext(ListScContext);
    const [isExport, setIsExport] = useState(false);
    const api = useAxios();
    const [dataExport, setDataExport] = useState(null)
    const [loading, setLoading] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [inputs, setInputs] = useState({
        dataEntrega: '',
        vendedor: '',
        contato: ''
    })


    useEffect(() => {
        if(data){
            setDataExport(data)
            handleChange('dataEntrega', moment(data.data[0].c7_datprf).format('YYYY-MM-DD'))
        }
    }, [open]);



    const handleChange = (input, value) => {
        setInputs({...inputs, [input]: value})

    }



   

    const handleApiExport = async () => {
        try{
            setLoading(true)
            const updatedData = {
                items: dataExport.data,
                data_entrega: moment(inputs.dataEntrega).format('YYYYMMDD'),
                vendedor: inputs.vendedor,
                tel_vendedor: inputs.contato,
                uuid: dataExport.uuid
            };
            const response = await api.post(`/api/v2/modules/gestao-compras/pedido-compra/exportar_pedido/`, updatedData);
            setLoading(false)
            setCardSelected("transporte")
            setIsExport(true)
        }catch(error){
            console.log(error)
            setLoading(false)
        }
    }
   


    return (
        <>
        
        <Modal open={openConfirm}  closeButton={false} role="alertdialog" backdrop="static" size="full" enforceFocus={true}  onClose={()=>setOpenConfirm(false)}>
            <Modal.Header>
                <div style={{backgroundColor: '#00664e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                        <Modal.Title style={{fontSize: 15, color: '#fff'}}>EXPORTAR PEDIDO DE COMPRA</Modal.Title>
                    </div>
                </div>
                </Modal.Header>
            <Modal.Body>
            
            {openConfirm && (
                <PurchaseOrderExport data={data} vendedor={inputs.vendedor} contato={inputs.contato} isExport={isExport} setIsExport={setIsExport} setOpenConfirm={setOpenConfirm}/>
            )}
        
            </Modal.Body>
            <Modal.Footer style={{padding: 0, margin: 0}}>
            <div>
                <IconButton loading={loading}  size="xs" onClick={()=>handleApiExport()} style={{backgroundColor: 'gray', color: '#fff', marginLeft: 5, fontSize: 10}} icon={<CiImport  className='rs-icon' style={{color: '#fff', backgroundColor: '#5a5959', height: '100%'}}/>}>Exportar</IconButton>
                
                <Button size='xs' style={{width: 50}} onClick={()=>setOpenConfirm(false)} appearance="subtle">
                    Sair
                </Button>
            </div>
            </Modal.Footer>
            

            

        </Modal>
        

        <Modal open={open} style={{zIndex: 999999, marginTop: '3%'}}  closeButton={false} role="alertdialog" backdrop="static" size="xs" enforceFocus={true}  onClose={()=>setOpen(false)}>
            <Modal.Header>
                <div style={{backgroundColor: '#6e6e6e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                        <Modal.Title style={{fontSize: 13, color: '#fff'}}>CONFIRMAR EXPORTAÇÃO</Modal.Title>
                    </div>
                </div>
                </Modal.Header>
            <Modal.Body>
            <div style={{padding: 5, marginTop: 10, minHeight: 250}}>
                <div>
                    <label style={{fontSize: 10, marginBottom: 5}}>DATA DE ENTREGA</label>
                    <Input style={{width: '100%', marginBottom: 15}} value={inputs.dataEntrega} onChange={(value)=>handleChange('dataEntrega', value)} type='date' />
                </div>
                <div>
                    <label style={{fontSize: 10, marginBottom: 5}}>VENDEDOR</label>
                    <Input style={{width: '100%', marginBottom: 15}} value={inputs.vendedor} onChange={(value)=>handleChange('vendedor', value)} placeholder='Vendedor' />
                </div>
                <div>
                    <label style={{fontSize: 10, marginBottom: 5}}>CONTATO</label>
                    <Input maxLength={20} style={{width: '100%', marginBottom: 15}} value={inputs.contato} onChange={(value)=>handleChange('contato', value)} placeholder='Contato' />
                </div>
            </div>
        
            </Modal.Body>
            <Modal.Footer style={{padding: 0, margin: 0}}>
            <div>
                
                <IconButton size="xs" 
                    onClick={()=>{
                        setTimeout(() => {
                            setOpenConfirm(true); 
                            setOpen(false)
                        }, 500);
                    }} 
                    style={{backgroundColor: '#00664e', color: '#fff', marginLeft: 5, fontSize: 10}} 
                    icon={<FaRegCircleCheck  className='rs-icon' style={{color: '#fff', backgroundColor: '#01503d', height: '100%'}}/>}
                    >Confirmar
                </IconButton>
                
                <Button size='xs' onClick={()=>setOpen(false)} appearance="subtle">
                    Cancelar
                </Button>
            </div>
            </Modal.Footer>
            

        </Modal>

        </>
    );
}
export default ModalExort;