import React, { createContext, useState, useEffect } from "react";

export const ScContext = createContext();

export const ScProvider = ({ children }) => {
  const [cardSelected, setCardSelected] = useState("pendentes");
  

  return (
    <ScContext.Provider
      value={{
        cardSelected,
        setCardSelected,
      }}
    >
      {children}
    </ScContext.Provider>
  );
};
