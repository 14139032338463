import '../../../../../../tailwind.css';
import React from "react";

const NfView = () => {
  const data = {
    "r_e_c_n_o_field": 285152,
    "d1_filial": "01",
    "d1_item": "0018",
    "d1_cod": "1400151",
    "d1_um": "PC",
    "d1_quant": 3,
    "d1_vunit": 1120.99,
    "d1_total": 3362.97,
    "d1_valipi": 0,
    "d1_desc": 0,
    "d1_ipi": 0,
    "d1_conta": "1010502",
    "d1_itemcta": "22101",
    "d1_cc": "10.41.17",
    "d1_pedido": "A21117",
    "d1_itempc": "0019",
    "d1_fornece": "127526",
    "d1_local": "93",
    "d1_doc": "000021463",
    "d1_emissao": "20250217",
    "d1_dtdigit": "20250220",
    "d1_grupo": "140",
    "d1_tipo": "N",
    "d1_serie": "1",
    "d1_tp": "MC",
    "d1_datacus": "20250220",
    "d1_clvl": "RT006",
    "d1_loja": "97",
    "d1_descri": "0101002 TAMBOR DE FREIO",
    "pedido": 186637
  };

  return (
    <div className="max-w-2xl mx-auto p-3 bg-white shadow-md rounded-lg border border-gray-200" style={{marginTop: 10}}>
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-left">N°: #{data.d1_doc}</h2>
      
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Data de Emissão</p>
          <p className="text-lg font-semibold">{data.d1_emissao}</p>
        </div>

        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Filial</p>
          <p className="text-lg font-semibold">{data.d1_filial}</p>
        </div>

        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Fornecedor</p>
          <p className="text-lg font-semibold">{data.d1_fornece}</p>
        </div>

        <div className="col-span-2 md:col-span-3 bg-gray-200 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Codigo</p>
          <p className="text-lg font-semibold">{data.d1_cod}</p>
        </div>
        <div className="col-span-2 md:col-span-3 bg-gray-200 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Descrição</p>
          <p className="text-lg font-semibold">{data.d1_descri}</p>
        </div>

        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Pedido</p>
          <p className="text-lg font-semibold">{data.d1_pedido}</p>
        </div>

        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Item Pedido</p>
          <p className="text-lg font-semibold">{data.d1_itempc}</p>
        </div>

        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Quantidade</p>
          <p className="text-lg font-semibold">{data.d1_quant}</p>
        </div>

        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Unidade</p>
          <p className="text-lg font-semibold">{data.d1_um}</p>
        </div>

        <div className="bg-gray-100 p-3 rounded-lg">
          <p className="text-sm text-gray-500">Valor Unitário</p>
          <p className="text-lg font-semibold">R$ {data.d1_vunit}</p>
        </div>

        <div className="col-span-2 md:col-span-3 flex flex-row gap-2">
          <div className="bg-yellow-100 p-3 rounded-lg flex-1">
            <p className="text-sm text-gray-500">Valor Desconto</p>
            <p className="text-lg font-semibold text-yellow-700">R$ {data.d1_valdesc || 0}</p>
          </div>
          <div className="bg-blue-100 p-3 rounded-lg flex-1">
            <p className="text-sm text-gray-500">Valor Total</p>
            <p className="text-lg font-semibold text-blue-700">R$ {data.d1_total}</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default NfView;
