import './pedido.css';
import PlusIcon from '@rsuite/icons/Plus';
import { IconButton, Modal, Button} from "rsuite";
import { useState, useEffect, useRef, useContext } from "react";
import { useAxios } from "../../../../utils/api/api";
import PdList from "./components/listPd";
import { useNavigate, useLocation } from 'react-router-dom';
import DrawerPedidoForm from './components/drawerPedidoForm';
import PdCards from './components/pdCards';
import ListScPendente from './components/listScPendente';
import { ListScContext} from './utils/scListContext';
import InputFilter from './components/inputsFilter';
import { CiImport } from "react-icons/ci";
import {agruparSolicitacoes} from './utils/groupdata';
import SolicitacaoOrderImport from './components/scImport';



const PedidoCompras = () => {
    const api = useAxios();
    const location = useLocation();
    const uiidState = location.state?.uuid;
    const { setScList, cardSelected,  setLoadingSc,  setPedidoList, setListUser, Pdpagination, setPdPagination, setSelectedSolicitacao, setPagination, isFilter, setIsFilter, selectedSolicitacao, pedidoList, setCardSelected } = useContext(ListScContext);
    const navigate = useNavigate();
    const triggerRef = useRef();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataEdit, setDataEdit] = useState(null);
    const [openDocument, setOpenDocument] = useState(false);
    const [document, setDocument] = useState(null);
    const [loadingExport, setLoadingExport] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [isExport, setIsExport] = useState(false);
    

    
  

    useEffect(() => {
        const controle = new AbortController();
        if(cardSelected === "solicitacao"){
            handleScs("", "PENDENTE", controle);
        }else if(cardSelected === "aprovacao"){
            handleGetSolicitacoes("", "BLOQUEADO", controle);
        }else if(cardSelected === "transporte"){
            handleGetSolicitacoes("", "TRANSPORTE", controle);
        }else if(cardSelected === "aprovada"){
            handleGetSolicitacoes("", "PENDENTE", controle);
        }else if(cardSelected === "sync"){
            handleGetSolicitacoes("" , "SYNC", controle);
        }else if(cardSelected === "concluida"){
            handleGetSolicitacoes("", "CONCLUIDO", controle);
        }else if(cardSelected === "reprovadas"){
            handleGetSolicitacoes("", "REPROVADA", controle);
        }
        return () => {
            controle.abort();
        }
    }, [cardSelected]);

    

    

    



    



    const handelUsuarios = async () => {
        try{
            const response = await api.get('/api/v2/membros/users/get_users/')
            const users = response.data.map(user => ({
                ...user,
                is_propagate: false,
                is_view: true,
            }));
            setListUser(users);
    
        }catch(error){
            console.log(error)
        }
      
    }

    
    
    useEffect(() => {
    handelUsuarios()
    }, []);




    





    const handleScs = async (value, status, controller) => {
        setScList([]); 
        setLoadingSc(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/solicitacoes_list',{
                params: {
                    filter: value,
                    status: status
                },
                signal: controller.signal
            });
            setLoadingSc(false)
            const data = response.data.results.map(item => ({
                ...item,
                c1_quant: item.c1_quant - item.c1_quje
            }));

            const sortedData = data.sort((a, b) => {
            if (a.c1_num === b.c1_num) {
                return parseInt(a.c1_item, 10) - parseInt(b.c1_item, 10);
            }
            return 0;
            });
            setScList(sortedData)
            setPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys:{
                    filter: value,
                    status: status
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        } catch (error) {
            console.log(error)
        }
    }
    


    useEffect(() => {
        if(uiidState){
            handleGetPedidosByUiid(uiidState);
        }
    }, [uiidState, location.key]);


    const handleGetPedidosByUiid = async (uuid) => { 
        setCardSelected(null)
        setLoadingSc(true);
        try {
            const response = await api.get(`/api/v2/modules/gestao-compras/pedido-compra/filter_pedidos_by_uuid/${uuid}/`);
            setLoading(false);
            const data = response.data.map(item => ({
            ...item,
            r_e_c_n_o_field: item.r_e_c_n_o_field ?? item.id
            }));
            const gruposFormatados = agruparSolicitacoes(data);
            setPedidoList(gruposFormatados);
            setLoadingSc(false);
        } catch (error) {
            console.log(error);
        }
    }




    const handleGetSolicitacoes = async (value, status, controller) => { 
        setLoadingSc(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/pedido_list',{
                params: {
                    filter: value,
                    status: status
                },
                signal: controller.signal
            });   
            setLoadingSc(false);
            const gruposFormatados = agruparSolicitacoes(response.data.results);
            setPedidoList(gruposFormatados);
            setPdPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys:{
                    filter: value,
                    status: status
                },
                path: "/api/v2/modules/gestao-compras/pedido-compra/pedido_list",
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        } catch (error) {
            console.log(error)
        }
    }


   


    const handleOpenEdit = (data) => {
        setOpen(true);
        setDataEdit(data);
    }


    const handleExport = async (data) => {
        setLoadingExport(true);
        setLoadingSc(true);
        try{
            const response = await api.post('/api/v2/modules/gestao-compras/pedido-compra/exportar_solicitacao/',data
            );
            setScList(response.data);
            setLoadingExport(false);
            setIsExport(true);
            setLoadingSc(false);
        }catch(error){
            setLoadingExport(false);
            setLoadingSc(false);
        }
    }




   






    return (
        
        <div className="folder-content">
            <div className='container-puchare'>
                <div className="folder-row-2" style={{width: '100%'}}>
                    <div className="container-data-main top" style={{alignItems: 'center'}}>
                        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', marginRight: 5, alignItems: 'center'}}>
                            <InputFilter/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc mobile" icon={<PlusIcon  style={{color: '#fff', height: '100%'}}/>}></IconButton>
                            
                            <IconButton onClick={()=>setOpen(true)}  className="btn-sc" style={{fontSize: 10}} icon={<PlusIcon  style={{color: '#fff', backgroundColor: '#014838', height: '100%'}}/>}>Novo Pedido</IconButton>
                            <IconButton disabled={cardSelected != "solicitacao" || selectedSolicitacao.length === 0} onClick={()=>setOpenImport(true)}   style={{backgroundColor: 'gray', color: '#fff', marginLeft: 5, fontSize: 10}} icon={<CiImport  className='rs-icon' style={{color: '#fff', backgroundColor: '#5a5959', height: '100%'}}/>}>Exportar (SC)</IconButton>
                        </div>
                    </div>
                    <div className="data-cards-content" style={{borderRadius: 8}}>
                        <PdCards/>
                    </div>
                                       
                    <div className='container-data-main' style={{position: 'relative'}}>
                        <>
                        {cardSelected === "solicitacao" ? (
                            <>
                            <ListScPendente/>
                            </>
                        ):(
                            <>
                            <PdList handleOpenEdit={handleOpenEdit}/>
                            </>
                        )}
                        </>
                    </div>
                </div>    
            </div>
            <DrawerPedidoForm open={open} setOpen={setOpen} dataEdit={dataEdit} setDataEdit={setDataEdit}/>
            <Modal  closeButton={false} role="alertdialog" backdrop="static" size="full" enforceFocus={true} open={openImport}  onClose={()=>openImport(false)}>
            <Modal.Header>
                <div style={{backgroundColor: '#00664e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                        <Modal.Title style={{fontSize: 15, color: '#fff'}}>IMPORTAR SOLICITAÇÃO DE COMPRAS</Modal.Title>
                    </div>
                </div>
                </Modal.Header>
            <Modal.Body>
            
            <SolicitacaoOrderImport data={selectedSolicitacao} isExport={isExport} setIsExport={setIsExport} openImport={openImport} setOpenImport={setOpenImport}/>

            </Modal.Body>
            <Modal.Footer style={{padding: 0, margin: 0}}>
            <div>
                <IconButton   size="xs" loading={loadingExport}  onClick={()=>handleExport(selectedSolicitacao)} style={{backgroundColor: 'gray', color: '#fff', marginLeft: 5, fontSize: 10}} icon={<CiImport  className='rs-icon' style={{color: '#fff', backgroundColor: '#5a5959', height: '100%'}}/>}>Exportar</IconButton>
                
                <Button size='xs' style={{width: 50}} onClick={()=>setOpenImport(false)} appearance="subtle">
                    Sair
                </Button>
            </div>
            </Modal.Footer>
            

            

        </Modal>
            
        </div>

    );
}
export default PedidoCompras;
