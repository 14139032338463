import './data-card.css';
import { useAxios } from '../../../../../../utils/api/api';
import { useEffect, useState, useContext } from 'react';
import { SaAmzContext } from '../../../../utils/AmzContext';


const DataCardsAmz = () => {
    const api = useAxios();
    const { cardSelected, setCardSelected } = useContext(SaAmzContext);
    const [data, setData] = useState({
        total: 0,
        pendentes: 0,
        convertido: 0,
        entrega: 0,
        sync: 0
    });


    const handleData = async () => {
        try {
            const response = await api.get('/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/amz_data_cards');
            const {total, pendentes, convertido, entrega, sync} = response.data;
            
        
            const newData = {
                total,
                pendentes,
                convertido,
                entrega,
                sync
            };
            setData(newData);

        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        
        handleData();
        
        const interval = setInterval(() => {
            handleData();
        }, 70000);

        return () => clearInterval(interval);
    }, []);



    

    return(
        <div className='container-data-cards'>
            <div className='data-card-item' onClick={()=>setCardSelected("pendentes")}>
                <span className='data-card-title'>PENDENTES</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.pendentes}</span>
                </div>
                {cardSelected === 'pendentes' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.6%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' onClick={()=>setCardSelected("convertidos")}>
                <span className='data-card-title'>CONVERTIDA EM SC</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.convertido}</span>
                </div>
                {cardSelected === 'convertidos' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.6%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' onClick={()=>setCardSelected("transporte")}>
                <span className='data-card-title'>EM TRANSPORTE</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.entrega}</span>
                </div>
                {cardSelected === 'transporte' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.6%', borderRadius: 0}}/>
                )}
            </div>
            
            <div className='data-card-item' onClick={()=>setCardSelected("todas")}>
                <span className='data-card-title'>CONCLUIDAS</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.total}</span>
                </div>
                {cardSelected === 'todas' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.6%', borderRadius: 0}}/>
                )}
            </div>

            <div className='data-card-item' onClick={()=>setCardSelected("sync")}>
                <span className='data-card-title'>SINCRONIZAÇÃO PENDENTE</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.sync}</span>
                </div>
                {cardSelected === 'sync' && (
                    <hr className='data-card-divider' style={{position: 'absolute', left: 0, bottom: 0, width: '98.6%', borderRadius: 0}}/>
                )}
            </div>

        </div>
    )
}

export default DataCardsAmz;