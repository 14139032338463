import './sc-pd.css';
import { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Loader, Progress, Popover, Whisper, Modal, IconButton, Dropdown, Input, Pagination} from 'rsuite';
import { useAxios } from '../../../../../../utils/api/api';
import { ListScContext } from '../../utils/scListContext';
import { CiCircleCheck } from "react-icons/ci";
import InputFilter from '../inputsFilter';

const ListScPendente = ({openImport}) => {
    const api = useAxios();
    const { scList, setScList, handleCheckboxChange, selectedSolicitacao, handleSelectAll, loadingSc, setLoadingSc, setCardSelected, pagination, setPagination, isFilter, setIsFilter } = useContext(ListScContext);


    const hadleActivePage = async (page) => {
        setLoadingSc(true)
        setIsFilter(false)
        try{
            const response = await api.get('/api/v2/modules/gestao-compras/pedido-compra/solicitacoes_list', {
                params: {
                    ...pagination.querys,
                    page: page
                }
            });
            setLoadingSc(false)
            const data = response.data.results.map(item => ({
                ...item,
                c1_quant: item.c1_quant - item.c1_quje
            }));

            const sortedData = data.sort((a, b) => {
            if (a.c1_num === b.c1_num) {
                return parseInt(a.c1_item, 10) - parseInt(b.c1_item, 10);
            }
            return 0;
            });
            setScList(sortedData);
            setPagination({
                links: {
                    next: response.data.links.next,
                    previous: response.data.links.previous
                },
                querys: {
                    ...pagination.querys,
                },
                current: response.data.current_page,
                total_pages: response.data.total_pages,
                total_items: response.data.total_items,
                page_size: response.data.page_size
            });
        }catch(error){
            console.log(error)
        }
    }
    

    useEffect(() => {
        setCardSelected("solicitacao")
    }, [openImport])

    const handleStatus = (values) => {
        if(values === 'sol_pendente' || values === 'pendente' || values === "PD"){
            return 'PENDENTE';
        }else if(values === 'SY'){
            return 'NÃO ENVIADO';
        }else if(values === 'EA'){
            return 'APROVACAO';
        }else if(values === 'atendimento'){
            return 'EM ATENDIMENTO';
        }
        else{
            return values.toUpperCase();
        }
    }


    return(
        <>
        <div className="purchase-list-container" style={{position: 'relative', padding: 0}}>
            {openImport && (
                <div style={{width: '100%', padding: 10, position: 'sticky', top: '-1px', zIndex: 9999, backgroundColor: '#fff'}}>
                    <InputFilter/>
                </div>
            )}
            <div className="purchase-list-titles-sc-pendentes" style={openImport ? {top: 50} : {top: 40}}>
                <div className="purchase-list-title-item pd" style={{textAlign: 'left'}}>
                    <CiCircleCheck title='Selecionar Todos' style={{fontSize: 18, color: 'gray', marginLeft: 4, cursor: 'pointer'}} className='rs-icon' onClick={()=>handleSelectAll()}/>
                </div>
                <div className="purchase-list-title-item pd">FILIAL</div>
                <div className="purchase-list-title-item pd">NUMERO DA SC</div>
                <div className="purchase-list-title-item pd">NUMERO DA SA</div>
                <div className="purchase-list-title-item pd">SOLICITANTE</div>
                <div className="purchase-list-title-item pd">DESCRIÇÃO DO PRODUTO</div>
                <div className="purchase-list-title-item pd">UN.</div>
                <div className="purchase-list-title-item pd">QTD.</div>
                <div className="purchase-list-title-item pd">EMISSÃO</div>
                <div className="purchase-list-title-item pd">NECESSIDADE</div>
                <div className="purchase-list-title-item pd">STATUS</div>
                <div className="purchase-list-title-item pd">OBSERVAÇÃO</div>
            </div>
            {loadingSc ? (
                <Loader content="Carregando..."  center/>
            ):(
                <>
                {scList.length === 0 ? (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, width: '100%'}}>
                        <span style={{fontSize: 16, color: 'gray'}}>Nenhuma solicitação encontrada</span>
                    </div>
                ):(
                    <>
                    {scList?.map((processos, index) => {
                        const isChecked = selectedSolicitacao.some(
                            item => item.r_e_c_n_o_field === processos.r_e_c_n_o_field
                        );
                        return (
                        <ul key={processos.r_e_c_n_o_field} className='purchase-list-items'>
                            <li>
                                <div className='pushase-item-container-sc-pendentes'>
                                    <div className={isChecked ? 'purchase-list-item-sc-pendentes sc-pendentes-active' : 'purchase-list-item-sc-pendentes'}>
                                        <div className="purchase-list-item pd" style={{marginLeft: -5}}>
                                            <span className="item-manage">
                                                <input type="checkbox" onChange={() => {handleCheckboxChange(processos)}} checked={isChecked}/>
                                            </span>
                                        </div>

                                        <div className="purchase-list-item pd" style={{textAlign: 'center', display: 'flex', alignItems: 'center', marginRight: 10}}>
                                            <span className="item-title-sm">
                                                FILIAL
                                            </span>
                                            <span className="item-manager">
                                                {`${processos?.c1_filial}`}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item pd" style={{textAlign: 'center', display: 'flex', alignItems: 'center', marginRight: 10}}>
                                            <span className="item-title-sm">
                                                NUMERO
                                            </span>
                                            <span className="item-manager">
                                                {`${processos?.c1_num} - ${processos?.c1_item}`}
                                            </span>
                                        </div>
                                            

                                        <div className="purchase-list-item pd">
                                            <span className="item-manager">
                                                {processos?.sa?.value || 'N/A'}
                                            </span>
                                        </div>
                                        
                                        <div className="purchase-list-item pd">
                                            <span className="item-manager" title={processos?.c1_solicit}>
                                                {processos?.c1_solicit?.toUpperCase()}
                                            </span>
                                        </div>

                                        

                                        <div className="purchase-list-item pd">
                                            <span className="item-manager" title={processos?.c1_descri} style={{width: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: '5%'}}>
                                                {processos?.c1_descri}
                                            </span>
                                        </div>
                                        
                                        <div className="purchase-list-item pd">
                                            <span className="item-title-sm">
                                                UN.
                                            </span>
                                            <span className="item-manager">
                                                {processos?.c1_um}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item pd">
                                            <span className="item-title-sm">
                                                QTD. ITENS
                                            </span>
                                            <span className="item-manager">
                                                {processos?.c1_quant}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item pd">
                                            <span className="item-manager">
                                                {moment(processos.c1_emissao).format('DD/MM/YYYY')}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item pd">
                                            <span className="item-manager">
                                                {moment(processos?.c1_datprf).format('DD/MM/YYYY')}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item pd" style={{whiteSpace: 'nowrap'}}>
                                            <span className="item-manager">
                                                {processos?.status}
                                            </span>
                                        </div>


                                        <div className="purchase-list-item pd" style={{marginLeft: '0%'}}>
                                            <span className="item-manager" title={processos?.c1_obs} style={{width: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '10%'}}>
                                                {processos?.c1_obs?.toUpperCase() || 'SEM OBSERVAÇÃO'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                        );
                    })}
                    </>
                )}
                
                </>
            )}
            
            
            
               
        </div>
        <Pagination 
            total={pagination.total_items}
            limit={pagination.page_size}
            size="xs"
            disabled={isFilter}
            maxButtons={7}
            activePage={pagination.current} 
            onChangePage={hadleActivePage}
        />
        </>
    )
}
export default ListScPendente;