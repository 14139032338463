import './table-public.css'
import {IconButton, List } from 'rsuite';
import { useState, useEffect } from 'react';
import { FiDownload } from "react-icons/fi";
import moment from 'moment';
import { FaRegFilePdf } from "react-icons/fa";
import { CiFolderOn } from "react-icons/ci";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
import { useAxios } from '../../utils/api/api';
import { ONLYOFFICE_URL } from '../../utils/api/base';

import axios from 'axios';

const TablePublic = () => {
    const api = useAxios();
    const [loading, setLoading] = useState(false);
    const [loadinConvert, setLoadingConvert] = useState(false);
    const [documentos, setDocumentos] = useState([]);
    const [expand, setExpand] = useState({
        id: null,
        expanded: false
    });

    useEffect(() => {
        handeDocument();
    }, []);


    const handeDocument = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/v1/documento/public/');
            setLoading(false);
            const documentGroup = response.data.reduce((acc, item) => {
                const tipo = item?.tipo;
                
                let group = acc.find(group => group.tipo.id === tipo.id);

                if (!group) {
                    acc.push({
                        tipo: tipo,
                        data: [item]
                    });
                } else {
                    group.data.push(item);
                }
            
                return acc;
            }, []);
            setDocumentos(documentGroup);
        }catch(error){
            setLoading(false);
            console.log(error);
        }
    }



    function getFileExtension(url) {
        if (!url) return null;
        const parts = url.split('.');
        if (parts.length === 1) return null;
        return parts.pop().split('?')[0];
    }
    


    const handleConvert = async (files) => {
        setLoadingConvert(true);
    
        try {
            await handleGetConvert(files);
            if (files.children && files.children.length > 0) {
                await Promise.all(files.children.map((childFile) => handleGetConvert(childFile)));
            }
    
            setLoadingConvert(false);
        } catch (error) {
            console.error('Erro durante a conversão ou acesso ao arquivo:', error);
            setLoadingConvert(false);
        }
    };
    
    const handleGetConvert = async (file) => {
        const payload = {
            "async": true,
            "filetype": getFileExtension(file.link),
            "outputtype": "pdf",
            "key": String(file.id),
            "url": file.link,
        };
    
        try {
            const response = await axios.post(`${ONLYOFFICE_URL}ConvertService.ashx?sharedkey=${file.id}`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer PNN5VR3ZJml8OQtLGnZImiNkbJVZytpV'
                }
            });
    
            let fileUrl = response.data.fileUrl;
            let endConvert = response.data.endConvert;
            let fileReadyUrl = fileUrl;
    
            while (!endConvert) {
                const statusResponse = await axios.get(fileUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
    
                endConvert = statusResponse.data.endConvert;
                fileReadyUrl = statusResponse.data.fileUrl;
            }
            window.open(fileReadyUrl, '_blank');
        } catch (error) {
            console.error(`Erro durante a conversão do arquivo ${file.id}:`, error);
        }
    };
    



    
    const handleExpandToggle = (id) => {
        setExpand((prevState) =>
            prevState.id === id && prevState.expanded
                ? { id: null, expanded: false }
                : { id, expanded: true }
        );
    };
  

    

    return(
        <div className="container-documents-public">
        {documentos?.length > 0 ? (
            <>
                {documentos?.map((group, index) => (
                    <List key={index}>
                        <List.Item className='public-list' index={index}>
                            <div className='public-item'>
                                <div className='public-01'>
                                    <div className='public-item-icon'>
                                        <CiFolderOn style={{fontSize: 30, color: 'gray'}}/>
                                    </div>
                                    <div className='public-item-text'>
                                        <strong>{group.tipo.nome}</strong>
                                        <div className='public-item-text-01'>
                                            <span style={{marginLeft: 2}}>Total de Documentos: {group.data.length}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='public-02'>
                                    <IconButton 
                                        appearance="subtle" 
                                        onClick={() => handleExpandToggle(group?.tipo?.id)} 
                                        style={{marginTop: -6}} 
                                        icon={expand?.id === group?.tipo?.id && expand.expanded ? <MdExpandLess style={{fontSize: 25}}/> : <MdExpandMore style={{fontSize: 25}}/>} 
                                    />
                                </div>
                            </div>
                        </List.Item>
                        {expand.id === group.tipo.id && expand.expanded && (
                            group.data.map((file, docIndex) => (
                                <List.Item className='public-list' index={docIndex} key={docIndex}>
                                    <div className='public-item'>
                                        <div className='public-01'>
                                            <div className='public-item-icon'>
                                                <FaRegFilePdf style={{fontSize: 25, color: 'gray'}}/>
                                            </div>
                                            <div className='public-item-text'>
                                                <span style={{fontWeight: 'bold', fontSize: '0.9em'}}>{file.titulo}</span>
                                                <div className='public-item-text-01'>
                                                    <span style={{marginLeft: 2}}>DATA DE PUBLICAÇÃO: {moment(file?.data_criacao).format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='public-02'>
                                            <IconButton 
                                                appearance="subtle" 
                                                onClick={() => handleConvert(file)} 
                                                style={{marginTop: -6}} 
                                                icon={<FiDownload style={{fontSize: 25}}/>} 
                                            />
                                        </div>
                                    </div>
                                </List.Item>
                            ))
                        )}
                    </List>
                ))}
            </>
        ) : (
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <span>Nenhum documento disponível.</span>
            </div>
        )}
    </div>
    )
}
export default TablePublic;