import './list-solicitacoes.css';
import { useState, useEffect } from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { Loader, Dropdown, Whisper, Popover, Modal, Button} from 'rsuite';
import { MdOutlineErrorOutline } from "react-icons/md";
import MoreIcon from '@rsuite/icons/legacy/More';
import { FaRegEye } from "react-icons/fa";
import ScOrderView from '../scView';
import { useSelector } from "react-redux";
import { RiLoader3Fill } from "react-icons/ri";
import { atualizarGrupos, atualizarStatusGrupo } from '../../utils/groupdata';
import { FaRegCircleCheck } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import {toggleIsUpdate} from '../../../../../../utils/store/channelMessage';


const ListSolicitacoes = ({solicitacoes, setSolicitacoes, loading, setLoading}) => {
    const dispatch = useDispatch();
    const channelsMessage = useSelector(state => state.channelMessage.messages);
    const [expandId, setExpandId] = useState(null);
    const [openView, setOpenView] = useState(false);
    const [ItemSelect, setItemSelect] = useState(null);
    const [openModalError, setOpenModalError] = useState(false);
    const [dataError, setDataError] = useState({
        message: '',
        uuid: ''
    });
    const [print, isPrint] = useState(false);
    const api = useAxios();


    
    const handleUpdateStatus = (data) =>{
        const {uuid, tipo, numero, error_message} = data;
        let status = tipo === 'SUCESSO' ? 'PENDENTE' : 'SY';
        let sync_success = tipo === 'SUCESSO' ? true : false;
        let IsError = tipo === 'ERRO' ? true : false;
        setSolicitacoes(prevGrupos => atualizarStatusGrupo(prevGrupos, uuid, status, numero, error_message ,IsError, sync_success));
    }


    useEffect(() => {
        if (channelsMessage.length > 0) {
            const mensagensNaoAtualizadas = channelsMessage.filter(msg => !msg.is_update && msg.path === 'solicitacao_compra');
            if (mensagensNaoAtualizadas.length > 0) {
                mensagensNaoAtualizadas.forEach((msg) => {
                    dispatch(toggleIsUpdate({ id: msg.id, path: "solicitacao_compra", is_update: true }));
                    handleUpdateStatus(msg);
                });
            }
        }
    }, [channelsMessage]);


    const handleReeviarSc = async (uuid) => {  
        setLoading(true); 
        try {
            const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/reeviar_solicitacao/', {
                params: {
                    uuid: uuid
                }
            });
            setOpenModalError(false);
            setSolicitacoes(prevData => atualizarGrupos(prevData, response.data));
            setLoading(false);
            
        } catch (error) {
            console.log(error);
        }
    }


    
    const renderMenu = ({ onClose, left, top, className }, ref, Data) => {
        return (
            <Popover ref={ref} className={className} style={{ left, top, margin: 0, padding: 0 }}>
            <Dropdown.Menu style={{padding: 0, margin: 0}}>
                <Dropdown.Item eventKey={1} 
                    icon={<FaRegEye className='rs-icon'/>}
                    onClick={(e) => {
                        e.stopPropagation();
                        setItemSelect(Data);
                        setOpenView(true);
                        onClose();
                    }}
                >
                    Visualizar
                </Dropdown.Item>
                <Dropdown.Separator style={{padding: 0, margin: 0}}/>
            </Dropdown.Menu>
            </Popover>
        );
    };
       

    


  



    const toggleExpand = (index) => {
        setExpandId(prevId => (prevId === index ? null : index));
    };

    

    const handleStatus = (values) => {
        if(values === 'sol_pendente' || values === 'pendente' || values === "PD"){
            return 'PENDENTE';
        }else if(values === 'SY'){
            return 'NÃO ENVIADO';
        }
        else{
            return values.toUpperCase();
        }
    }

    return (
        <div className="sc-purchase-list-container" style={{marginBottom: 40}}>
            <div className="sc-ls-purchase-list-titles">
                <div className="sc-purchase-list-title-item item-left">FILIAL</div>
                <div className="sc-purchase-list-title-item item-left">NUMERO</div>
                <div className="sc-purchase-list-title-item">SOLICITANTE</div>
                <div className="sc-purchase-list-title-item">DATA DA SOLICITAÇÃO</div>
                <div className="sc-purchase-list-title-item">CENTRO DE CUSTO</div>
                <div className="sc-purchase-list-title-item">QTD. ITENS</div>
                <div className="sc-purchase-list-title-item">STATUS</div>
                <div className="sc-purchase-list-title-item item-right">...</div>
            </div>
            <>
            {loading ? (
                <div style={{marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader center content="Carregando..." style={{position: 'relative', marginTop: '5%'}}/>
                </div>
            ):(
                <>
                {solicitacoes.length === 0 ? (
                    <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%', fontSize: 15}}>Nenhuma solicitação encontrada</span>
                ):(
                    <>
                    {solicitacoes.map((processos, index) => {
                        return (
                            <ul key={processos.uuid} className='sc-purchase-list-items'>
                                <li>
                                    <div className='sc-pushase-item-container' onClick={(e) => {e.stopPropagation();toggleExpand(index)}} style={{cursor: 'pointer'}}>
                                        <div className="sc-ls-purchase-list-item-content">
                                            <div className="sc-purchase-list-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%'}}>
                                                <span className="item-manager number">
                                                    {processos?.filial}
                                                </span>
                                            </div>
                                            <div className="sc-purchase-list-item">
                                                <span className="item-manager number">
                                                    {processos?.numero}
                                                </span>
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    SOLICITANTE
                                                </span>
                                                <span className="item-manager">
                                                    {processos.data[0]?.c1_solicit?.toUpperCase()}
                                                </span>
                                            </div>


                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    DATA DA SOLICITAÇÃO
                                                </span>

                                                <span className="item-manager">
                                                    {moment(processos.data[0]?.c1_emissao).format('DD/MM/YYYY')}
                                                </span>
                                            </div>



                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    CENTRO DE CUSTO
                                                </span>
                                                <span className="item-manager desktop">
                                                    {processos.data[0]?.centro_custo || 'SEM CENTRO DE CUSTO'}
                                                </span>
                                                <span className="item-manager mobile">
                                                    {processos.data[0]?.centro_custo || 'SEM CC'}
                                                </span>
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    QTD. ITENS
                                                </span>
                                                <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {processos.data.length}
                                                </span>
                                            </div>

                                            <div className="purchase-list-item">
                                                <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    {processos?.status === 'SY' ? (
                                                    <>
                                                        {processos?.is_error_sync ? (
                                                            <>
                                                            <MdOutlineErrorOutline style={{fontSize: 30, cursor: 'pointer'}} className="text-4xl text-red-500" onClick={(e)=>{e.stopPropagation();setDataError({message: processos.error_message, uuid: processos.uuid}); setOpenModalError(true)}} title='ERRO NO ENVIO'/>
                                                            </>
                                                        ):(
                                                            <RiLoader3Fill style={{fontSize: 30, cursor: 'pointer'}} title='ENVIANDO..' className="animate-spin text-4xl text-blue-600"/>
                                                        )}
                                                    </>
                                                    ):processos.sync_success ? (
                                                        <>
                                                        <FaRegCircleCheck style={{fontSize: 25, cursor: 'pointer'}} className="text-4xl text-green-700" title='ENVIADO COM SUCESSO'/>
                                                        </>
                                                    ): (
                                                        <>
                                                        {processos?.status}
                                                        </>
                                                    )}
                                                    
                                                </span>
                                            </div>

                                            <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    ...
                                                </span>
                                                <Whisper placement="auto" trigger="click" speaker={(props, ref) => renderMenu(props, ref, processos)}>
                                                    <MoreIcon className="list-documents-icon" style={{ cursor: 'pointer', fontSize: 30, marginLeft: 5, backgroundColor: 'gray' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                </Whisper>
                                            </div>

                                        

                                        </div>
                                        {expandId === index && (
                                        <>
                                        {processos?.data?.map((item, index) => (
                                            <div key={index} onClick={(e)=>e.stopPropagation()} className="sc-sub-purchase-list-item-content-child" style={{cursor: 'pointer'}}> 
                                                <div className="sc-sub-purchase-list-item item-left">
                                                    <div className='item-expand'>
                                                        <span className="item-title-expadend" title={`${item?.c1_produto} - ${item?.c1_descri}`}>
                                                            ITEM
                                                        </span>
                                                        <span className="item-data-expand" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 160}}>
                                                            {item?.c1_descri?.trim()}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                            AMZ
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_local || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                            NÚMERO DA SA
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.sa?.value || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                           NÚMERO DO PEDIDO
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.pd?.value || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                           N° NF
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.relacoes_pd?.pd?.nf_data || 'SEM NF'}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                           O.S
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_os?.trim() || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                            O.P
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_op?.trim() || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                            CVLR
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_clvl?.trim() || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                            UN.
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_um || 'N/A'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc'>
                                                        <span className="item-title-expadend">
                                                            QTD.
                                                        </span>
                                                        <span className="item-data-expand">
                                                            {item?.c1_quant}
                                                        </span>
                                                    </div>
                                                </div>
                                                
                                                <div className="sc-sub-purchase-list-item">
                                                    <div className='item-expand sc' style={{display: 'flex', alignItems: 'center', marginRight: '40%'}}>
                                                        <span className="item-title-expadend">
                                                            STATUS
                                                        </span>
                                                        <span className="item-data-expand" style={{ whiteSpace: 'nowrap' }}>
                                                            {item?.status}
                                                        </span>
                                                    </div>
                                                </div>                                                
                                                
                                            </div>
                                            
                                        ))}
                                        </>
                                        )}
                                    </div>
                                </li>
                            </ul>

                        );
                    })}
                    </>
                )}
                
                </>
            )}
            </>
            <Modal size="full" open={openView} onClose={()=>setOpenView(false)}>
                <Modal.Header>
                    <div style={{backgroundColor: '#00664e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                            <Modal.Title style={{fontSize: 15, color: '#fff'}}>SOLICITAÇÃO DE COMPRAS</Modal.Title>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Button appearance="primary" onClick={()=>isPrint(true)} style={{backgroundColor: '#fff', color: 'black', fontSize: 12, borderRadius: 3, marginRight: 10}}>
                                    IMPRIMIR
                                </Button>
                                <Button onClick={()=>setOpenView(false)} appearance="primary" style={{backgroundColor: '#fff', color: 'black', fontSize: 12, borderRadius: 3}}>
                                    FECHAR
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ScOrderView data={ItemSelect} print={print} isPrint={isPrint}/>
                </Modal.Body>
            </Modal>

            <Modal overflow={false} open={openModalError} onClose={()=>setOpenModalError(false)} size="sm">
                <Modal.Header>
                    <Modal.Title style={{fontSize: 10}}>LOG DO ERRO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{minHeight: 300, backgroundColor: '#c7c6c698', padding: 10}}>
                        <code style={{fontSize: 12, fontWeight: 'bold'}}>
                            
                            {
                                dataError?.message || 'Erro não identificado'
                            }
                        </code>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button appearance="primary" onClick={()=>handleReeviarSc(dataError.uuid)} style={{backgroundColor: 'gray'}}>
                    Reenviar
                </Button>
                <Button onClick={()=>setOpenModalError(false)} appearance="subtle">
                    Sair
                </Button>
                </Modal.Footer>
            </Modal>
            
            
        </div>
           
        
    );
}
export default ListSolicitacoes;

