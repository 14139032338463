import { useState, useEffect, useRef } from "react";


const CustomTagInput = ({acompanhantes, usuarios, handleAcompanhantes}) => {
    const inputRef = useRef(null);
    const mainRef = useRef(null);
    const [usersList, setUsersList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    

   
    


    const handleSelect = (item) => {
        handleAcompanhantes("add", item);
        inputRef.current.value = "";
        setUsersList(usuarios);

        
        
    };


    const handleRemove = (item) => {
        handleAcompanhantes("remove", item);

    };



    const handleFilter = (value) => {
        
        const searchTerm = value.toLowerCase();

        const updatedUsers = usuarios.map(user => {
            const fullName = `${user.first_name} ${user.last_name}`.toLowerCase(); // Nome completo

            return {
                ...user,
                is_view: user.first_name.toLowerCase().includes(searchTerm) || 
                        user.last_name.toLowerCase().includes(searchTerm) ||
                        fullName.includes(searchTerm) // Agora pesquisa pelo nome completo também
            };
        });
        setUsersList(updatedUsers);
        
    };






  useEffect(() => {
    function handleClickOutside(event) {
      if (mainRef.current && !mainRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  
    useEffect(() => {
        if (usuarios) {
            setUsersList(usuarios);
        }
    }, [usuarios]);

return (
    <div ref={mainRef} style={{ position: "relative", width: '100%'}}>
        <div style={{borderRadius: 6, border: '1px solid #e5e5ea', fontSize: 13, fontFamily: 'inherit', letterSpacing: 'inherit', color: '#343434', padding: '7px 11px', height: 35, zIndex: 5, cursor: 'text'}}
            className="flex items-center justify-between"
            onClick={(e) => {setIsOpen(true);e.stopPropagation(); inputRef.current?.focus()}}
            
        >
            <div style={{display: 'flex', flexDirection: 'row', gap: 5, maxWidth: '100%'}}>
                {acompanhantes?.length > 0 && (
                    acompanhantes?.map((item, index) => (
                        <div key={item?.id}>
                            
                                <span  onClick={(e)=>{e.stopPropagation()}} title={`${item.first_name} ${item.last_name}`} style={{overflow: 'hidden', cursor: 'pointer', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, fontSize: 10}} 
                                className="inline-flex items-center gap-x-1.5 py-1.5 ps-3 pe-2 rounded-md bg-gray-100 text-gray-800">
                                
                                
                                {item.first_name}
                            
                                <button 
                                type="button" 
                                onClick={() => handleRemove(item)}
                                className="group shrink-0 size-3 inline-flex items-center justify-center rounded-md"
                                >
                                <span className="sr-only">Remove badge</span>
                                <svg className="h-5 w-5 text-gray-500 hover:text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg>
                                </button>
                            
                                </span>
                        </div>
                        

                    ))

                )}
                
                
                
                <input ref={inputRef} onChange={(e)=>handleFilter(e.target.value)} tabIndex="-1" style={{maxWidth: 240, boxSizing: 'content-box', minWidth: 0}} type="text"/>
                
            </div>
        </div>
        {isOpen && (
            <div className="absolute w-full bg-white  overflow-hidden" style={{marginTop: 0, borderRadius: 6, boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 4px 0px, rgba(0, 0, 0, 0.06) 0px 0px 10px 0px', padding: '6px 0', zIndex: 9999}}>
                <div style={{overflowY: 'auto', maxHeight: 150}}>
                    {usersList?.filter(user=> user.is_view).map((item) => (
                        <div
                            key={item.id}
                            className="p-2 flex items-center gap-2 cursor-pointer hover:bg-blue-100 text-[10px] text-gray-600 hover:text-blue-600"
                            onClick={() => handleSelect(item)}
                        >
                            <input
                                type="checkbox"
                                checked={!!acompanhantes?.find(acomp => acomp.id === item.id)}
                                readOnly
                                className="w-4 h-4"
                            />
                            <span className="uppercase text-[10px]">
                                {item.first_name} {item.last_name}
                            </span>
                        </div>
                    ))}
                    {usersList?.filter(user=> user.is_view).length === 0 && (
                        <div id="userListMessage" style={{height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#666'}}>
                            <span>Nenhum usuario encontrado</span>
                        </div>
                    )}
                </div>
            </div>
        )}
    </div>
);
}
export default CustomTagInput;