import './data-form.css';
import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@rsuite/icons/Close';
import { IconButton, Input, Loader, InputPicker, Modal, Button} from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import CheckIcon from '@rsuite/icons/Check';
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useFormik } from 'formik';
import { LuFileCog } from "react-icons/lu";
import { CiCircleAlert } from "react-icons/ci";
import { SaAmzContext } from '../../../../utils/AmzContext';
import {atualizarGrupos } from '../../../../utils/groupdata';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});







const DataFormDrawerAmz = ({ open, setOpen, listUser, solicitacoes,setSolicitacoes }) => {
  const user = useSelector((state) => state.auth);
  const datasets = useSelector((state) => state.datasets);
  const api = useAxios();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { cardSelected, setCardSelected } = useContext(SaAmzContext);
  const [openModal, setOpenModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [centroCList, setCentroCList] = useState([]);
  const [filialList, setFilialList] = useState([]);
  const [listAmz, setListAmz] = useState([]);
  const [listCta, setListCta] = useState([]);
  const [listOS, setListOS] = useState([]);
  const [listPrd, setListPrd] = useState([]);
  const [listCls, setListCls] = useState([]);
  const [loadingInput, setLoadingInput] = useState(false);
  const [openList, setOpenList] = useState(true);
  const [ItensPropag, setItensPropag] = useState(null);
  const formik = useFormik({
    initialValues: {
      solicitante: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
      filial: "",
      items: []
    }
  });
  
  const itemsFormik = useFormik({
    initialValues: {
      cp_item: '',
      cp_produto_label: 'PRODUTO',
      cp_produto: '',
      cp_produto_fixed: false,
      cp_descri: '',
      cp_um: '',
      cp_quant_label: 'QUANTIDADE',
      cp_quant: 0,
      cp_quant_fixed: false,
      cp_itemcta_label: 'ITEM CONTÁBIL',
      cp_itemcta: '',
      cp_itemcta_fixed: false,
      cp_clvl_label: 'CLASSE DE VALOR',
      cp_clvl: '',
      cp_clvl_fixed: false,
      classe_valor_desc: '',
      cp_op_label: 'ORDEM DE PRODUÇÃO',
      cp_op: '',
      cp_op_fixed: false,
      cp_numos_label: 'NUMERO DA OS',
      cp_numos: '',
      cp_numos_fixed: false,
      produto: '',
      centro_custo: '',
      cp_obs_label: 'OBSERVAÇÃO',
      cp_obs: '',
      cp_obs_fixed: false,
      cp_local_label: 'ARMAZÉM',
      cp_local: '',
      cp_local_fixed: false,
      cp_cc_label: 'CENTRO DE CUSTO',
      cp_cc: '',
      cp_cc_fixed: false,
      cp_datprf_label: 'DATA DE NECESSIDADE',
      cp_datprf: '',
      cp_datprf_fixed: false,
      acompanhantes: []
    }
  });



  const handleFixedItem = (e) => {
    const { name, checked } = e.target;
    const ItemName = name.replace("_fixed", "")
    const ItemValue = itemsFormik.values[ItemName];
    const data = {...ItensPropag};
    if(ItemValue === "" || ItemValue === 0){
      enqueueSnackbar(`Para adicionar um campo fixo o mesmo deve ser preechido`, { variant: "error", autoHideDuration: 2500, anchorOrigin: { vertical: 'top', horizontal: 'center' } });
      return;
    }
    if(ItemName === 'cp_produto'){
      if(checked){
        data[name] = checked
        data[ItemName] = ItemValue
        data['produto'] = ItemValue
        data['cp_descri'] = itemsFormik.values.cp_descri
        data['cp_um'] = itemsFormik.values.cp_um
      }else{
        delete data[name]
        delete data[ItemName]
        delete data['produto']
        delete data['cp_descri']
        delete data['cp_um']
      }
      setItensPropag(data);
    }else if(ItemName === 'cp_clvl'){
      if(checked){
        data[name] = checked
        data[ItemName] = ItemValue
        data['classe_valor_desc'] = itemsFormik.values.classe_valor_desc
      }else{
        delete data[name]
        delete data[ItemName]
        delete data['classe_valor_desc']
      }
      setItensPropag(data);
    }else if(ItemName === 'cp_cc'){
      if(checked){
        data[name] = checked
        data[ItemName] = ItemValue
        data['centro_custo'] = itemsFormik.values.centro_custo
      }else{
        delete data[name]
        delete data[ItemName]
        delete data['centro_custo']
      }
      setItensPropag(data);
    }else{
      if(checked){
        data[name] = checked
        data[ItemName] = ItemValue
      }else{
        delete data[name]
        delete data[ItemName]
      }
      setItensPropag(data);
    }
    itemsFormik.setFieldValue(name, checked);
  }

  


  const handleProduto = async (value) => {
    setOpenList(true);
    setLoadingInput(true);
    if(value.length > 1){
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,  
              table: 'Sb1010'
          }
        });
        setLoadingInput(false);
        setProductList(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoadingInput(false);
      }
    }else{
      setLoadingInput(false);
    }
  };

  const selectProduct = (item) => {
    setOpenList(false)
    let produto = productList.filter((prd) => prd.b1_cod === item)[0];
    itemsFormik.setFieldValue('cp_produto', produto.b1_cod);
    itemsFormik.setFieldValue('cp_um', produto.b1_um);
    itemsFormik.setFieldValue('cp_descri', produto.b1_desc);
    itemsFormik.setFieldValue('produto', produto.b1_desc);
    setProductList([produto])
  };

  const handleCentroC = async (value) => {
    setLoadingInput(true);
    if(value.length > 3){
      
      try {
        const response = await api.get(`/api/v2/modules/gestao-compras/solicitacao-compras/search_cc`, {
          params: { value }
        });
        setLoadingInput(false);
        setCentroCList(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching Centro de Custo:", error);
      }
    }
  };


  const selectCentroC = (item) => {
    itemsFormik.setFieldValue('cp_cc', item);
    const centroCusto = centroCList.filter((cc) => cc.ctt_custo.trim() === item.trim())[0];
    itemsFormik.setFieldValue('centro_custo', centroCusto.ctt_desc01);
    
  };



  const handleItemCTA = async (value) => {
    setLoadingInput(true);
    if(value){
      
      try {
        const response = await api.get(`/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/search_amz_dynamic`, {
          params: { 
            "table": "CTD010",
            "value": value
           }
        });
        setLoadingInput(false);
        setListCta(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching Centro de Custo:", error);
      }
    }
  };






  




  const selectFilial = (item) => {
    itemsFormik.resetForm();
    formik.setFieldValue('filial', item);
  };

  useEffect(() => {
    const nnrData = datasets['Nnr010'] ?? [];
    const filialData = datasets['Filial'] ?? [];
    setFilialList(filialData);
    setListAmz(nnrData);
  }, []);

  

 



  const handleOS = async (value) => {
    setLoadingInput(true);
    if(value.length > 1){
      try {
        const response = await api.get(`/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/search_amz_dynamic`, {
          params: { 
            "table": "Stj010",
            "value": value
           }
        });
        setLoadingInput(false);
        setListOS(response.data);
      } catch (error) {
        console.error("Error fetching os:", error);
        setLoadingInput(false);
      }
    }else{
      setLoadingInput(false);
    }
  };

  const selectOS = (item) => {
    const items = listOS.filter((os) => os.tj_ordem.trim() === item.trim())[0];
    if(items.c2_num){
      setListPrd([
        {
          "c2_num": items.c2_num?.trim(),
          "c2_produto":null,
        }
      ])
      itemsFormik.setFieldValue('cp_op', items.c2_num?.trim());
    }
    if(items.cth_clvl && items.cth_desc01){
      setListCls([
        {
          "cth_clvl": items.cth_clvl?.trim(),
          "cth_desc01": items.cth_desc01?.trim()
        }
      ])
      itemsFormik.setFieldValue('cp_clvl', items.cth_clvl?.trim());
      itemsFormik.setFieldValue('classe_valor_desc', items.cth_desc01?.trim());
    }
    itemsFormik.setFieldValue('cp_numos', item);
  };




  const handlePrd = async (value) => {
    if(value.length > 2){
      setLoadingInput(true);
      try {
        const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
          params: {
              filter: value,  
              table: 'Sc2010'
          }
        });
        setLoadingInput(false);
        setListPrd(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching AMZ:", error);
      }
    }
  }


  const selectPrd = (item) => {
    itemsFormik.setFieldValue('cp_op', item.c2_num);
  }





  const handleClass = async (value) => {
    if(value.length > 1){
      setLoadingInput(true);
      try {
        const response = await api.get(`/api/v2/modules/gestao-compras/solicitacao-compras/classe_valor`, {
          params: { value }
        });
        setLoadingInput(false);
        setListCls(response.data);
      } catch (error) {
        setLoadingInput(false);
        console.error("Error fetching AMZ:", error);
      }
    }
  }

  


  



  const handleAddItem = () => {
    if (!itemsFormik.values.cp_itemcta){
      enqueueSnackbar("Item Contábil é obrigatório", { variant: "error" });
      return
    }
    if (!itemsFormik.values.cp_produto) {
      enqueueSnackbar("Produto é obrigatório", { variant: "error" });
      return;
    }
    if (!itemsFormik.values.cp_quant) {
      enqueueSnackbar("Quantidade é obrigatório", { variant: "error" });
      return;
    }
    if (!itemsFormik.values.cp_local) {
      enqueueSnackbar("Armazém é obrigatório", { variant: "error" });
      return;
    }
    if (!itemsFormik.values.cp_cc) {
      enqueueSnackbar("Centro de Custo é obrigatório", { variant: "error" });
      return;
    }
    if (!itemsFormik.values.cp_datprf) {
      enqueueSnackbar("Data de necessidade é obrigatório", { variant: "error" });
      return;
    }
    let ItemData = null
    if (ItensPropag){
      ItemData = {
        ...itemsFormik.values, 
        ...Object.keys(itemsFormik.values)
          .filter(key => key in ItensPropag)
          .reduce((acc, key) => {
            acc[key] = ItensPropag[key];
            return acc;
          }, {})
      };
    }else{
      ItemData = itemsFormik.values;
    }
    const nextItem = String(formik.values.items.length + 1).padStart(2, '0');
    const newItem = {
      ...ItemData,
      cp_item: nextItem
    };
    formik.setFieldValue('items', [...formik.values.items, newItem]);
    const resetValues = Object.keys(itemsFormik.values).reduce((acc, key) => {
      if (key.endsWith("_label")) {
        acc[key] = itemsFormik.values[key]; // Mantém os labels
      }
      else if (ItensPropag && key in ItensPropag) { // Verifica se ItensPropag NÃO é null antes de acessar
        acc[key] = itemsFormik.values[key]; // Mantém os valores de ItensPropag
      }
      else {
        acc[key] = ""; // Reseta os demais campos
      }
      return acc;
    }, {});
    resetValues.cp_item = String(formik.values.items.length + 2).padStart(2, '0');
    itemsFormik.resetForm({ values: resetValues });
  }

  

  const openEdit = (item) => {
    itemsFormik.setFieldValue('cp_produto', item.cp_produto);
    itemsFormik.setFieldValue('produto', item.produto);
    itemsFormik.setFieldValue('cp_descri', item.cp_descri);
    itemsFormik.setFieldValue('cp_um', item.cp_um);
    itemsFormik.setFieldValue('cp_quant', item.cp_quant);
    itemsFormik.setFieldValue('cp_clvl', item.cp_clvl);
    itemsFormik.setFieldValue('classe_valor_desc', item.classe_valor_desc);
    itemsFormik.setFieldValue('cp_op', item.cp_op);
    itemsFormik.setFieldValue('cp_itemcta', item.cp_itemcta);
    itemsFormik.setFieldValue('cp_obs', item.cp_obs);
    itemsFormik.setFieldValue('cp_numos', item.cp_numos);
    itemsFormik.setFieldValue('centro_custo', item.centro_custo);
    itemsFormik.setFieldValue('cp_local', item.cp_local);
    itemsFormik.setFieldValue('cp_cc', item.cp_cc);
    itemsFormik.setFieldValue('cp_datprf', item.cp_datprf);
    itemsFormik.setFieldValue('acompanhantes', item.acompanhantes);
  }



  function removerCaracteresEspeciais(texto) {
    return texto
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')     // Remove acentos
      .replace(/ç/g, 'c')                  // Substitui ç
      .replace(/Ç/g, 'C')                  // Substitui Ç
      .replace(/[^\w\s\/-]/g, '');         // Remove tudo, exceto letras, números, espaços, / e -
  }



  const handleConclusion = async () => {
    
    setLoadingRequest(true);
    const data = {
      ...formik.values,
      items: formik.values.items.map((item) => {
        return {
          ...item,
          cp_datprf: moment(item.cp_datprf).format('YYYYMMDD'),
          cp_emissao: moment().format('YYYYMMDD')
        }
      })
    };
    try {
      const response = await api.post(`/api/v2/modules/gestao-amoxarifado/solicitacao-armazem/`, data);
      setSolicitacoes(prevData => atualizarGrupos(prevData, response.data));
      enqueueSnackbar("Solicitação criada com sucesso!", { variant: "success", autoHideDuration: 2000 });
      setLoadingRequest(false);
      setCardSelected("sync");
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      DataReset();
    } catch (error) {
      console.error("Error creating request:", error);
      setLoadingRequest(false);
    }
  }

  const DataReset = () =>{
    setItensPropag(null);
    itemsFormik.resetForm({
      values: {
        cp_item: '',
        cp_produto_label: 'PRODUTO',
        cp_produto: '',
        cp_produto_fixed: false,
        cp_descri: '',
        cp_um: '',
        cp_quant_label: 'QUANTIDADE',
        cp_quant: 0,
        cp_quant_fixed: false,
        cp_itemcta_label: 'ITEM CONTÁBIL',
        cp_itemcta: '',
        cp_itemcta_fixed: false,
        cp_clvl_label: 'CLASSE DE VALOR',
        cp_clvl: '',
        cp_clvl_fixed: false,
        classe_valor_desc: '',
        cp_op_label: 'ORDEM DE PRODUÇÃO',
        cp_op: '',
        cp_op_fixed: false,
        cp_numos_label: 'NUMERO DA OS',
        cp_numos: '',
        cp_numos_fixed: false,
        produto: '',
        centro_custo: '',
        cp_obs_label: 'OBSERVAÇÃO',
        cp_obs: '',
        cp_obs_fixed: false,
        cp_local_label: 'ARMAZÉM',
        cp_local: '',
        cp_local_fixed: false,
        cp_cc_label: 'CENTRO DE CUSTO',
        cp_cc: '',
        cp_cc_fixed: false,
        cp_datprf_label: 'DATA DE NECESSIDADE',
        cp_datprf: '',
        cp_datprf_fixed: false,
        acompanhantes: []
      }
    });
    formik.resetForm({
      values: {
        solicitante: `${user.name.toUpperCase()}.${user.last_name?.toUpperCase()}`,
        filial: "",
        items: []
      }
    });
  }


  
  




  return (
    <div className='modal-custom'>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {setOpen(false); DataReset()}}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ backgroundColor: '#00664e', padding: 2 }}>
            <IconButton icon={<CloseIcon />} size='sm' onClick={() => {setOpen(false); DataReset()}} />
            <div style={{width: '100%', padding: 10}}>
              <h3 style={{fontSize: 18}}>SOLICITAÇÃO ARMAZÉM</h3>
            </div>
          </Toolbar>
        </AppBar>

          <div className='form-container'>
            <SnackbarProvider maxSnack={3}/>
            <div className='form-pushare' style={{padding:10}}>
              <div style={{padding: 15, border: '1px solid #e5e5ea', borderRadius: 6, height: '100%', position: 'relative'}}>
                <div className='form-row' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  {/* FILIAL */}
                  <div className='form-group' style={{width: '30%', position: 'relative', display: 'flex', flexDirection: 'column', marginRight: 10}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Filial</label>
                    <InputPicker
                      id="cc"
                      value={formik.values.filial}
                      block
                      onSelect={(value) => selectFilial(value)}
                      onClean={() => {
                        formik.setFieldValue('filial', '');
                      }}
                      placeholder="Selecione A filial"
                      data={filialList.map((item) => 
                        ({
                            label: `${item.codigo} - ${item.descricao}`,
                            value: item?.codigo
                        }))}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                          <div style={{fontSize: 11}}>
                            {menu}
                          </div>
                        )
                      }}
                    />
                  </div>

                    <div className='form-group' style={{width: '80%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                      <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                        <label style={{fontSize: 12}}>Item Contábil</label>
                        {itemsFormik.values.cp_itemcta_fixed && (
                          <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                        )}
                      </div>
                      <InputPicker
                        id="cc"
                        value={itemsFormik.values.cp_itemcta}
                        block
                        onSearch={(value) => handleItemCTA(value.toUpperCase())}
                        onSelect={(value) => itemsFormik.setFieldValue('cp_itemcta', value)}
                        onClean={() => {
                          itemsFormik.setFieldValue('cp_itemcta', '');
                        }}
                        placeholder="Selecione A filial"
                        data={listCta.map((item) => 
                          ({
                              label: `${item.ctd_item} - ${item.ctd_desc}`,
                              value: item?.ctd_item
                          }))}
                        menuStyle={{
                          maxWidth: '100%',
                          overflowX: 'auto',
                        }}
                        renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                      />
                    </div> 

                </div>
                
                <div className='form-row' >
                  <div className='form-group input-product-container' style={{width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Produto</label>
                      {itemsFormik.values.cp_produto_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    <InputPicker
                          block
                          id="product"
                          virtualized
                          menuMaxHeight={300}
                          style={{width: '100%'}}
                          menuStyle={{
                            display: openList ? 'block' : 'none',
                          }}
                          value={itemsFormik.values.cp_produto}
                          onSearch={(e) => handleProduto(e.toUpperCase())}
                          onSelect={(value) => selectProduct(value)}
                          placeholder="Código ou nome"
                          data={productList.map((item) => ({
                              label: item?.b1_cod,
                              value: item?.b1_cod,
                              item
                          }))}
                          menuClassName='rs-custom-picker'
                          renderMenu={(menu) => {
                            if(loadingInput){
                              return(
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20}}>
                                  <Loader content="Carregando..." center/>
                                </div>
                              )
                            } return(
                              <>
                                <div className='menu-custom-picker-titles'>
                                  <div className='item-custom-picker-title'>COD.</div>
                                  <div className='item-custom-picker-title'>DESCRIÇÃO</div>
                                  <div className='item-custom-picker-title'>ESPECIFICAÇÕES</div>
                                  <div className='item-custom-picker-title'>UNIDADE DE MEDIDA</div>
                                </div>
                                {menu}
                              </>
                              
                            )
                          }}
                          searchBy={() => true}
                          renderMenuItem={(label, item) => (
                            <div className='menu-custom-picker'>
                              <div className='item-custom-picker'>
                                <span className='item-custom-data-picker'>
                                  {label}
                                </span>
                              </div>
                              <div className='item-custom-picker'>
                                <span className='item-custom-data-picker' title={item.item.b1_desc} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 250, display: 'inline-block'}}>
                                  {item.item.b1_desc}
                                </span>
                              </div>
                              <div className='item-custom-picker'>
                                <span className='item-custom-data-picker' title={item.item.b1_especif} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200, display: 'inline-block'}}>
                                  {item.item.b1_especif || ''}
                                </span>
                              </div>
                              <div className='item-custom-picker'>
                                <span className='item-custom-data-picker' style={{marginLeft:'10%'}}>
                                  {item.item.b1_um}
                                </span>
                              </div>
                            </div>
                          )}
                          onClean={() => {
                            itemsFormik.setFieldValue('cp_produto', '');
                            itemsFormik.setFieldValue('cp_um', '');
                            itemsFormik.setFieldValue('cp_descri', '');
                            itemsFormik.setFieldValue('produto', '');
                          }}
                        />
                  </div>
                </div>


                <div className='form-row' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  
                  

                  <div className='form-group' style={{width: '100%', marginRight: 10}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Descrição</label>
                    <Input
                      type="text"
                      id="cc"
                      readOnly
                      value={itemsFormik.values.cp_descri}
                      placeholder=""
                    />
                  </div>

                  <div className='form-group' style={{width: '10%'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Unidade</label>
                    <Input
                      type="text"
                      id="cc"
                      readOnly
                      value={itemsFormik.values.cp_um || 'UN'}
                      placeholder=""
                    />
                  </div>

                </div>
                
                <div className='form-row' style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                

                  <div className='form-group' style={{marginRight: 10, position: 'relative'}}>
                    
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Armazém</label>
                      {itemsFormik.values.cp_local_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    <InputPicker
                      type="text"
                      id="cc"
                      value={itemsFormik.values.cp_local}
                      onClean={() => {
                        itemsFormik.setFieldValue('cp_local', '');
                      }}
                      data={listAmz.map((item) => 
                        ({
                            label: `${item?.nnr_codigo} - ${item?.nnr_descri}`,
                            value: item?.nnr_codigo
                        }))}
                      placeholder="Amazém"
                      onSelect={(value)=>{
                        itemsFormik.setFieldValue('cp_local', value);
                      }}
                      renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                          <div style={{fontSize: 10}}>
                            {menu}
                          </div>
                        )
                      }}
                    />
                    
                  </div>

                  <div className='form-group' style={{width: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                    
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Centro de Custo</label>
                      {itemsFormik.values.cp_cc_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    <InputPicker
                      id="cc"
                      value={itemsFormik.values.cp_cc}
                      block
                      onSearch={(e) => handleCentroC(e.toUpperCase())}
                      onSelect={(value) => selectCentroC(value)}
                      onClean={() => {
                        itemsFormik.setFieldValue('cp_cc', '');
                        itemsFormik.setFieldValue('centro_custo', '');
                      }}
                      placeholder="Digite o código ou nome do centro de custo"
                      data={centroCList.map((item) => 
                        ({
                            label: `${item.ctt_custo?.trim()} - ${item.ctt_desc01.trim()}`,
                            value: item?.ctt_custo?.trim()
                        }))}
                      menuStyle={{
                        maxWidth: '100%',
                        overflowX: 'auto',
                      }}
                      renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                          <div style={{fontSize: 10}}>
                            {menu}
                          </div>
                        )
                      }}
                    />
                  </div>
                </div>




                <div className='form-row'>
                <div className='form-group' style={{width: '50%', marginRight: 10, position: 'relative'}}>
                    
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>N° OS</label>
                      {itemsFormik.values.cp_numos_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    <InputPicker
                      type="text"
                      style={{width: '100%'}}
                      autoComplete='off'
                      value={itemsFormik.values.cp_numos}
                      id="cc"
                      data={listOS.map((item) => 
                        ({
                            label: item.tj_ordem?.trim(),
                            value: item.tj_ordem?.trim()
                        }))}
                      onSearch={(e) => handleOS(e.toUpperCase())}
                      onSelect={(value) => selectOS(value)}
                      onClean={() => {
                        itemsFormik.setFieldValue('cp_numos', '');
                      }}
                      placeholder="Selecione"
                      menuMaxHeight={200}
                      renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                          <div style={{fontSize: 10}}>
                            {menu}
                          </div>
                        )
                      }}
                    />
                  </div>

                  <div className='form-group' style={{width: '50%', marginRight: 10, position: 'relative'}}>
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Ordem de Produção</label>
                      {itemsFormik.values.cp_op_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    
                    <InputPicker
                      type="text"
                      style={{width: '100%'}}
                      autoComplete='off'
                      value={itemsFormik.values.cp_op}
                      id="cc"
                      data={listPrd.map((item) => 
                        ({
                            label: item.c2_num?.trim(),
                            value: item.c2_num?.trim()
                        }))}
                      onSearch={(e) => handlePrd(e.toUpperCase())}
                      onSelect={(value) => selectPrd(value)}
                      placeholder="N° da ordem de produção"
                      onClean={() => {
                        itemsFormik.setFieldValue('cp_op', '');
                      }}
                      renderMenu={(menu) => {
                        if(loadingInput){
                          return(
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                              <Loader content="Carregando..." center/>
                            </div>
                          )
                        } return(
                          <div style={{fontSize: 10}}>
                            {menu}
                          </div>
                        )
                      }}
                    />
                  </div>


                  <div className='form-group' style={{width: '50%', position: 'relative', marginRight: 10}}>
                    
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Classe de Valor</label>
                      {itemsFormik.values.cp_clvl_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    <InputPicker
                      type="text"
                      style={{width: '100%'}}
                      id="cc"
                      autoComplete='off'
                      value={itemsFormik.values.cp_clvl}
                      onSearch={(e) => handleClass(e.toUpperCase())}
                      placeholder="Selecione"
                      data={listCls.map((item) => 
                        ({
                            label: `${item.cth_desc01.trim()}`,
                            value: item.cth_clvl?.trim()
                        }))}
                      onSelect={(value) => {
                        const item = listCls.find((item) => item.cth_clvl?.trim() === value);
                        itemsFormik.setFieldValue('classe_valor_desc', item.cth_desc01?.trim());
                        itemsFormik.setFieldValue('cp_clvl', value)
                      }}
                      onClean={() => {
                        itemsFormik.setFieldValue('classe_valor_desc', '');
                        itemsFormik.setFieldValue('cp_clvl', '');
                      }}
                      menuStyle={{
                        maxWidth: 100,
                        overflowX: 'auto',
                      }}
                      menuMaxHeight={200}
                      renderMenuItem={(label, item) => (
                      <div 
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '250px',
                          display: 'block',
                          fontSize: 10
                        }}
                        title={label?.props?.children}
                      >
                        {label}
                      </div>
                    )}
                    renderMenu={(menu) => {
                      if (loadingInput) {
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                            <Loader content="Carregando..." center />
                          </div>
                        );
                      }
                      return menu;
                    }}
                    />
                  </div>


                  <div className='form-group' style={{width: '50%', marginRight: 10}}>
                    
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Necessidade</label>
                      {itemsFormik.values.cp_datprf_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    <Input
                      type="date"
                      autoComplete='off'
                      style={{fontSize: 12, color: '#545454'}}
                      id="cc"
                      value={itemsFormik.values.cp_datprf}
                      onChange={(e)=>itemsFormik.setFieldValue('cp_datprf', e)}
                      placeholder=""
                    />
                  </div>
                  

                  <div className='form-group' style={{width: '50%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Quantidade</label>
                      {itemsFormik.values.cp_quant_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                    </div>
                    <Input
                      type="number"
                      autoComplete='off'
                      id="cc"
                      value={itemsFormik.values.cp_quant}
                      onChange={(e)=>itemsFormik.setFieldValue('cp_quant', e)}
                      placeholder=""
                    />
                  </div>
                  
                </div>


              

                <div style={{width: '100%'}}>
                  <div style={{display: 'flex', flexDirection: 'row', marginLeft: 4, alignItems: 'center'}}>
                      <label style={{fontSize: 12}}>Observação</label>
                      {itemsFormik.values.cp_obs_fixed && (
                        <CiCircleAlert style={{marginLeft: 3, color: 'red'}} title='Item Fixo'/>
                      )}
                  </div>
                  <Input as="textarea" rows={3} onChange={(value)=>itemsFormik.setFieldValue('cp_obs', removerCaracteresEspeciais(value.toUpperCase()))} value={itemsFormik.values.cp_obs} placeholder="Observação" />
                </div>

                <div className="submit-button" style={{position: 'absolute', bottom: 0, left: 0}}>
                  <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                    <div>
                      <IconButton appearance='primary' onClick={()=>setOpenModal(true)} icon={<LuFileCog className='rs-icon' style={{backgroundColor: '#999', color: '#fff'}}/>} style={{backgroundColor: '#999', color: '#fff'}} size='sm'>
                      Fixar Itens
                      </IconButton>
                      {/* <IconButton appearance='primary' onClick={()=>setOpenModal(true)} icon={<MdFormatListBulletedAdd className='rs-icon' style={{backgroundColor: '#999', color: '#fff'}}/>} style={{backgroundColor: '#999', color: '#fff', marginLeft: 5}} size='sm'>
                      Trocar Formulário
                      </IconButton> */}
                    </div>
                    <IconButton icon={<ExpandOutlineIcon style={{backgroundColor: '#999', color: '#fff'}}/>} style={{backgroundColor: '#999', color: '#fff'}} onClick={()=>handleAddItem()} size='sm'>
                      Adicionar
                    </IconButton>
                  </div>
                </div>
              </div>  
            </div>

            <div className='list-pushare' style={{position: 'relative'}}>
              <div>
                {loadingRequest ? (
                  <Loader content="Salvando..."  center/> 
                ):(
                  <>
                  {formik?.values?.items?.length > 0 ? (
                  <>
                    <span>Itens adicionados:</span>
                    <ul className="list-container-puchare" style={{marginBottom: 50}}>
                        <div className='list-item-puchare-title sa'>
                          <span>Produto</span>
                          <span style={{textAlign: 'center'}}>Qtd.</span>
                          <span style={{textAlign: 'center'}}>Classe de valor</span>
                          <span style={{textAlign: 'center'}}>Necessidade</span>
                          <span style={{textAlign: 'center'}}>...</span>
                        </div>
                      {formik?.values?.items?.map((item, index) => (
                        <>
                        <li key={index} className="list-item-puchare sa">
                          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
                            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 120}}>{item.cp_produto}</span>
                            <span title={item.cp_descri} style={{fontSize: 9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 190, cursor: 'pointer'}}>{item.cp_descri}</span>
                          </div>
                          

                          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            <span style={{textAlign: 'center'}}>{item.cp_quant}</span>
                          </div>

                          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            <span title={item?.classe_valor_desc} style={{textAlign: 'center'}}>{item.cp_clvl || "N/A"}</span>
                          </div>
                          
                          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            <span style={{textAlign: 'center'}}>{moment(item.cp_datprf, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                          </div>


                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <IconButton 
                              appearance='primary' 
                              style={{backgroundColor: '#bbbaba', marginRight: 10}} 
                              onClick={() => {
                                openEdit(item);
                                const updatedItems = formik.values.items.filter((value, i) => value.cp_item !== item.cp_item);
                                formik.setFieldValue('items', updatedItems);
                              }} 
                              icon={<EditIcon style={{backgroundColor: '#bbbaba', color: '#fff'}}/>} 
                              size='sm'
                            />

                            <IconButton
                              appearance='primary'
                              style={{backgroundColor: 'red', marginRight: 10}}
                              onClick={() => {
                                const updatedItems = formik.values.items.filter((_, i) => i !== index);
                                formik.setFieldValue('items', updatedItems);
                              }}
                              icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>}
                              size='sm'
                            />
                          </div>
                          
                        </li>
                        
                        </>
                        
                      ))}
                    </ul>
                  </>
                ) : (
                  <span>Nenhum item adicionado</span>
                )}
                  </>
                )}
              </div>
              {formik.values?.items?.length > 0 && (
                <div style={{position: 'fixed', bottom: 0, height: '5%', zIndex: 9999, backgroundColor: '#fff', width: '100%', left: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '1%'}}>
                  <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '1%'}}>
                    <IconButton appearance='primary' style={{backgroundColor: 'red', marginRight: 10}} onClick={()=> DataReset()} icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>} size='sm'>
                      Cancelar
                    </IconButton>
                    <IconButton appearance='primary' style={{backgroundColor: '#00664e'}} icon={<CheckIcon style={{backgroundColor: '#00664e', color: '#fff'}}/>} onClick={()=>handleConclusion()} size='sm'>
                      Concluir
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
            <Modal size="sm" backdrop="static" style={{zIndex: 9999, marginTop: 40}} open={openModal} onClose={()=>setOpenModal(false)}>
                  <Modal.Header>
                      <div style={{backgroundColor: '#00664e', position: 'absolute', top:0, width: '100%', left: 0, height: 40, padding: 10, display: 'flex', flexDirection: 'row', borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginLeft: 10, marginRight: 10}}>
                            <Modal.Title style={{fontSize: 15, color: '#fff'}}>FIXAR OPÇÕES DO FORMULÁRIO</Modal.Title>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                              <Button onClick={()=>setOpenModal(false)} appearance="primary" style={{backgroundColor: '#fff', color: 'black', fontSize: 12, borderRadius: 3}}>
                                  FECHAR
                              </Button>
                            </div>

                          </div>
                      </div>
                  </Modal.Header>
                  <Modal.Body>
                      <div style={{marginTop: 20}}>
                        <div className="space-y-2">
                          {Object.keys(itemsFormik.values).map((key) => {
                            if (key.endsWith("_fixed")) {
                              const labelKey = key.replace("_fixed", "_label");
                              const fixedItem = itemsFormik.values;
                              return (
                                <label key={key} className="flex items-center space-x-2">
                                  <input
                                    type="checkbox"
                                    name={key}
                                    checked={fixedItem[key]}
                                    onChange={handleFixedItem}
                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                                  />
                                  <span className="text-gray-700">{fixedItem[labelKey]}</span>
                                </label>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                  </Modal.Body>
            </Modal>
          </div>
      
      </Dialog>
    </div>
  );
};

export default DataFormDrawerAmz;
